import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Navbar2 from "./Navbar2";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import l from "./Images/l.jpg";
import m from "./Images/m.jpg";
import n from "./Images/n.jpg";
import marina from "./Images/marina.jpeg";
import o from "./Images/o.jpg";
import p from "./Images/p.jpg";
import { useNavigate } from "react-router-dom";

function Home() {
  const [value, setvalue] = useState();
  const navigate = useNavigate();

  return (
    <div>
      <Navbar2 />
      <div>
        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={88}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={88}
          ></div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={88}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={88}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={88}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={88}
          />
          <div data-react-server-container={6}>
            <div
              data-react-server-root-id={7}
              data-react-server-timing-offset={88}
            />
            <div
              data-react-server-root-id={8}
              data-react-server-timing-offset={88}
            >
              <div
                className="MerchPlacement merch-widget-LeftClearBgHeroWidget"
                data-rf-test-id="homepage_main"
              >
                <section
                  className="LeftClearBgHeroWidget home-hero-section no-cta-section"
                  data-rf-test-id="searchAndMerch"
                >
                  <div className="home-hero-outer">
                    <div className="home-hero-inner-transition-group">
                      <div
                        className="home-hero-inner"
                        style={{
                          backgroundImage: `url(${p})`,
                        }}
                      >
                        <div
                          className="tabSection fluid-gutter"
                          style={{ marginTop: "5rem" }}
                        >
                          <h1 className="brandText">
                            Search with Redcoral.
                            <br />
                            Tour with a agent.
                          </h1>
                          <div
                            className="homepageTabContainer"
                            id="homepageTabContainer"
                            data-rf-test-name="searchBox"
                          >
                            <div className="TabbedContainer  SearchTabs">
                              <div className="Tabs" role="tablist">
                                <div
                                  className="TabContent"
                                  id="tabContentId0"
                                  role="tabpanel"
                                  aria-selected="true"
                                  aria-hidden="false"
                                >
                                  <div className="searchInputNode">
                                    <div
                                      data-rf-test-name="search-box"
                                      className="SearchBox"
                                      tabIndex={-1}
                                    >
                                      <form
                                        className="SearchBoxForm"
                                        action="?"
                                        method="POST"
                                      >
                                        <div
                                          data-rf-test-name="search-container"
                                          className="search-container inline-block"
                                        >
                                          <div className="InputBox">
                                            <noscript />
                                            <input 
                                              className="search-input-box"
                                              onChange={(e) => {
                                                setvalue(e.target.value);
                                              }}
                                              placeholder="search with your city or country(us, canada, emirates, italy, france)"
                                              title="City, Address, School, Agent, ZIP"
                                            />
                                            <div className="inline-block clear-button-block">
                                              <input
                                                type="button"
                                                aria-label="clear input"
                                                aria-hidden="true"
                                                className="btn-clear-search-input clickable hidden"
                                                data-rf-test-name="search-box-clear"
                                                title="Clear"
                                              />
                                              <svg className="SvgIcon clear clear-icon-search-input size-small hidden">
                                                <svg viewBox="0 0 24 24">
                                                  <g fillRule="nonzero">
                                                    <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z" />
                                                    <path d="M16.772 8.288l-1.06-1.06a.25.25 0 00-.354 0L12 10.586 8.642 7.228a.25.25 0 00-.354 0l-1.06 1.06a.25.25 0 000 .354L10.586 12l-3.358 3.358a.25.25 0 000 .354l1.06 1.06a.25.25 0 00.354 0L12 13.414l3.358 3.358a.25.25 0 00.354 0l1.06-1.06a.25.25 0 000-.354L13.414 12l3.358-3.358a.25.25 0 000-.354z" />
                                                  </g>
                                                </svg>
                                              </svg>
                                            </div>
                                          </div>
                                          <button
                                            data-rf-test-name="searchButton"
                                            aria-label="submit search"
                                            className="inline-block SearchButton clickable float-right"
                                            type="submit"
                                            value
                                            title="Search"
                                            tabIndex={0}
                                            onClick={() => {
                                              if (value == null) {
                                                navigate("/buy");
                                              } else {
                                                navigate(`/buy/${value}`);
                                              }
                                            }}
                                          >
                                            <svg className="SvgIcon search">
                                              <svg viewBox="0 0 24 24">
                                                <g fillRule="evenodd">
                                                  <path d="M23.884 21.763l-7.554-7.554a8.976 8.976 0 001.526-6.835C17.203 3.68 14.204.72 10.502.122a9.01 9.01 0 00-10.38 10.38c.598 3.702 3.558 6.7 7.252 7.354a8.976 8.976 0 006.835-1.526l7.554 7.554a.25.25 0 00.353 0l1.768-1.768a.25.25 0 000-.353zM2 9c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                                                </g>
                                              </svg>
                                            </svg>
                                          </button>
                                        </div>
                                      </form>
                                      <noscript />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="disclaimer">
                              <span className="disclaimer-text">
                                supports fair housing. See the{" "}
                                <Link to="https://www.dos.ny.gov/licensing/docs/FairHousingNotice_new.pdf">
                                  NY fair housing notice
                                </Link>
                                .
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
            <div
              data-react-server-root-id={10}
              data-react-server-timing-offset={90}
            />
            <div
              data-react-server-container={11}
              className="InlineMerch row-center"
            >
              <div data-react-server-container={12} className="col-12">
                <div
                  data-react-server-root-id={13}
                  data-react-server-timing-offset={90}
                  className="contentBodySection"
                  id="userFeed"
                >
                  <div className="UserFeedCarousel">
                    <h2 className="UserFeedCarousel__header heading-medium padding-bottom-large">
                      Recommended for you{" "}
                    </h2>
                    <div
                      className="BaseCarousel desktop BaseCarousel--mounted"
                      tabIndex={0}
                    >
                      <div className="BaseCarousel__stage">
                        <ul className="BaseCarousel__slider" id>
                          <li
                            className="BaseCarousel__cell"
                            style={{ width: "480px" }}
                            aria-hidden="false"
                            ioindex={0}
                          >
                            <div className="FeedHomeCard">
                              <div className="v2 interactive" tabIndex={0}>
                                <div
                                  className="homecardv2"
                                  data-rf-test-name="basicNode-homeCard"
                                >
                                  <div className="cover-all">
                                    <div className="PhotoSlider photoContainer">
                                      <div
                                        className="scrollable"
                                        style={{ transform: "translateX(0px)" }}
                                      >
                                        <Link
                                          to="/buy"
                                          className="slider-item "
                                          data-rf-test-id="slider-item--1"
                                          style={{
                                            transform: "translateX(-472px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="409 Auburn St #2, Newton, MA 02466"
                                            alt="Photo of 409 Auburn St #2, Newton, MA 02466"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/806/genIsm.73078806_30_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          to="/buy"
                                          className="slider-item "
                                          data-rf-test-id="slider-item-0"
                                          data-rf-test-name="basic-card-photo"
                                          style={{
                                            transform: "translateX(0px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="409 Auburn St #2, Newton, MA 02466"
                                            alt="Photo of 409 Auburn St #2, Newton, MA 02466"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/806/genIsm.73078806_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </Link>
                                        <Link
                                          to="/buy"
                                          className="slider-item "
                                          data-rf-test-id="slider-item-1"
                                          style={{
                                            transform: "translateX(472px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="409 Auburn St #2, Newton, MA 02466"
                                            alt="Photo of 409 Auburn St #2, Newton, MA 02466"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/806/genIsm.73078806_1_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    role="button"
                                    aria-label="previous button"
                                    tabIndex={0}
                                    className="left-nav clickable"
                                    data-rf-test-name="left-nav"
                                  >
                                    <svg className="SvgIcon slide-previous">
                                      <svg viewBox="0 0 24 24">
                                        <g fillRule="evenodd">
                                          <path d="M16.426 23.134L5.074 11.78a.25.25 0 010-.353L16.426.073a.25.25 0 01.355 0l1.06 1.06a.25.25 0 010 .355L7.724 11.603 17.841 21.72a.25.25 0 010 .355l-1.06 1.06a.25.25 0 01-.355 0" />
                                        </g>
                                      </svg>
                                    </svg>
                                  </div>
                                  <div
                                    role="button"
                                    aria-label="next button"
                                    tabIndex={0}
                                    className="right-nav clickable"
                                    data-rf-test-name="right-nav"
                                  >
                                    <svg className="SvgIcon slide-next">
                                      <svg viewBox="0 0 24 24">
                                        <g fillRule="evenodd">
                                          <path d="M7.134 23.134l-1.06-1.06a.25.25 0 010-.355L16.19 11.603 6.074 1.488a.25.25 0 010-.355l1.06-1.06a.25.25 0 01.354 0L18.84 11.427a.25.25 0 010 .353L7.488 23.134a.25.25 0 01-.354 0" />
                                        </g>
                                      </svg>
                                    </svg>
                                  </div>
                                  <div className="Pill Pill--default padding-vert-smallest padding-horiz-smaller font-size-smaller font-weight-bold font-color-white media-index no-opacity">
                                    1 / 31
                                  </div>
                                  <div className="bottomright">
                                    <div className="mlsLogoExtension" />
                                    <div
                                      className="MapToggleButton"
                                      data-rf-test-name="MapToggleButton"
                                    >
                                      <button
                                        type="button"
                                        className="button Button PhotoMapToggle icon unpadded suppressMinWidth"
                                        tabIndex={0}
                                        role="button"
                                        aria-label="PhotoMapToggle"
                                      >
                                        <div className="svg-icon">
                                          <svg className="SvgIcon MapAlt">
                                            <svg viewBox="0 0 36 36">
                                              <path d="M0 0h36v36H0z" />
                                              <circle
                                                opacity=".7"
                                                cx={18}
                                                cy={18}
                                                r="15.75"
                                                fillOpacity=".7"
                                              />
                                              <path d="M15.236 10.543a.832.832 0 01.527 0l4.737 1.579 3.64-1.214a1.667 1.667 0 012.193 1.582v9.91c0 .717-.459 1.354-1.14 1.58l-4.43 1.477a.832.832 0 01-.527 0L15.5 23.878l-3.64 1.214a1.667 1.667 0 01-2.193-1.581V13.6c0-.718.459-1.355 1.14-1.582l4.43-1.476zm1.097 11.856l3.334 1.111V13.6l-3.334-1.11v9.91zm-1.666-9.91l-3.334 1.112v9.91l3.334-1.112v-9.91zm6.666 1.112v9.91l3.334-1.112v-9.91l-3.334 1.112z" />
                                            </svg>
                                          </svg>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="bottomV2 ">
                                  <span
                                    className="homecardV2Price"
                                    data-rf-test-name="homecard-price"
                                  >
                                    $729,000
                                  </span>
                                  <div className="favoriteButtonV2 homecard-clickable">
                                    <div
                                      className="FavoriteExtension favoriteButtonV2 clickable fav-off"
                                      data-rf-test-id="favoriteExtension"
                                      role="checkbox"
                                      aria-label="favorite button"
                                      aria-checked="false"
                                    >
                                      <svg className="SvgIcon favorite-actionable clickable favorite-icon">
                                        <svg viewBox="0 0 26 23">
                                          <path
                                            className="favorite-actionable-fill fill"
                                            d="M18.38 2c-1.753 0-3.712.756-5.368 2.766C11.356 2.756 9.397 2 7.644 2c-1.286 0-2.46.407-3.322 1.026C2.287 4.487 1.337 7.56 2.508 10.48c1.517 3.783 9.212 9.6 10.36 10.452a.233.233 0 00.287 0c1.158-.856 8.95-6.72 10.36-10.452 1.113-2.943.221-5.993-1.813-7.454C20.84 2.407 19.665 2 18.38 2z"
                                          />
                                          <path
                                            className="favorite-actionable-outline outline"
                                            d="M13.012 4.766l-.771.636.771.937.772-.937zm-8.69-1.74l.584.813zM2.508 10.48l-.928.373zm10.36 10.452l-.596.803zm.287 0l-.594-.804-.006.004zm10.36-10.452l-.935-.353zm-1.813-7.454l.584-.812zM18.38 1c-2.061 0-4.3.898-6.138 3.13l1.543 1.272C15.257 3.615 16.937 3 18.38 3zm-4.595 3.13C11.945 1.899 9.707 1 7.644 1v2c1.444 0 3.124.615 4.597 2.402zM7.644 1c-1.49 0-2.87.47-3.905 1.215l1.167 1.623C5.593 3.344 6.563 3 7.644 3zM3.74 2.214C1.294 3.97.255 7.547 1.58 10.853l1.857-.745C2.42 7.572 3.28 5.006 4.906 3.838zM1.58 10.853c.443 1.104 1.298 2.278 2.273 3.394.988 1.132 2.16 2.27 3.304 3.301a75.12 75.12 0 005.115 4.187l1.193-1.605a73.097 73.097 0 01-4.969-4.068c-1.112-1.002-2.22-2.08-3.137-3.13-.93-1.065-1.607-2.036-1.922-2.824zm10.693 10.883c.44.327 1.037.33 1.482-.004l-1.2-1.6a.763.763 0 01.909-.003zm1.477 0a74.823 74.823 0 005.159-4.207c1.151-1.035 2.326-2.175 3.31-3.305.968-1.113 1.815-2.287 2.232-3.39l-1.871-.707c-.289.763-.945 1.721-1.87 2.784-.91 1.046-2.02 2.125-3.138 3.13a72.868 72.868 0 01-5.011 4.087zm10.7-10.902c1.249-3.3.292-6.855-2.164-8.62L21.119 3.84c1.612 1.157 2.439 3.702 1.461 6.288zm-2.164-8.62C21.25 1.471 19.87 1 18.379 1v2c1.081 0 2.051.344 2.74.839z"
                                          />
                                        </svg>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="HomeCardShareButton homecard-clickable">
                                    <svg className="SvgIcon share-forward-icon clickable share-icon">
                                      <svg viewBox="0 0 24 24">
                                        <path
                                          opacity=".5"
                                          className="share-forward-icon-fill fill"
                                          d="M11.986 15.982c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.755A.753.753 0 010 20.214c.144-3.693 1.336-6.637 3.549-8.76C6.412 8.71 10.18 8.127 11.986 8.01V3.452a.25.25 0 01.403-.197l10.971 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.405-.197v-4.538z"
                                        />
                                        <path
                                          className="share-forward-icon-outline outline"
                                          d="M12.382 13.99c.374 0 .609.01.66.011l.958.04v2.914L20.37 12 14 7.044v2.994L12.96 10c-.044 0-4.833-.146-8.024 2.925-.921.886-1.627 1.962-2.116 3.216 3.072-1.959 7.82-2.15 9.562-2.15M12 15.996c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.769a.753.753 0 01-.754-.772c.144-3.693 1.336-6.637 3.549-8.759C6.426 8.723 10.196 8.14 12 8.024V3.466a.25.25 0 01.404-.197l10.97 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.404-.197v-4.538z"
                                        />
                                      </svg>
                                    </svg>
                                  </div>
                                  <div className="HomeStatsV2 font-size-small ">
                                    <div className="stats">3 Beds</div>
                                    <div className="stats">2 Baths</div>
                                    <div className="stats">1,660 Sq. Ft.</div>
                                  </div>
                                  <div className="homeAddressV2">
                                    <div
                                      className="addressDisplay font-size-smaller"
                                      data-rf-test-id="abp-homeinfo-homeaddress"
                                    >
                                      <span
                                        className="collapsedAddress primaryLine"
                                        data-rf-test-id="abp-streetLine"
                                      >
                                        409 Auburn St #2, Newton, MA 02466
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    to="#"
                                    title="409 Auburn St #2, Newton, MA 02466"
                                    target="_blank"
                                  >
                                    <div className="link-and-anchor">
                                      409 Auburn St #2, Newton, MA 02466
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="BaseCarousel__cell"
                            style={{ width: "480px" }}
                            aria-hidden="false"
                            ioindex={1}
                          >
                            <div className="FeedHomeCard">
                              <div className="v2 interactive" tabIndex={0}>
                                <div
                                  className="homecardv2"
                                  data-rf-test-name="basicNode-homeCard"
                                >
                                  <div className="cover-all">
                                    <div className="PhotoSlider photoContainer">
                                      <div
                                        className="scrollable"
                                        style={{ transform: "translateX(0px)" }}
                                      >
                                        <div
                                          className="slider-item "
                                          data-rf-test-id="slider-item--1"
                                          style={{
                                            transform: "translateX(-472px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="397 Linwood Ave #2, Newton, MA 02460"
                                            alt="Photo of 397 Linwood Ave #2, Newton, MA 02460"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/867/genIsm.73080867_18_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </div>
                                        <Link
                                          to="/buy"
                                          target="_blank"
                                          className="slider-item "
                                          data-rf-test-id="slider-item-0"
                                          data-rf-test-name="basic-card-photo"
                                          style={{
                                            transform: "translateX(0px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="397 Linwood Ave #2, Newton, MA 02460"
                                            alt="Photo of 397 Linwood Ave #2, Newton, MA 02460"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/867/genIsm.73080867_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </Link>
                                        <div
                                          className="slider-item "
                                          data-rf-test-id="slider-item-1"
                                          style={{
                                            transform: "translateX(472px)",
                                          }}
                                        >
                                          <img
                                            className=" homecard-image"
                                            title="397 Linwood Ave #2, Newton, MA 02460"
                                            alt="Photo of 397 Linwood Ave #2, Newton, MA 02460"
                                            src="https://ssl.cdn-redfin.com/photo/52/ismphoto/867/genIsm.73080867_1_0.jpg"
                                            height="100%"
                                            width="100%"
                                            fetchpriority="auto"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                              objectFit: "cover",
                                              objectPosition: "center center",
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    role="button"
                                    aria-label="previous button"
                                    tabIndex={0}
                                    className="left-nav clickable"
                                    data-rf-test-name="left-nav"
                                  >
                                    <svg className="SvgIcon slide-previous">
                                      <svg viewBox="0 0 24 24">
                                        <g fillRule="evenodd">
                                          <path d="M16.426 23.134L5.074 11.78a.25.25 0 010-.353L16.426.073a.25.25 0 01.355 0l1.06 1.06a.25.25 0 010 .355L7.724 11.603 17.841 21.72a.25.25 0 010 .355l-1.06 1.06a.25.25 0 01-.355 0" />
                                        </g>
                                      </svg>
                                    </svg>
                                  </div>
                                  <div
                                    role="button"
                                    aria-label="next button"
                                    tabIndex={0}
                                    className="right-nav clickable"
                                    data-rf-test-name="right-nav"
                                  >
                                    <svg className="SvgIcon slide-next">
                                      <svg viewBox="0 0 24 24">
                                        <g fillRule="evenodd">
                                          <path d="M7.134 23.134l-1.06-1.06a.25.25 0 010-.355L16.19 11.603 6.074 1.488a.25.25 0 010-.355l1.06-1.06a.25.25 0 01.354 0L18.84 11.427a.25.25 0 010 .353L7.488 23.134a.25.25 0 01-.354 0" />
                                        </g>
                                      </svg>
                                    </svg>
                                  </div>
                                  <div className="Pill Pill--default padding-vert-smallest padding-horiz-smaller font-size-smaller font-weight-bold font-color-white media-index no-opacity">
                                    1 / 19
                                  </div>
                                  <div className="bottomright">
                                    <div className="mlsLogoExtension" />
                                    <div
                                      className="MapToggleButton"
                                      data-rf-test-name="MapToggleButton"
                                    >
                                      <button
                                        type="button"
                                        className="button Button PhotoMapToggle icon unpadded suppressMinWidth"
                                        tabIndex={0}
                                        role="button"
                                        aria-label="PhotoMapToggle"
                                      >
                                        <div className="svg-icon">
                                          <svg className="SvgIcon MapAlt">
                                            <svg viewBox="0 0 36 36">
                                              <path d="M0 0h36v36H0z" />
                                              <circle
                                                opacity=".7"
                                                cx={18}
                                                cy={18}
                                                r="15.75"
                                                fillOpacity=".7"
                                              />
                                              <path d="M15.236 10.543a.832.832 0 01.527 0l4.737 1.579 3.64-1.214a1.667 1.667 0 012.193 1.582v9.91c0 .717-.459 1.354-1.14 1.58l-4.43 1.477a.832.832 0 01-.527 0L15.5 23.878l-3.64 1.214a1.667 1.667 0 01-2.193-1.581V13.6c0-.718.459-1.355 1.14-1.582l4.43-1.476zm1.097 11.856l3.334 1.111V13.6l-3.334-1.11v9.91zm-1.666-9.91l-3.334 1.112v9.91l3.334-1.112v-9.91zm6.666 1.112v9.91l3.334-1.112v-9.91l-3.334 1.112z" />
                                            </svg>
                                          </svg>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div className="bottomV2 ">
                                  <span
                                    className="homecardV2Price"
                                    data-rf-test-name="homecard-price"
                                  >
                                    $1,089,000
                                  </span>
                                  <div className="favoriteButtonV2 homecard-clickable">
                                    <div
                                      className="FavoriteExtension favoriteButtonV2 clickable fav-off"
                                      data-rf-test-id="favoriteExtension"
                                      role="checkbox"
                                      aria-label="favorite button"
                                      aria-checked="false"
                                    >
                                      <svg className="SvgIcon favorite-actionable clickable favorite-icon">
                                        <svg viewBox="0 0 26 23">
                                          <path
                                            className="favorite-actionable-fill fill"
                                            d="M18.38 2c-1.753 0-3.712.756-5.368 2.766C11.356 2.756 9.397 2 7.644 2c-1.286 0-2.46.407-3.322 1.026C2.287 4.487 1.337 7.56 2.508 10.48c1.517 3.783 9.212 9.6 10.36 10.452a.233.233 0 00.287 0c1.158-.856 8.95-6.72 10.36-10.452 1.113-2.943.221-5.993-1.813-7.454C20.84 2.407 19.665 2 18.38 2z"
                                          />
                                          <path
                                            className="favorite-actionable-outline outline"
                                            d="M13.012 4.766l-.771.636.771.937.772-.937zm-8.69-1.74l.584.813zM2.508 10.48l-.928.373zm10.36 10.452l-.596.803zm.287 0l-.594-.804-.006.004zm10.36-10.452l-.935-.353zm-1.813-7.454l.584-.812zM18.38 1c-2.061 0-4.3.898-6.138 3.13l1.543 1.272C15.257 3.615 16.937 3 18.38 3zm-4.595 3.13C11.945 1.899 9.707 1 7.644 1v2c1.444 0 3.124.615 4.597 2.402zM7.644 1c-1.49 0-2.87.47-3.905 1.215l1.167 1.623C5.593 3.344 6.563 3 7.644 3zM3.74 2.214C1.294 3.97.255 7.547 1.58 10.853l1.857-.745C2.42 7.572 3.28 5.006 4.906 3.838zM1.58 10.853c.443 1.104 1.298 2.278 2.273 3.394.988 1.132 2.16 2.27 3.304 3.301a75.12 75.12 0 005.115 4.187l1.193-1.605a73.097 73.097 0 01-4.969-4.068c-1.112-1.002-2.22-2.08-3.137-3.13-.93-1.065-1.607-2.036-1.922-2.824zm10.693 10.883c.44.327 1.037.33 1.482-.004l-1.2-1.6a.763.763 0 01.909-.003zm1.477 0a74.823 74.823 0 005.159-4.207c1.151-1.035 2.326-2.175 3.31-3.305.968-1.113 1.815-2.287 2.232-3.39l-1.871-.707c-.289.763-.945 1.721-1.87 2.784-.91 1.046-2.02 2.125-3.138 3.13a72.868 72.868 0 01-5.011 4.087zm10.7-10.902c1.249-3.3.292-6.855-2.164-8.62L21.119 3.84c1.612 1.157 2.439 3.702 1.461 6.288zm-2.164-8.62C21.25 1.471 19.87 1 18.379 1v2c1.081 0 2.051.344 2.74.839z"
                                          />
                                        </svg>
                                      </svg>
                                    </div>
                                  </div>
                                  <div className="HomeCardShareButton homecard-clickable">
                                    <svg className="SvgIcon share-forward-icon clickable share-icon">
                                      <svg viewBox="0 0 24 24">
                                        <path
                                          opacity=".5"
                                          className="share-forward-icon-fill fill"
                                          d="M11.986 15.982c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.755A.753.753 0 010 20.214c.144-3.693 1.336-6.637 3.549-8.76C6.412 8.71 10.18 8.127 11.986 8.01V3.452a.25.25 0 01.403-.197l10.971 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.405-.197v-4.538z"
                                        />
                                        <path
                                          className="share-forward-icon-outline outline"
                                          d="M12.382 13.99c.374 0 .609.01.66.011l.958.04v2.914L20.37 12 14 7.044v2.994L12.96 10c-.044 0-4.833-.146-8.024 2.925-.921.886-1.627 1.962-2.116 3.216 3.072-1.959 7.82-2.15 9.562-2.15M12 15.996c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.769a.753.753 0 01-.754-.772c.144-3.693 1.336-6.637 3.549-8.759C6.426 8.723 10.196 8.14 12 8.024V3.466a.25.25 0 01.404-.197l10.97 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.404-.197v-4.538z"
                                        />
                                      </svg>
                                    </svg>
                                  </div>
                                  <div className="HomeStatsV2 font-size-small">
                                    <div className="stats">3 Beds</div>
                                    <div className="stats">2 Baths</div>
                                    <div className="stats">1,700 Sq. Ft.</div>
                                  </div>
                                  <div className="homeAddressV2">
                                    <div
                                      className="addressDisplay font-size-smaller"
                                      data-rf-test-id="abp-homeinfo-homeaddress"
                                    >
                                      <span
                                        className="collapsedAddress primaryLine"
                                        data-rf-test-id="abp-streetLine"
                                      >
                                        397 Linwood Ave #2, Newton, MA 02460
                                      </span>
                                    </div>
                                  </div>
                                  <Link
                                    to="#"
                                    title="397 Linwood Ave #2, Newton, MA 02460"
                                    target="_blank"
                                  >
                                    <div className="link-and-anchor">
                                      397 Linwood Ave #2, Newton, MA 02460
                                    </div>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </li>
                          <li
                            className="BaseCarousel__cell"
                            // style={{ width: "480px" }}
                            style={{ display: "none" }}
                            aria-hidden="false"
                            ioindex={2}
                          >
                            <div className="RegWallHomeCard card-v2-border">
                              <div className="photoWrapper padding-bottom-large">
                                <img
                                  className="photo"
                                  alt="Photo of 501 Lexington St #29, Waltham, MA 02452"
                                  src="https://ssl.cdn-redfin.com/photo/52/ismphoto/024/genIsm.73081024_0.jpg"
                                />
                              </div>
                              <div className="padding-larger" />
                              <div className="bottomOverlay text-center">
                                <div className="bottom padding-medium">
                                  <h2 className="moreRecommendations font-size-medium padding-bottom-medium text-left font-weight-bold">
                                    Sign up to see 3 more recommendations for
                                    you
                                  </h2>
                                  <button
                                    type="button"
                                    className="button Button joinButton primary margin-bottom-small"
                                    tabIndex={0}
                                    role="button"
                                    data-rf-test-name="RegWallHomeCardJoin"
                                  >
                                    <span>Join </span>
                                  </button>
                                  <span className="alreadyHaveAccountText font-size-small font-color-gray-light">
                                    Already have an account?
                                  </span>
                                  <button
                                    type="button"
                                    className="button Button font-weight-normal font-size-base text compact signIn unpadded"
                                    tabIndex={0}
                                    role="button"
                                    data-rf-test-name="RegWallHomeCardSignIn"
                                  >
                                    <span> Sign In</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                        <div
                          className="CarouselArrow CarouselArrow--previous clickable hidden"
                          role="button"
                          aria-label="previous"
                          tabIndex={0}
                        >
                          <svg className="SvgIcon breadcrumb size-tiny">
                            <svg viewBox="0 0 8 12">
                              <path
                                d="M1.214 11.921L.079 10.786a.268.268 0 010-.379L4.486 6 .079 1.593a.268.268 0 010-.38L1.214.08a.268.268 0 01.379 0L7.325 5.81a.268.268 0 010 .38L1.593 11.92a.268.268 0 01-.38 0"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </div>
                        <div
                          className="CarouselArrow CarouselArrow--next clickable"
                          role="button"
                          aria-label="next"
                          tabIndex={0}
                        >
                          <svg
                            className="SvgIcon breadcrumb size-tiny"
                            style={{ display: "none" }}
                          >
                            <svg viewBox="0 0 8 12">
                              <path
                                d="M1.214 11.921L.079 10.786a.268.268 0 010-.379L4.486 6 .079 1.593a.268.268 0 010-.38L1.214.08a.268.268 0 01.379 0L7.325 5.81a.268.268 0 010 .38L1.593 11.92a.268.268 0 01-.38 0"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-react-server-root-id={16}
              data-react-server-timing-offset={90}
            >
              <section className="SideBySideImageTextWidget bg-white img-left font-color-black padding-bottom-compact padding-top-compact">
                <div className="content-row row-center">
                  <div className="visual-section col-12 col-lg-6">
                    <img
                      src={n}
                      alt="A woman showing a document for a backyard consultation."
                    />
                  </div>
                  <div className="action-section header-type-configured col-12 col-lg-6">
                    <div className="left-margin">
                      <div className="header padding-bottom-medium">
                        <h2 className="heading-large margin-vert-medium heading-size-preset-3 text-and-disclaimer-flyout">
                          <span>Proven expertise for luxury homes</span>
                        </h2>
                      </div>
                      <div className="copy">
                        <div className="font-line-height-spacious text-and-disclaimer-flyout">
                          <span>
                            Premier agents have extensive experience buying and
                            selling high-end homes, ranking them in the top 1%
                            of agents working in luxury real estate.
                          </span>
                        </div>
                      </div>
                      <div className="cta primary_button">
                        <Link
                          to="/invest"
                          className="button Button margin-top-larger primary"
                          tabIndex={0}
                          role="button"
                        >
                          <span>Learn more</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              data-react-server-root-id={17}
              data-react-server-timing-offset={90}
            >
              <section className="SideBySideImageTextWidget bg-white img-right font-color-black padding-bottom-compact padding-top-compact">
                <div className="content-row row-center">
                  <div className="visual-section col-12 col-lg-6">
                    <img
                      src={o}
                      alt=" for sale yard sign in front of a house."
                    />
                  </div>
                  <div className="action-section header-type-configured col-12 col-lg-6">
                    <div className="right-margin">
                      <div className="header padding-bottom-medium">
                        <h2 className="heading-large margin-vert-medium heading-size-preset-3 text-and-disclaimer-flyout">
                          <span>Sell for more than the home next door</span>
                        </h2>
                      </div>
                      <div className="copy">
                        <div className="font-line-height-spacious text-and-disclaimer-flyout">
                          <span>
                            For seven years running, independent research has
                            proven that homes listed with sell for more than
                            comparable homes listed with other brokerages.
                          </span>
                          <Link
                            className="DisclaimerFlyoutAsterisk"
                            to="#"
                            aria-label="disclaimer"
                          >
                            <svg className="SvgIcon asterisk size-tiny">
                              <svg viewBox="0 0 14 14">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14 7A7 7 0 110 7a7 7 0 0114 0zM6.996 8.207c.038 0 .064.012.076.038l1.368 2.26a.187.187 0 00.171.096.509.509 0 00.247-.095l.798-.551c.127-.076.19-.159.19-.247 0-.038-.025-.095-.076-.171L8.174 7.599c-.038-.038-.057-.076-.057-.114a.122.122 0 01.095-.095l2.28-.684c.114-.026.171-.095.171-.21a.264.264 0 00-.018-.113l-.304-.95c-.026-.127-.09-.19-.19-.19a.292.292 0 00-.114.038l-2.28.988c-.039.012-.07.012-.096 0-.025-.013-.038-.038-.038-.076v-.038l.19-2.546a.182.182 0 00-.057-.133c-.025-.051-.076-.076-.152-.076H6.388a.205.205 0 00-.152.057c-.038.038-.057.076-.057.114v.038l.19 2.527c0 .088-.038.133-.114.133l-.076-.02-2.204-.93c-.126-.064-.221-.026-.285.114l-.36.969c-.064.19-.026.304.113.342l2.337.646c.076.012.114.05.114.114l-.038.076-1.672 2.052c-.05.05-.076.101-.076.152 0 .05.038.107.114.17l.912.647c.076.038.14.057.19.057.09 0 .152-.026.19-.076L6.92 8.245c.026-.026.051-.038.076-.038z"
                                />
                              </svg>
                            </svg>
                          </Link>
                          <span> Get started with a free consultation.</span>
                        </div>
                      </div>
                      <div className="cta seller_consult_flow">
                        <section
                          className="HorizSellerConsultForm bg-white SCFV2"
                          id="seller-consultation"
                        >
                          <div
                            className="seller-consult-form-container"
                            id="consult-form"
                          >
                            <div className="header-container header-type-configured" />
                            <div className="SellerConsultationFormWrapper">
                              <div
                                className="SellerConsultationFormComponent SellerConsultationFormComponentV2"
                                data-rf-test-name="sellerConsultationForm"
                              >
                                <div className="SellerConsultationAddress">
                                  <div className="addressRow">
                                    <div className="addressWrapper">
                                      <div className="addressContainer field required">
                                        <span className="label" />
                                        <div
                                          data-rf-test-name="search-box"
                                          className="SearchBox"
                                          tabIndex={-1}
                                          style={{ visibility: "hidden" }}
                                        >
                                          <form
                                            className="SearchBoxForm"
                                            action="?"
                                            method="POST"
                                          >
                                            <div
                                              data-rf-test-name="search-container"
                                              className="search-container inline-block"
                                            >
                                              <div className="InputBox">
                                                <noscript />
                                                <input
                                                  type="search"
                                                  data-rf-test-name="search-box-input"
                                                  id="search-box-input"
                                                  className="search-input-box"
                                                  name="searchInputBox"
                                                  autoComplete="off"
                                                  aria-autocomplete="list"
                                                  aria-haspopup="listbox"
                                                  tabIndex={0}
                                                  aria-invalid="false"
                                                  placeholder="Enter your street address"
                                                  title="Enter your street address"
                                                />
                                                <div className="inline-block clear-button-block">
                                                  <input
                                                    type="button"
                                                    aria-label="clear input"
                                                    aria-hidden="true"
                                                    className="btn-clear-search-input clickable hidden"
                                                    data-rf-test-name="search-box-clear"
                                                    title="Clear"
                                                  />
                                                  <svg className="SvgIcon clear clear-icon-search-input size-small hidden">
                                                    <svg viewBox="0 0 24 24">
                                                      <g fillRule="nonzero">
                                                        <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z" />
                                                        <path d="M16.772 8.288l-1.06-1.06a.25.25 0 00-.354 0L12 10.586 8.642 7.228a.25.25 0 00-.354 0l-1.06 1.06a.25.25 0 000 .354L10.586 12l-3.358 3.358a.25.25 0 000 .354l1.06 1.06a.25.25 0 00.354 0L12 13.414l3.358 3.358a.25.25 0 00.354 0l1.06-1.06a.25.25 0 000-.354L13.414 12l3.358-3.358a.25.25 0 000-.354z" />
                                                      </g>
                                                    </svg>
                                                  </svg>
                                                </div>
                                              </div>
                                              <button
                                                data-rf-test-name="searchButton"
                                                aria-label="submit search"
                                                className="inline-block SearchButton clickable float-right"
                                                type="submit"
                                                value
                                                title="Search"
                                                tabIndex={2}
                                              >
                                                <svg className="SvgIcon search">
                                                  <svg viewBox="0 0 24 24">
                                                    <g fillRule="evenodd">
                                                      <path d="M23.884 21.763l-7.554-7.554a8.976 8.976 0 001.526-6.835C17.203 3.68 14.204.72 10.502.122a9.01 9.01 0 00-10.38 10.38c.598 3.702 3.558 6.7 7.252 7.354a8.976 8.976 0 006.835-1.526l7.554 7.554a.25.25 0 00.353 0l1.768-1.768a.25.25 0 000-.353zM2 9c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                                                    </g>
                                                  </svg>
                                                </svg>
                                              </button>
                                            </div>
                                          </form>
                                          <noscript />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="addressRowSubmitButton">
                                      <div className="buttonContainer nextButton submitBtn">
                                        <button
                                          type="submit"
                                          className="button Button v3 primary"
                                          tabIndex={0}
                                          role="button"
                                          style={{ visibility: "hidden" }}
                                        >
                                          <span>Next</span>
                                        </button>
                                      </div>
                                    </div>
                                    <div className="clear" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              data-react-server-root-id={16}
              data-react-server-timing-offset={90}
            >
              <section className="SideBySideImageTextWidget bg-white img-left font-color-black padding-bottom-compact padding-top-compact">
                <div className="content-row row-center">
                  <div className="visual-section col-12 col-lg-6">
                    <img
                      src={marina}
                      alt="A woman showing a document for a backyard consultation."
                    />
                  </div>
                  <div className="action-section header-type-configured col-12 col-lg-6">
                    <div className="left-margin">
                      <div className="header padding-bottom-medium">
                        <h2 className="heading-large margin-vert-medium heading-size-preset-3 text-and-disclaimer-flyout">
                          <span>DUBAI MARINA RESORT</span>
                       
                        </h2>
                        <p>
                            A Visionary Development Redefining Urban Living.
                          </p>
                      </div>
                      <div className="copy">
                        <div className="font-line-height-spacious text-and-disclaimer-flyout">
                          <span>
                            We are thrilled to announce an exceptional real
                            estate project that promises to redefine urban
                            living and set new standards of luxury and
                            convenience. Dubai Marina Resort is a visionary
                            development that combines modern design,
                            cutting-edge amenities, and a prime location to
                            create an unparalleled living experience.
                          </span>
                        </div>
                      </div>
                      <div className="cta primary_button">
                        <Link
                          to="/project"
                          className="button Button margin-top-larger primary"
                          tabIndex={0}
                          role="button"
                        >
                          <span>Learn more</span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div
              data-react-server-root-id={18}
              data-react-server-timing-offset={90}
            >
              <section className="SideBySideImageTextWidget bg-white img-left font-color-black padding-bottom-compact padding-top-compact">
                <div className="content-row row-center">
                  <div className="visual-section col-12 col-lg-6">
                    <img
                      src={m}
                      alt="A street lined with multi-unit residence buildings."
                    />
                  </div>
                  <div className="action-section header-type-configured col-12 col-lg-6">
                    <div className="left-margin">
                      <div className="header padding-bottom-medium">
                        <h2 className="heading-large margin-vert-medium heading-size-preset-3 text-and-disclaimer-flyout">
                          <span>Find your perfect rental on Redcoral </span>
                        </h2>
                      </div>
                      <div className="copy">
                        <div className="font-line-height-spacious text-and-disclaimer-flyout">
                          <span>
                            Whether you’re searching for houses, apartments, or
                            condos, it's easy to find a place you'll love.
                          </span>
                        </div>
                      </div>
                      <div
                        className="cta rental_search_box"
                        style={{ visibility: "hidden" }}
                      >
                        <div
                          data-rf-test-name="search-box"
                          className="SearchBox"
                          tabIndex={-1}
                        >
                          <form
                            className="SearchBoxForm"
                            action="?"
                            method="POST"
                          >
                            <div
                              data-rf-test-name="search-container"
                              className="search-container inline-block"
                            >
                              <div className="InputBox">
                                <noscript />
                                <input
                                  type="search"
                                  data-rf-test-name="search-box-input"
                                  id="search-box-input"
                                  className="search-input-box"
                                  name="searchInputBox"
                                  autoComplete="off"
                                  aria-autocomplete="list"
                                  aria-haspopup="listbox"
                                  tabIndex={1}
                                  aria-invalid="false"
                                  placeholder="City, Address, School, Building, ZIP"
                                  title="City, Address, School, Building, ZIP"
                                />
                                <div className="inline-block clear-button-block">
                                  <input
                                    type="button"
                                    aria-label="clear input"
                                    aria-hidden="true"
                                    className="btn-clear-search-input clickable hidden"
                                    data-rf-test-name="search-box-clear"
                                    title="Clear"
                                  />
                                  <svg className="SvgIcon clear clear-icon-search-input size-small hidden">
                                    <svg viewBox="0 0 24 24">
                                      <g fillRule="nonzero">
                                        <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z" />
                                        <path d="M16.772 8.288l-1.06-1.06a.25.25 0 00-.354 0L12 10.586 8.642 7.228a.25.25 0 00-.354 0l-1.06 1.06a.25.25 0 000 .354L10.586 12l-3.358 3.358a.25.25 0 000 .354l1.06 1.06a.25.25 0 00.354 0L12 13.414l3.358 3.358a.25.25 0 00.354 0l1.06-1.06a.25.25 0 000-.354L13.414 12l3.358-3.358a.25.25 0 000-.354z" />
                                      </g>
                                    </svg>
                                  </svg>
                                </div>
                              </div>
                              <button
                                data-rf-test-name="searchButton"
                                aria-label="submit search"
                                className="inline-block SearchButton clickable float-right"
                                type="submit"
                                value
                                title="Search"
                                tabIndex={2}
                              >
                                <svg className="SvgIcon search">
                                  <svg viewBox="0 0 24 24">
                                    <g fillRule="evenodd">
                                      <path d="M23.884 21.763l-7.554-7.554a8.976 8.976 0 001.526-6.835C17.203 3.68 14.204.72 10.502.122a9.01 9.01 0 00-10.38 10.38c.598 3.702 3.558 6.7 7.252 7.354a8.976 8.976 0 006.835-1.526l7.554 7.554a.25.25 0 00.353 0l1.768-1.768a.25.25 0 000-.353zM2 9c0-3.86 3.14-7 7-7s7 3.14 7 7-3.14 7-7 7-7-3.14-7-7z" />
                                    </g>
                                  </svg>
                                </svg>
                              </button>
                            </div>
                          </form>
                          <noscript />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div
              data-react-server-root-id={19}
              data-react-server-timing-offset={90}
            ></div>
            <div
              data-react-server-root-id={20}
              data-react-server-timing-offset={90}
            >
              <div className="SideBySideGeneralInquiryFormWidget bg-gray padding-bottom-compact padding-top-compact">
                <div
                  className="horiz-gif-container oneCol multipleFieldsVisible row-center-flex"
                  id="consult-form"
                >
                  <div className="title-container header-type-configured">
                    <h2 className="heading-large margin-vert-medium title  text-and-disclaimer-flyout">
                      <span>Talk to a Redcoral agent</span>
                    </h2>
                    <p className="subtitle">
                      You’ll be connected with an expert local agent—there’s no
                      pressure or obligation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-react-server-root-id={21}
              data-react-server-timing-offset={103}
            ></div>
            <div
              data-react-server-root-id={22}
              data-react-server-timing-offset={104}
            >
              <div className="TextWidget bg-white padding-horiz-none padding-bottom-compact padding-top-compact">
                <div className="row-center">
                  <div className="col-md-10 col-push-md-1 col-lg-6 col-push-lg-3">
                    <div>
                      <small className="Disclaimer text-small text-left text-and-disclaimer-flyout">
                        <span>
                          *Fees subject to change, minimums apply. Buyer’s agent
                          fee not included, e.g., if buyer’s agent fee is 2.5%,
                          seller will pay a total fee of 3.5%. Listing fee
                          increased by 1% of sale price if buyer is
                          unrepresented. Sell for a 1% listing fee only if you
                          also buy with within 365 days of closing on your
                          listing. We will charge a 1.5% listing fee, then send
                          you a check for the 0.5% difference after you buy your
                          next home with us.{" "}
                          <Link to="/invest">Learn more</Link>.
                        </span>
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            data-react-server-root-id={34}
            data-react-server-timing-offset={104}
          />
          <div data-react-server-container={35}>
            <div
              data-react-server-root-id={36}
              data-react-server-timing-offset={104}
            />
            <div
              data-react-server-root-id={37}
              data-react-server-timing-offset={104}
            />
            <div
              data-react-server-root-id={38}
              data-react-server-timing-offset={104}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={40}
            data-react-server-timing-offset={104}
          >
            <Footer />
          </div>
          <div
            data-react-server-root-id={41}
            data-react-server-timing-offset={104}
          >
            <noscript />
          </div>
        </div>

        <div
          style={{
            width: "0px",
            height: "0px",
            display: "none",
            visibility: "hidden",
          }}
          id="batBeacon230227613202"
        >
          <img
            style={{
              width: "0px",
              height: "0px",
              display: "none",
              visibility: "hidden",
            }}
            id="batBeacon186206709672"
            width={0}
            height={0}
            alt=""
            src="https://bat.bing.com/action/0?ti=5220234&tm=gtm002&Ver=2&mid=e2bb3c6e-45e2-4d69-a9bc-c6f16f5ffed9&sid=ad342c50b43611ed8b582917df2da0d0&vid=ad34bad0b43611ed8ca351d5c73c14c3&vids=0&msclkid=N&uach=pv%3D10.0.0&pi=918639831&lg=en-US&sw=1366&sh=768&sc=24&tl=Real%20Estate,%20Homes%20for%20Sale,%20MLS%20Listings,%20Agents%20%7C%20Red Coral Real Estates&p=https%3A%2F%2Fwww..com%2F&r=https%3A%2F%2Fwww..com%2Fcity%2F11619%2FMA%2FNewton%2Fcondos&lt=858&evt=pageLoad&sv=1&rn=320646"
          />
        </div>
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Fri%2C%2024%20Feb%202023%2011%3A40%3A08%20GMT&n=-1&b=Real%20Estate%2C%20Homes%20for%20Sale%2C%20MLS%20Listings%2C%20Agents%20%7C%20Red Coral Real Estates&.yp=10184908&f=https%3A%2F%2Fwww..com%2F&e=https%3A%2F%2Fwww..com%2Fcity%2F11619%2FMA%2FNewton%2Fcondos&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />
        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div className="ot-pc-logo" role="img" aria-label="-logo">
                  <img
                    alt="-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>

              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
          </div>
        </div>
        <iframe
          id="ssIFrame_google"
          sandbox="allow-scripts allow-same-origin"
          aria-hidden="true"
          frame-border={0}
          src="https://accounts.google.com/o/oauth2/iframe#origin=https%3A%2F%2Fwww..com&rpcToken=1635082194.7160685"
          style={{
            position: "absolute",
            width: "1px",
            height: "1px",
            inset: "-9999px",
            display: "none",
          }}
        />
        <iframe
          src="//s.amazon-adsystem.com/iu3?d=generic&ex-fargs=%3Fid%3D5510a2fa-9c5c-b0c9-d1f8-5270b0333d38%26type%3D7%26m%3D1&ex-fch=416613&ex-src=https://www..com/&ex-hargs=v%3D1.0%3Bc%3D1328324616230%3Bp%3D5510A2FA-9C5C-B0C9-D1F8-5270B0333D38&cb=809316658361163800"
          id="_pix_id_5510a2fa-9c5c-b0c9-d1f8-5270b0333d38"
          style={{ display: "none" }}
        />
        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
