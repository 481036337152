import React from 'react'
import { Link } from "react-router-dom";
import Footer from "./Footer"
import Navbar2 from "./Navbar2"
import a from "./Images/1.jpg"
import b from "./Images/career21.jpg"
import c from "./Images/contacts.jpg"
import d from "./Images/d.jpg"


function Invest() {
  return (
    <div>
         <div>
        <div className="general-wrapper">
     <Navbar2 />
          <div className="page-hero ir-hero overlay dark ir-cta-presentation" style={{background: `url(${a})`, backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#333'}}>
            <div className="container">
              <div className="vertical-align">
                <div className="wrapper">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="date">Investor relations</div>
                      <h3>Redefining real estate in the consumer’s favor</h3>
                      <p>redcoral helps people buy and sell homes in over 100 markets across the U.S. and Canada. As a residential real estate brokerage, we combine our agents and technology to create a faster, better and more affordable service.</p>                                                                        <div className="inline-btn">
                        <Link to="#" className="btn" title="Opens in a new window" style={{display:"none"}}>View presentation</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>        <nav id="sectionNav" className="sub-navigation-wrapper" aria-label="Investors Section">
            <div className="sub-navigation affixed">
              <div className="container">
                <Link className="visible-xs visible-sm mobile-sub-nav-trigger collapsed" data-toggle="collapse" data-target="#sub-navigation">
                  <div className="trigger-container">
                    <div className="meat" />
                    <div className="meat" />
                    <div className="meat" />
                  </div>
                  Investors          </Link>
                <div className="collapse sb-nav clearfix" id="sub-navigation">
                  <h3>Investors</h3>
                  <ul>
                    <li className="active active-landing">
                      <Link to="/" >Overview</Link>
                    </li>
                    <li className="has-children dropdown">
                      <Link to="#" >News &amp; events</Link>
                      <ul className="dropdown-menu">
                        <li className>
                          <Link to="#" >Press releases</Link>
                        </li>
                        <li className>
                          <Link to="#" >IR calendar</Link>
                        </li>
                        <li className>
                          <Link to="#" >Email alerts</Link>
                        </li>
                        <li className>
                          <Link to="#">redcoral news</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children dropdown">
                      <Link to="#" >Company info</Link>
                      <ul className="dropdown-menu">
                        <li className>
                          <Link to="#" >Management team</Link>
                        </li>
                        <li className>
                          <Link to="#" >Presentations</Link>
                        </li>
                        <li className>
                          <Link to="#" >Contacts</Link>
                        </li>
                        <li className>
                          <Link to="#" >FAQ</Link>
                        </li>
                        <li className>
                          <Link to="#" >Annual reports &amp; proxy</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children dropdown">
                      <Link to="#" >Financial info</Link>
                      <ul className="dropdown-menu">
                        <li className>
                          <Link to="#" >Financial results</Link>
                        </li>
                        <li className>
                          <Link to="#" >SEC filings</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children dropdown">
                      <Link to="#" >Stock data</Link>
                      <ul className="dropdown-menu">
                        <li className>
                          <Link to="#" >Quote</Link>
                        </li>
                        <li className>
                          <Link to="#" >Historical data</Link>
                        </li>
                        <li className>
                          <Link to="#" >Analyst coverage</Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children dropdown">
                      <Link to="#" >Governance</Link>
                      <ul className="dropdown-menu">
                        <li className>
                          <Link to="#" >Board of directors</Link>
                        </li>
                        <li className>
                          <Link to="#" >Board committees</Link>
                        </li>
                        <li className>
                          <Link to="#" >Governance documents</Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </nav>                <main id="mainContent">
            <section className="module module-featured-news-blocks ">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <h2>Latest news</h2>
                    <Link to="#" className="btn desktop-all" style={{display:"none"}}>See all news</Link>
                  </div>
                </div>
                <div className="featured-news-block overlay dark" style={{background: `url(${b})`, backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#333'}}>
                  <div className="row">
                    <div className="col-sm-8 col-md-6">
                      <time className="date" dateTime="2023-03-10T09:00:00">March 10, 2023 • 9:00 am EST</time>
                      <div className="text">
                        <h3><Link to="#">redcoral Reports Rents Drop to Lowest Level in a Year</Link></h3>
                        <a href="https://accounts.redcoralproperty.com/login.php" className="btn">Start now</a>
                      </div>
                    </div>{/*end col*/}
                  </div>{/*end row*/}
                </div>{/*end featured*/}
                <div className="news-blocks">
                  <div className="news-block">
                    <a  href="https://accounts.redcoralproperty.com/login.php">
                      <article className="text">
                        <time className="date" dateTime="2023-03-10T08:00:00">March 10, 2023 • 8:00 am EST</time>
                        <h3 data-mh="news-block" style={{}}>Luxury-Home Purchases Sink a Record 45% to the Second-Lowest Level on Record</h3>
                        <span>Start now</span>
                      </article>
                    </a>
                  </div>{/*end cell*/}
                  <div className="news-block">
                    <Link to="#">
                      <article className="text">
                        <time className="date" dateTime="2023-03-09T15:29:00">March 9, 2023 • 3:29 pm EST</time>
                        <h3 data-mh="news-block" style={{}}>redcoral Reports Homebuyers’ Monthly Payments Hit All-Time High As Mortgage Rates Rise</h3>
                        <span>Start now</span>
                      </article>
                    </Link>
                  </div>{/*end cell*/}
                  <div className="news-block">
                    <a  href="https://accounts.redcoralproperty.com/login.php">
                      <article className="text">
                        <time className="date" dateTime="2023-03-06T08:00:00">March 6, 2023 • 8:00 am EST</time>
                        <h3 data-mh="news-block" style={{}}>redcoral Reports Million-Dollar Homes Are Less Common as High Mortgage Rates Cool the Market</h3>
                        <span>Start now</span>
                      </article>
                    </a>
                  </div>{/*end cell*/}
                </div>{/*end news-blocks*/}
                <Link to="#" className="btn mobile-all" style={{display:"none"}}>See all news</Link>
              </div>
            </section><div className="module module-presentation no-padding-bottom">
          
            </div><div id="financial-results" className="module module-overview-financial-results  no-bg">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="header"><h2>Latest financial results</h2></div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 fin-col results">
                    <h3>FY 2022</h3>
                    <p className="date">Fiscal year ended December 31, 2022</p>
                    <div className="text">
                      <div className=" ">
                        <div>     
                          <div className="result-line">
                            <div className="row justify-space-between">
                              <div className="col-auto">
                                <Link to="#">Earnings release</Link>
                              </div>
                              <div className="col">
                                <Link to="#" target="_blank" rel="noopener">
                                  <span className="eqicon-PDF" />PDF
                                </Link>
                                <Link to="#" title="Redfin Reports Fourth Quarter and Full Year 2022 Financial Results" className="link">
                                  <span className="eqicon-HTML" />HTML
                                </Link>
                              </div>
                            </div>
                          </div>
                      
                          <div className="result-line">
                            <div className="row justify-space-between">
                              <div className="col-auto">
                                <Link to="#" title="10-K Filing Viewer" className="fancybox fancybox.iframe" rel="noopener">10-K <span className="sr-only">Filing</span></Link>
                              </div>
                              <div className="col">
                                <Link to="#" title="10-K" className="link" target="_blank" rel="noopener"><span className="eqicon-PDF" />PDF</Link>
                                <Link to="#" title="10-K Filing Viewer" rel="noopener" className="link fancybox fancybox.iframe"><span className="eqicon-HTML" />HTML</Link>
                              </div>
                            </div>
                          </div><div className="result-line">
                            <div className="row justify-content-between">
                              <div className="col-auto">
                                <Link to="#" title="Download Raw XBRL Files" target="_blank" rel="noopener">XBRL</Link>
                              </div>
                              <div className="col">
                                <Link to="#" ><span className="eqicon-ZIP" />ZIP</Link>
                                <Link to="#" ><span className="eqicon-XLS" />XLS</Link>
                              </div>
                            </div>
                          </div><div className="result-line">
                            <div className="row justify-content-between">
                              <div className="col-auto">
                                <Link to="#">Earnings Presentation</Link>
                              </div>
                              <div className="col">
                                <Link to="#">
                                  <span className="eqicon-PDF" />PDF          </Link>
                              </div>
                            </div>
                          </div>  </div>
                      </div>
                    </div>                           </div>
                  <div className="col-lg-6 fin-col stats-mod">
                    <div className="results-stats">
                      <div className="stat-box">
                        <h2>$480M</h2>
                        <p><strong>Revenue</strong> was $68 million, a decrease of 25% year-over-year.</p>
                      </div>
                      <div className="stat-box">
                        <h2>$37M</h2>
                        <p><strong>Gross profit</strong> was $48 million, a decrease&nbsp;of 45% year-over-year.</p>
                      </div>
                      <div className="stat-box">
                        <h2>44M</h2>
                        <p><strong>redcoral's mobile apps and website</strong> reached 44 million average monthly users, a decrease of 2% year-over-year.</p>
                      </div>
                    </div>
                    <div className="financial-disclaimer">
                    
                    </div>
                  </div>
                </div>
                <div className="ar-row">
                  <section>
                    <h2>Latest annual filing</h2>
                    <p>For fiscal year ending December 31, 2022</p>
                  </section>
                  <section className="report-links">
                    <h3 className="sr-only">Report links</h3>
                    <nav className="inline-btns right" aria-labelledby="document-navigation">
                      <h4 id="document-navigation" className="sr-only">Annual report document links</h4>
                      <Link to="#" className="btn" target="_blank" rel="noopener" title="Opens in a new window" style={{display:"none"}}>View 10-K</Link>
                    </nav>
                  </section>
                </div>
              </div>
            </div>
            <div className="module module-events no-padding-top">
              <div className="container">
                <div className="presentation-events row">
                  <article className="item event col-lg col-md-12">
                    <div className="wrapper" data-mh="item-wrapper" style={{}}>
                      <div className="header" data-mh="presentation-event-header" style={{}}>
                        <h2>Recent event</h2>
                        <Link to="#" className="btn" style={{display:"none"}}>See all events</Link>
                      </div>{/* /header*/}
                      <div className="text overlay dark" style={{background: `url(${c})`, backgroundPosition: '50% 50%', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundColor: '#333'}}>
                        <div className="content">
                          <time dateTime="2023-02-16T16:30:00" className="date">
                            February 16, 2023 1:30 pm PST                          </time>
                          <p className="article-link">Q4 2022 Earnings Conference Call</p>
                          <div className="inline-block-links">
                            <Link to="#"  className="btn" style={{display:"none"}}>View
                              event</Link>
                          </div>
                        </div>
                      </div>{/* /text*/}
                    </div>
                  </article>{/* /item*/}
                </div>
              </div>
            </div>
            <div className="module module-stats neutral-bg">
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-sm-8">
                    <div className="module-content">
                      <div className="text center">
                        <h2>Redcoral corporation at a glance</h2>
                      </div>
                    </div>
                  </div>
                </div>		
                <div className="row stats-row justify-content-center">
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '202px'}}>
                      <span className="number">97,000</span>
                      <p>Customers bought or sold homes with us through 2022</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '202px'}}>
                      <span className="number">$149M</span>
                      <p>Worth of homes bought or sold by our customers through 2022</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '202px'}}>
                      <span className="number">9 million</span>
                      <p>Average monthly visitors to our website and mobile app in 2022</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '202px'}}>
                      <span className="number">5,500</span>
                      <p>Employees</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '177px'}}>
                      <span className="number">3,540</span>
                      <p>Average number of lead agents for 2022</p>
                    </div>
                  </div>
                  <div className="col-md-6 col-lg-4">
                    <div className="stat" data-mh="stat-box" style={{height: '177px'}}>
                      <span className="number">100 +</span>
                      <p>Markets</p>
                    </div>
                  </div>
                </div>
                <div className="source">
                  <p>As of Dec. 31, 2022 • 10-K 2022<br /></p>      </div>	
              </div>
            </div>
            <section className="module module-charts ">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-12 mb-5">
                    <div className="heading center">
                      <h2>Redcoral delivers continued growth</h2>
                      <p>Over the years, our revenue and our presence in the U.S. market have continued to grow.</p>              </div>                
                  </div>
                </div>
                <div className="chart-container">
                  <div className="col-lg-12 chart-wrapper mb-1">
                    <div className="row justify-content-center chart-box">
                      <div className="col-lg-2">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="chart-header">
                              <h3 className="mb-0">Total revenues by segment*</h3>
                              <p>(in thousands)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-10">
                        <div className="chartist-chart centered-chart" data-cid={20804} data-pid={107607} data-chart-type="bar" data-chart-name="charts" data-field-name="additional_stats" data-unit-type="currency" data-financial-chart="true" data-yoy-chart="true" data-chart-title="Total revenues by segment*" data-legend="false" data-series="true" data-stacked-bars="true" data-scale-y-axis={1000}>
                       
                      
                      </div>
                      <div className="col-lg-12">
                        <div className="ct-legend-wrapper">
                          <ul className="ct-legend pl-0">
                            <li className="ct-series ct-series-0">
                              Real estate services                                  </li>
                            <li className="ct-series ct-series-1">
                              Properties                                  </li>
                            <li className="ct-series ct-series-2">
                              Rentals                                  </li>
                            <li className="ct-series ct-series-3">
                              Mortgage                                  </li>
                            <li className="ct-series ct-series-4">
                              Other                                  </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footnotes center"><p>*Includes revenue from mortgage, title and eliminations</p>
                    <p><span>As of Dec. 31, 2022 • 10-K 2022</span></p></div>
                </div>
                <div className="chart-container">
                  <div className="row">
                    <div className="col-12">
                      <div className="source">
                        <h3 className="mb-1">U.S. market share by units</h3>
                        <p>Three months ended</p>                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 chart-wrapper mb-1">
                    <div className="row justify-content-center chart-box">
                      <div className="col-lg-2">
                        <div className="row justify-content-center">
                          <div className="col-lg-12">
                            <div className="chart-header">
                              <h3 className="mb-0">U.S. market share</h3>
                              <p>(in percent)</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                  <div className="footnotes center"><p><span>As of Dec. 31, 2022 • 10-K 2022</span></p>
                    <p></p></div>
                </div>
              </div>
              </div>
            </section><section className="module company-overview neutral-bg">
              <div className="container">
                <div className="row justify-content-center align-items-center flex-row-reverse">
                  <div className="col-lg-6">
                    <figure className="module-image">
                      <img src={d} alt="" />
                    </figure>
                  </div>
                  <div className="col-lg-6">
                    <div className="module-content">
                      <div className="text-area">
                        <div className="text ">
                          <h2>Providing customers with comprehensive real estate solutions</h2>
                          <p>Redcoral is a technology-powered real estate company. We help people find a place to live with brokerage, rentals, lending, title insurance, and renovations services. We sell homes for more money and charge half the fee. We also run the country's #1 real estate brokerage site. Our home-buying customers see homes first with on-demand tours, and our lending and title services help them close quickly. Customers selling a home can have our renovations crew fix up their home to sell for top dollar. Our rentals business empowers millions nationwide to find apartments and houses for rent. Since launching in 2006, we've saved customers more than $1.5 billion in commissions. We serve more than 100 markets across the U.S., Canada and Dubai and employ over 5,000 people.</p><div className="inline-btn d-none">
                            <Link to="#" className="btn">See management team</Link>
                            <Link to="#" className="btn">See board of directors</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>				
              </div>
            </section>
            <div className="subscribe subscribe-module">
            
            </div>
     
            
             </main>
      <Footer/>
        </div>{/* //general-wrapper */}
        <div className="chartist-tooltip" />
      </div>
    </div>
  )
}

export default Invest