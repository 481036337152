import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Navbar2 from "./Navbar2";
import { data } from "./Data2";
import { useState } from "react";

function Details5() {
  const navigate = useNavigate();


  return (
  <div
      className="PhotosView bg-color-white similarHomecardHeight scroll"
      data-rf-test-id="photos-view"
      
    >
      <div
        className="HomeCardsContainer"
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {data.map((data) => {
          return (
            <div
              onClick={() => {
                navigate(`/details/${data.state}`, { state: data });
              }}
            >
              <div className="v2 interactive" tabIndex={0}>
                <div
                  className="homecardv2"
                  data-rf-test-name="basicNode-homeCard"
                >
                  <div className="cover-all">
                    <div className="PhotoSlider photoContainer">
                      <div
                        className="scrollable"
                        style={{ transform: "translateX(0px)" }}
                      >
                        <div
                          className="slider-item "
                          style={{ transform: "translateX(-315px)" }}
                          data-rf-test-id="slider-item--1"
                        >
                          <div className="remarks-container" role="button">
                            <div
                              role="img"
                              style={{
                                backgroundImage: `url(${data.url})`,
                              }}
                              className="HomeCardImage"
                              title
                              aria-label
                            />
                            <div className="remarks withTitle">
                              <p>Condo for Sale in New York:</p>
                              <p>
                                Motivated Seller * 1 Bedroom + Sleeping Den/Home
                                Office 5-Star Amenities Murray Hill Empire State
                                Building Views Low Common Charges/Taxes
                                Corinthian Building - Over 1,000 sqft - South
                                facing - Balcony * Apartment This is your
                                opportunity to own a Large south-facing one
                                bedroom with a home office/sleeping den, a
                                balcony, and 2.5 baths with fantastic Empire
                                State Building views! The living room is
                                spacious, has amazing city views, and is a great
                                space to entertain and have people over! The
                                king-size bedroom is large and has a private
                                balcony, with 4 California closets and an
                                en-suite bath. There is extra space, in the
                                apartment, for a Home Office or a Sleeping Den!
                                The kitchen has
                              </p>
                            </div>
                          </div>
                        </div>
                        <Link
                          to="#"
                          className="slider-item "
                          style={{ transform: "translateX(0px)" }}
                        >
                          <img
                            className=" homecard-image"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                            title="330 E 38th St Unit 24-A, New York, NY 10016"
                            alt="Photo of 330 E 38th St Unit 24-A, New York, NY 10016"
                            src={data.image1}
                            height="100%"
                            width="100%"
                            fetchpriority="auto"
                          />
                        </Link>
                        <div
                          className="slider-item "
                          style={{ transform: "translateX(315px)" }}
                          data-rf-test-id="slider-item-1"
                        >
                          <img
                            className=" homecard-image"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                            title="330 E 38th St Unit 24-A, New York, NY 10016"
                            alt="Photo of 330 E 38th St Unit 24-A, New York, NY 10016"
                            src="https://ssl.cdn-redfin.com/photo/211/islphoto/952/genIslnoResize.OLRS-1594952_1_7X.jpg"
                            height="100%"
                            width="100%"
                            fetchpriority="auto"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    role="button"
                    aria-label="previous button"
                    tabIndex={0}
                    className="left-nav clickable"
                    data-rf-test-name="left-nav"
                  >
                    <svg className="SvgIcon slide-previous">
                      <svg viewBox="0 0 24 24">
                        <g fillRule="evenodd">
                          <path d="M16.426 23.134L5.074 11.78a.25.25 0 010-.353L16.426.073a.25.25 0 01.355 0l1.06 1.06a.25.25 0 010 .355L7.724 11.603 17.841 21.72a.25.25 0 010 .355l-1.06 1.06a.25.25 0 01-.355 0" />
                        </g>
                      </svg>
                    </svg>
                  </div>
                  <div
                    role="button"
                    aria-label="next button"
                    tabIndex={0}
                    className="right-nav clickable"
                    data-rf-test-name="right-nav"
                  >
                    <svg className="SvgIcon slide-next">
                      <svg viewBox="0 0 24 24">
                        <g fillRule="evenodd">
                          <path d="M7.134 23.134l-1.06-1.06a.25.25 0 010-.355L16.19 11.603 6.074 1.488a.25.25 0 010-.355l1.06-1.06a.25.25 0 01.354 0L18.84 11.427a.25.25 0 010 .353L7.488 23.134a.25.25 0 01-.354 0" />
                        </g>
                      </svg>
                    </svg>
                  </div>
                  <div className="Pill Pill--default padding-vert-smallest padding-horiz-smaller font-size-smaller font-weight-bold font-color-white media-index no-opacity">
                    1 / 26
                  </div>
                  <div className="bottomright">
                    <div className="mlsLogoExtension" />
                  </div>
                </div>
                <div className="bottomV2 ">
                  <span
                    className="homecardV2Price"
                    data-rf-test-name="homecard-price"
                  >
                    {data.price}
                  </span>
                  <div className="favoriteButtonV2 homecard-clickable">
                    <div
                      className="FavoriteExtension favoriteButtonV2 clickable fav-off"
                      data-rf-test-id="favoriteExtension"
                      role="checkbox"
                      aria-label="favorite button"
                      aria-checked="false"
                    >
                      <svg className="SvgIcon favorite-actionable clickable favorite-icon">
                        <svg viewBox="0 0 26 23">
                          <path
                            className="favorite-actionable-fill fill"
                            d="M18.38 2c-1.753 0-3.712.756-5.368 2.766C11.356 2.756 9.397 2 7.644 2c-1.286 0-2.46.407-3.322 1.026C2.287 4.487 1.337 7.56 2.508 10.48c1.517 3.783 9.212 9.6 10.36 10.452a.233.233 0 00.287 0c1.158-.856 8.95-6.72 10.36-10.452 1.113-2.943.221-5.993-1.813-7.454C20.84 2.407 19.665 2 18.38 2z"
                          />
                          <path
                            className="favorite-actionable-outline outline"
                            d="M13.012 4.766l-.771.636.771.937.772-.937zm-8.69-1.74l.584.813zM2.508 10.48l-.928.373zm10.36 10.452l-.596.803zm.287 0l-.594-.804-.006.004zm10.36-10.452l-.935-.353zm-1.813-7.454l.584-.812zM18.38 1c-2.061 0-4.3.898-6.138 3.13l1.543 1.272C15.257 3.615 16.937 3 18.38 3zm-4.595 3.13C11.945 1.899 9.707 1 7.644 1v2c1.444 0 3.124.615 4.597 2.402zM7.644 1c-1.49 0-2.87.47-3.905 1.215l1.167 1.623C5.593 3.344 6.563 3 7.644 3zM3.74 2.214C1.294 3.97.255 7.547 1.58 10.853l1.857-.745C2.42 7.572 3.28 5.006 4.906 3.838zM1.58 10.853c.443 1.104 1.298 2.278 2.273 3.394.988 1.132 2.16 2.27 3.304 3.301a75.12 75.12 0 005.115 4.187l1.193-1.605a73.097 73.097 0 01-4.969-4.068c-1.112-1.002-2.22-2.08-3.137-3.13-.93-1.065-1.607-2.036-1.922-2.824zm10.693 10.883c.44.327 1.037.33 1.482-.004l-1.2-1.6a.763.763 0 01.909-.003zm1.477 0a74.823 74.823 0 005.159-4.207c1.151-1.035 2.326-2.175 3.31-3.305.968-1.113 1.815-2.287 2.232-3.39l-1.871-.707c-.289.763-.945 1.721-1.87 2.784-.91 1.046-2.02 2.125-3.138 3.13a72.868 72.868 0 01-5.011 4.087zm10.7-10.902c1.249-3.3.292-6.855-2.164-8.62L21.119 3.84c1.612 1.157 2.439 3.702 1.461 6.288zm-2.164-8.62C21.25 1.471 19.87 1 18.379 1v2c1.081 0 2.051.344 2.74.839z"
                          />
                        </svg>
                      </svg>
                    </div>
                  </div>
                  <div className="HomeCardShareButton homecard-clickable">
                    <svg className="SvgIcon share-forward-icon clickable share-icon">
                      <svg viewBox="0 0 24 24">
                        <path
                          opacity=".5"
                          className="share-forward-icon-fill fill"
                          d="M11.986 15.982c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.755A.753.753 0 010 20.214c.144-3.693 1.336-6.637 3.549-8.76C6.412 8.71 10.18 8.127 11.986 8.01V3.452a.25.25 0 01.403-.197l10.971 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.405-.197v-4.538z"
                        />
                        <path
                          className="share-forward-icon-outline outline"
                          d="M12.382 13.99c.374 0 .609.01.66.011l.958.04v2.914L20.37 12 14 7.044v2.994L12.96 10c-.044 0-4.833-.146-8.024 2.925-.921.886-1.627 1.962-2.116 3.216 3.072-1.959 7.82-2.15 9.562-2.15M12 15.996c-2.023.035-6.72.353-8.908 2.454-.582.558-.927 1.197-1.045 1.94a.736.736 0 01-.731.61H.769a.753.753 0 01-.754-.772c.144-3.693 1.336-6.637 3.549-8.759C6.426 8.723 10.196 8.14 12 8.024V3.466a.25.25 0 01.404-.197l10.97 8.533a.25.25 0 010 .395l-10.97 8.534a.25.25 0 01-.404-.197v-4.538z"
                        />
                      </svg>
                    </svg>
                  </div>
                  <div className="HomeStatsV2 font-size-small ">
                    <div className="stats">{data.beds} Bed</div>
                    <div className="stats">{data.baths} Baths</div>
                    <div className="stats">{data.size} Sq. Ft.</div>
                  </div>
                  <div className="homeAddressV2">
                    <div
                      className="addressDisplay font-size-smaller"
                      data-rf-test-id="abp-homeinfo-homeaddress"
                    >
                      <span
                        className="collapsedAddress primaryLine"
                        data-rf-test-id="abp-streetLine"
                      >
                        {data.address1}, {data.address2}
                      </span>
                    </div>
                  </div>
                  <div className="brokerageDisclaimerV2">
                    <div className="disclaimerV2" title="Listing by R New York">
                      Listing by {data["listedby"]}
                    </div>
                  </div>
                  <Link
                    to="#"
                    title="330 E 38th St Unit 24-A, New York, NY 10016"
                  >
                    <div className="link-and-anchor">
                      330 E 38th St Unit 24-A, New York, NY 10016
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Details5;
