import './App.css';


import {
 
  Routes,
  Route,
} from "react-router-dom";
import Home from './Pages/Home';
import Agent from './Pages/Agent';
import Details from './Pages/Details';
import Details2 from './Pages/Details2';
import Premier from './Pages/Premier';
import Map from './Pages/Map';
import Buy from './Pages/Buy';
import Buy1 from './Pages/Buy1';
import Buy2 from './Pages/Buy2';
import Buy3 from './Pages/Buy3';
import Buy4 from './Pages/Buy4';
import Buy5 from './Pages/Buy5';
import Selling1 from './Pages/Selling1';
import Selling2 from './Pages/Selling2';
import Selling3 from './Pages/Selling3';
import Invest from './Pages/Invest';
import Partner from './Pages/Partner';
import Careers from './Pages/Careers';
import Help from './Pages/Help';
import Contact from './Pages/Contact';
import Rates from './Pages/Rates';
import Lands from './Pages/Lands';
import Rent from './Pages/Rent';
import Navbar2 from './Pages/Navbar2';
import Projects from './Pages/Projects';





function App() {
  return (
<Routes> 
    <Route path="/" element={<Home/>} />
   
    <Route path="/real-estate-agent" element={<Agent/>} />  

    <Route path="/premier" element={<Premier/>} /> 
    <Route path="/redcoral" element={<Agent/>} />
    <Route path="/buy" element={<Buy/>} />
    <Route path="/land" element={<Lands/>} />
    <Route path="/buy/:name" element={<Buy1/>} />
    <Route path="/openbook" element={<Buy3/>} />
    <Route path="/classes-and-event" element={<Buy4/>} />
    <Route path="/us-housing-market" element={<Buy5/>} />  

    <Route path="/selling-options" element={<Selling1/>} />
    <Route path="/redcoral-full-service" element={<Selling2/>} />
    <Route path="/what-is-my-home-worth" element={<Selling3/>} />
  
    <Route path="/invest" element={<Invest/>} />

    <Route path="/project" element={<Projects/>} />
    <Route path="/contact" element={<Contact/>} />
    <Route path="/partner" element={<Partner/>} />
    <Route path="/careers" element={<Careers/>} />
    <Route path="/compare-rates" element={<Rates/>} />
    <Route path="/rent" element={<Buy/>} />
    <Route path="/condos" element={<Buy/>} />
    <Route path="/land" element={<Lands/>} />
   

    <Route path="details/:id" element={<Details2/>} />

  </Routes>
  );
}

export default App;







