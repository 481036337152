import React from "react";
import Navbar2 from "./Navbar2";
import { Grid } from "@mui/material";
import { red } from "@mui/material/colors";

function Projects() {
  return (
    <div>
      <Navbar2 />
      <div className="project" style={{ marginTop: "6rem" }}>
        <div className="Big-Screen-main-Q-1">
          <section className="overView-container-Q-1">
            <div className="overView-image-container-Q-1">
              <h1 className="overview-Q-1" style={{ fontWeight: "500" }}>
                OVERVIEW
              </h1>
              <img
                src="overview.jpeg"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="overview-text-container-Q-1">
              <p className="overview-decription-text-Q-1">
                Red Coral Homes, is seeking $8 million of equity/debt to
                complete phase I development of Dubai Marina Resort, a luxury
                resort development located on Dubai Marina, P.O.Box 163229,
                Dubai, United Arab Emirates. Expansion phases (II, III) totaling
                $52 million additional equity/debt.
              </p>
              <ul className="list-Q-1">
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Development includes 100 luxury condominium units with water
                    views for sale &amp; luxury brand name hotel with 215 rooms.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Resort will have a 500-person conference center, hotel
                    helipad, marina, boat yard and 4-story golf cart parking.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Situated in the heart of Dubai, Dubai Marina Resort enjoys a
                    prime location that offers easy access to major
                    transportation hubs, business centers, renowned educational
                    institutions, shopping districts, and recreational
                    facilities. Its central position ensures convenience and
                    connectivity, making it an ideal choice for individuals and
                    families seeking a vibrant urban lifestyle.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Dubai overreliance on tourism has led to the government
                    partnering with the private sector for marketing
                    initiatives.
                  </span>
                </li>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Demand for branded hotel rooms has been increasing by an
                    average annual compounded rate of 6.8% (while room supply is
                    only increasing by 3.6% per year).
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    42% of tourists enjoy visiting the Dubai Marina.
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </div>

        <div className="main-q-Q-1">
          <section className="overView-container-Q-1">
            <div className="overview-text-container-Q-1">
              <ul className="list">
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Property has all related infrastructure including roads,
                    lighting, and underground utilities, as well as 24-hour
                    security at the entrance to the resort.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    No competitors on the southern side of the island.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Direct incoming and outgoing flights and water taxis.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase I - completion of 8 building, 126-unit, condominium
                    complex.
                  </span>
                </li>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase II – Fifth branded hotel in Dubai Marina.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase III - Additional 6 buildings with 72 units.
                  </span>
                </li>
                <br />
              </ul>
              <section className="phases-Q-1">
                <div style={{ width: "12vw" }}>
                  <p>Phase I</p>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}>
                      {" "}
                      Land acquisition (completed)
                    </span>
                  </li>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}>
                      {" "}
                      Residential buildings (in progress)
                    </span>
                  </li>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}> Marina</span>
                  </li>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}> Roads</span>
                  </li>
                </div>
                <div style={{ width: "12vw" }}>
                  <p>Phase II</p>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}> Land acquisition</span>
                  </li>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}> Hotel and amenities</span>
                  </li>
                </div>
                <div style={{ width: "12vw" }}>
                  <p>Phase III</p>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}> Land acquisition</span>
                  </li>
                  <li className="list-style-color-Q-1">
                    <span style={{ color: "black" }}>
                      {" "}
                      Residential buildings
                    </span>
                  </li>
                </div>
              </section>
            </div>
            <div className="overView-image-container-Q-1">
              <h1 className="overview-Q-1" style={{ fontWeight: "500" }}>
                STRATEGY
              </h1>
              <img
                src="strategy.jpeg"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
          </section>
        </div>

        <div className="Big-Screen-main-Q-2">
          <section className="overView-container-Q-1">
            <div className="overView-image-container-Q-1">
              <h1 className="overview-Q-1" style={{ fontWeight: "500" }}>
                STRATEGY
              </h1>
              <img
                src="strategy.jpeg"
                style={{ width: "100%", height: "100%" }}
              />
            </div>
            <div className="overview-text-container-Q-1">
              <ul className="list-Q-1">
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Property has all related infrastructure including roads,
                    lighting, and underground utilities, as well as 24-hour
                    security at the entrance to the resort.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    No competitors on the southern side of the island.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Direct incoming and outgoing flights and water taxis.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase I - completion of 8 building, 126-unit, condominium
                    complex.
                  </span>
                </li>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase II – Fifth branded hotel in Dubai Marina.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Phase III - Additional 6 buildings with 72 units.
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </div>

        <div className="Big-Screen-main-Q-1">
          <section className="overView-container-Q-1">
            <div className="overview-text-container-Q-1">
              <h1
                className="overview-Q-1"
                style={{ fontWeight: "500", marginBottom: "1rem" }}
              >
                Specific &amp; General Risks
              </h1>
              <p>
                While Dubai is generally considered a safe and secure
                destination, it's important to be aware of a few specific risks
                that visitors should keep in mind.
              </p>
              <ul className="list-Q-1">
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Respect for Laws and Regulations: Dubai has strict laws and
                    regulations, and visitors are expected to adhere to them.
                    This includes respecting alcohol consumption laws, not
                    engaging in illegal activities, and respecting local customs
                    and traditions.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Cultural Diversity: Dubai is a multicultural city with
                    people from various nationalities and backgrounds. While
                    this diversity is celebrated, it's important to be
                    respectful and tolerant of different cultures, religions,
                    and beliefs.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Cultural Sensitivities: Dubai is an Islamic country with
                    conservative cultural norms and traditions. Visitors should
                    respect local customs, dress modestly in public areas, and
                    avoid public displays of affection. It's also important to
                    be aware of and adhere to Ramadan rules and etiquette during
                    the holy month.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Extreme Weather: Dubai experiences extremely high
                    temperatures, especially during the summer months, which can
                    pose health risks such as heat exhaustion or heatstroke.
                    It's important to stay hydrated, wear appropriate clothing,
                    and limit outdoor activities during the hottest parts of the
                    day.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Road Safety: Dubai has a fast-paced and sometimes aggressive
                    driving culture. Visitors should exercise caution when
                    crossing roads, use designated pedestrian crossings, and
                    follow traffic rules. It's advisable to use licensed taxis
                    or ride-hailing services for transportation.
                  </span>
                </li>
                <p>Some General Risks</p>
                <p>
                  <span className="overview-second-text-color-Q-1">
                    Risks related to market conditions and governmental
                    programs.
                  </span>
                </p>
                <p></p>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    We may be adversely impacted by weakness in the local
                    economies we serve.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    New legislation and/or regulatory laws affecting our
                    operations may affect our performance. Risks related to our
                    business/operations
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    We may be unable to successfully execute and manage our
                    growth strategy.
                  </span>
                </li>
              </ul>
            </div>
            <div className="overview-text-container-Q-1">
              <ul className="list-Q-1">
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Our success will depend on our ability to hire, train and
                    retain key personnel.
                  </span>
                </li>
                <p>
                  {" "}
                  <span className="overview-second-text-color-Q-1">
                    Risks related to our industry
                  </span>
                </p>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    The industries in which we operate are highly competitive
                    and many of our competitors have access to greater financial
                    resources, lower funding costs and greater access to
                    liquidity.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Unfavorable future conditions could adversely impact our
                    business, financial position, results of operations and/or
                    cash flows. Risks related to funding sources and interest
                    rates/debt markets
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    We are substantially dependent upon our secured and
                    unsecured funding arrangements. If any of our funding
                    arrangements are terminated, not renewed or otherwise become
                    unavailable to us, we may be unable to find replacement
                    financing on economically viable terms, if at all, which
                    would have a material adverse effect on our business,
                    financial position, results of operations and cash flows.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Changes in economic and legal conditions could materially
                    and adversely affect our transactions, business, financial
                    position, results of operations or cash flows.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    Our earnings and reputation may be adversely affected if
                    risk is not properly managed.
                  </span>
                </li>
                <p>
                  <span className="overview-second-text-color-Q-1">
                    {" "}
                    Risks related to proposed financing transaction
                  </span>
                </p>
                <li>
                  <span className="overview-second-text-color-Q-1">
                    There is no assurance that we will be able to obtain
                    additional capital as needed.
                  </span>
                </li>
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    There can be no assurance that any financial projections
                    delivered to a prospective investor will accurately reflect
                    the predicted profitability of our business
                  </span>
                </li>{" "}
                <br />
                <li>
                  <span className="overview-second-text-color-Q-1">
                    There is currently no market for our securities; and it is
                    unlikely that a market will develop in the future, which
                    would impact your ability to resell our securities in an
                    orderly fashion.
                  </span>
                </li>
              </ul>
            </div>
          </section>
        </div>

        <div className="redcoral-standout-main-Q-1">
          <p
            className="redcoral-standout-Q-1"
            style={{ fontWeight: "500", marginBottom: "1rem" }}
          >
            WHAT MAKES DUBAI MARINA STANDOUT
          </p>
          <div className="standout-main-Q-1">
            <Grid container sx={{ width: "80vw" }}>
              <Grid item xs={12} sm={4}>
                <div className="standout-div-container-Q-1">
                  <h4 className="architectural-Q-1">
                    Architectural Brilliance
                  </h4>
                  <p className="architectural-descripsion-Q-1">
                    Designed by renowned architects, Dubai Marina Resort
                    showcases architectural brilliance that seamlessly blends
                    with the surrounding landscape. The project features a
                    striking facade, elegant lines, and contemporary finishes,
                    reflecting the epitome of modern sophistication. The
                    attention to detail and commitment to quality are evident in
                    every aspect of the design.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="standout-div-container-Q-1">
                  <h4 className="architectural-Q-1">Residences</h4>
                  <p className="architectural-descripsion-Q-1">
                    Designed by renowned The residences at Dubai Marina Resort
                    are designed to cater to the diverse needs and preferences
                    of homeowners. Offering a range of unit sizes, from spacious
                    apartments to luxurious penthouses, each home provides a
                    haven of comfort and tranquility. The thoughtfully designed
                    layouts maximize natural light and optimize space
                    utilization, ensuring a harmonious living environment.
                    High-end fixtures, premium materials, and state-of-the-art
                    technology come together to create homes that exude elegance
                    and functionality.
                  </p>
                </div>
              </Grid>

              <Grid item xs={12} sm={4}>
                <div className="standout-div-container-Q-1">
                  <h4 className="architectural-Q-1">Amenities</h4>
                  <p className="architectural-descripsion-Q-1">
                    At Dubai Marina Resort residents will have access to an
                    impressive array of amenities that cater to their every need
                    and desire. From a state-of-the-art fitness center and
                    swimming pool to landscaped gardens, jogging tracks, and
                    dedicated children's play areas, the development has been
                    meticulously designed to promote an active and healthy
                    lifestyle. Additionally, residents can indulge in the
                    luxurious spa, unwind in the sauna and steam rooms, or
                    socialize in the stylish community lounges and entertainment
                    areas.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="standout-div-container-Q-1">
                  <h4 className="architectural-Q-1">
                    Sustainability and Green Spaces
                  </h4>
                  <p className="architectural-descripsion-Q-1">
                    Designed by renowned We believe in creating sustainable
                    communities that prioritize environmental responsibility.
                    Dubai Marina Resort incorporates Eco-friendly features and
                    energy-efficient technologies to reduce its ecological
                    footprint. The development boasts lush green spaces,
                    beautifully landscaped gardens, and dedicated areas for
                    recreational activities, providing a serene and serene
                    ambiance for residents to enjoy.
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="standout-div-container-Q-1">
                  <h4 className="architectural-Q-1">Investment Opportunity</h4>
                  <p className="architectural-descripsion-Q-1">
                    Dubai Marina Resort presents an exceptional investment
                    opportunity for those seeking long-term value and potential
                    returns. With its prime location, luxurious offerings, and
                    growing demand for quality housing in the area, the project
                    is poised for substantial appreciation. Whether you are a
                    homeowner or an investor, this development holds the promise
                    of an exceptional investment that combines lifestyle
                    enhancement with financial growth.
                  </p>
                </div>
              </Grid>

              <section style={{ paddingLeft: "2rem" }}></section>
            </Grid>
          </div>
        </div>
        <p
          style={{
            fontSize: "1.5rem",
            textAlign: "center",
            marginTop: "6rem",
            fontWeight: "500",
            marginBottom: "1rem",
          }}
        >
          {" "}
          Sources and Uses of Funds
        </p>
        <div className="money-detail-Q-1">
          <div className="money-detail-flex-container-Q-1">
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Sources</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $5M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Current Dept Revolving line of Credit/construction
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Sources</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $55M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Loan/equity
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Uses</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $4M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Resort infrastructure
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Uses</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $15M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Phase I Construction
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Uses</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $30M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Phase II Construction
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Uses</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                {" "}
                $10M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Phase III Construction
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }}>Uses</p>
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $1M
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                Closing/other costs
              </p>
            </div>
            <div className="money-detail-container-Q-1">
              <p style={{ padding: "1px 0px 1px 7px" }} />
              <p
                style={{
                  fontSize: "1.5rem",
                  margin: "3px 0px",
                  textAlign: "center",
                }}
              >
                $--
              </p>
              <p style={{ textAlign: "center", marginTop: "1rem" }}>
                More funding to be disclosed
              </p>
            </div>
          </div>
        </div>
        <div>
          <p
            className="Management-Q-1"
            style={{ fontWeight: "500", marginBottom: "1rem" }}
          >
            MANAGEMENT
          </p>
          <section className="grid-management-box-Q-1">
            <div className="Management-profile-flex-Q-1">
              <img src="Zahavi.jpeg" className="manange-personnel-images-Q-1" />
              <div className="Management-position-Q-1">
                <p className="personel-name-Q-1">
                  Zahavi Egle – Director and Co-owner
                </p>
                <ul className="personel-qualities-Q-1">
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Responsible for sales, marketing, and overall development.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      Over a decade of experience developing on Dubai Marina.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Sales/marketing experience with Major League Soccer and
                      Delta Demolition.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Management-profile-flex-Q-1">
              <img src="rami.jpeg" className="manange-personnel-images-Q-1" />
              <div className="Management-position-Q-1">
                <p className="personel-name-Q-1">
                  Ramy Marmor – Director and Co-owner
                </p>
                <ul className="personel-qualities-Q-1">
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Responsible for financial oversight.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      Co-developer of 3 completed projects in Dubai.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      Over 10 years of experience developing in Dubai.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Over 18 years of experience in Wall Street (Merrill Lynch
                      and Deutsche Bank).
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Management-profile-flex-Q-1">
              <img src="yoffe.jpeg" className="manange-personnel-images-Q-1" />
              <div className="Management-position-Q-1">
                <p className="personel-name-Q-1">
                  Mark Thompson – Director and Co-owner
                </p>
                <ul className="personel-qualities-Q-1">
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Responsible for finance, capital structuring and investor
                      relations.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Over 15 years of experience strutting debt &amp; equity
                      for construction and alternative investments.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Over 20 years owning business advisory services. Owning
                      finance corporations working with family offices
                      internationally.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Over 10 years as an expert in clean energy and
                      sustainability infrastructure, to include renewable energy
                      and PPA finance structuring.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="Management-profile-flex-Q-1">
              <img
                src="raji.jpeg"
                className="manange-personnel-images-Q-1"
                id="profile"
              />
              <div className="Management-position-Q-1">
                <p className="personel-name-Q-1">
                  Jamal Wahad – Operations Office Chief Engineer
                </p>
                <ul className="personel-qualities-Q-1">
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Responsible for finance, capital structuring and investor
                      relations.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      Responsible for the management of Dubai Marina Resort
                      projects.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      14 years of experience in the transportation industry.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      3 years of experience in the Ministry of Construction.
                    </span>
                  </li>
                  <li>
                    <span style={{ color: "black" }}>
                      {" "}
                      9 years of experience Chief Engineer.
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Projects;
