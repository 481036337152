import React from "react";
import Footer from "./Footer"
import Navbar2 from "./Navbar2"
import { Link } from "react-router-dom";
import contacts from "../Pages/Images/contacts.jpg"

function Contact() {
  return (
    <div>
      <div>
 
        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={62}
          >
         <Navbar2 />
          </div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={6}
            data-react-server-timing-offset={62}
          >
            <div
              className="TopBannerSection"
              style={{
                backgroundImage:
                  `url(${contacts})`,
              }}
            >
              <div className="banner-alignment">
                <div className="banner-contents">
                  <h1 className="title-text">
                    <span>Contact Us</span>
                  </h1>
                  <p>
                    We’re here to help! Our National Customer Service Team is
                    available 8am - 5pm PST, seven days a week.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={7}
            data-react-server-timing-offset={62}
          >
            <div className="ContactUsOptions topOfPage">
              <div className="contact-options-container">
                <div className="ValueProp value-prop header-type-configured call-us">
                  <div className="icon-div">
                    <svg className="SvgIcon call icon">
                      <svg viewBox="0 0 24 24">
                        <g fillRule="evenodd">
                          <path d="M5.741 3l2.151 3.999L6.26 9.924l.701 1.023c.076.111.803 1.144 2.88 3.252 2.087 2.056 3.12 2.783 3.231 2.859l1.023.701 2.925-1.632 3.999 2.151-2.441 2.721c-1.435-.13-4.951-.991-9.731-5.85-4.857-4.778-5.693-8.365-5.826-9.708L5.737 3h.001m0-2c-.471 0-.946.165-1.333.512L1.334 4.268A.981.981 0 001 5c-.003 1.374.517 5.744 6.444 11.575 5.212 5.175 9.842 6.069 11.498 6.348a.996.996 0 00.913-.323l2.652-2.986a2 2 0 00-.541-3.097l-4.481-2.41a1 1 0 00-.961.007l-2.321 1.295s-.937-.643-2.958-2.635c-1.991-2.021-2.634-2.958-2.634-2.958l1.294-2.321a1 1 0 00.008-.961l-2.41-4.481A1.995 1.995 0 005.738 1" />
                        </g>
                      </svg>
                    </svg>
                  </div>
                  <div className="prop-copy">
                    <Link to="#" target="_self">
                      <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                        <span>(844) 759-7732</span>
                      </h3>
                    </Link>
                    <p className="value-copy">
                      Talk to a Customer Service Representative for help with
                      our site, app, or finding a Red Coral Real Estates Agent.
                    </p>
                  </div>
                </div>
                <div className="ValueProp value-prop header-type-configured email-us">
                  <div className="icon-div">
                    <svg className="SvgIcon email icon">
                      <svg viewBox="0 0 24 24">
                        <g fillRule="evenodd">
                          <path d="M23.75 3H.25a.25.25 0 00-.25.25v17.5c0 .138.112.25.25.25h23.5a.25.25 0 00.25-.25V3.25a.25.25 0 00-.25-.25zM7.762 11.824L2 17.586V7.11l5.762 4.714zM2.579 5h18.842L12 12.708 2.579 5zm6.738 8.097l2.525 2.066a.25.25 0 00.316 0l2.525-2.066L20.586 19H3.414l5.903-5.903zm6.921-1.273L22 7.11v10.476l-5.762-5.762z" />
                        </g>
                      </svg>
                    </svg>
                  </div>
                  <div className="prop-copy">
                    <Link
                      to="#"
                      target="_self"
                    >
                      <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                        <span>Message us</span>
                      </h3>
                    </Link>
                    <p className="value-copy">
                      Send our Customer Service Team questions about our site,
                      app, or finding a Red Coral Real Estates Agent.
                    </p>
                  </div>
                </div>
                <div className="ValueProp value-prop header-type-configured connect-with-us">
                  <div className="icon-div">
                    <svg className="SvgIcon connect icon">
                      <svg viewBox="0 0 78 79">
                        <g fillRule="evenodd">
                          <path d="M12.997 35.416L58.91 11.004l3.756 7.063L16.753 42.48z" />
                          <path d="M19.005 36.998l45.033 26-4 6.928-45.033-26z" />
                          <path d="M15.857 52.607C8.757 52.607 3 46.851 3 39.75c0-7.1 5.756-12.857 12.857-12.857 7.1 0 12.857 5.756 12.857 12.857 0 7.1-5.756 12.857-12.857 12.857zM61.18 75.43c-7.101 0-12.858-5.757-12.858-12.858 0-7.1 5.757-12.857 12.858-12.857 7.1 0 12.857 5.757 12.857 12.857 0 7.101-5.757 12.858-12.857 12.858zm-.322-46.715c-7.1 0-12.857-5.756-12.857-12.857C48 8.757 53.756 3 60.857 3c7.1 0 12.857 5.756 12.857 12.857 0 7.1-5.756 12.857-12.857 12.857z" />
                        </g>
                      </svg>
                    </svg>
                  </div>
                  <div className="prop-copy">
                    <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                      <span>Connect with us</span>
                    </h3>
                    <p className="value-copy">
                      <Link to="https://www.facebook.com/Red Coral Real  Estate">
                        <svg className="SvgIcon facebook">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M21.252 1.6H2.747c-.633 0-1.147.514-1.147 1.148v18.504c0 .634.514 1.148 1.147 1.148h9.963v-8.055H9.999v-3.139h2.71V8.891c0-2.686 1.641-4.15 4.038-4.15 1.148 0 2.134.086 2.422.125v2.807h-1.662c-1.304 0-1.556.62-1.556 1.529v2.004h3.109l-.405 3.14h-2.704V22.4h5.3c.635 0 1.149-.514 1.149-1.148V2.748c0-.634-.514-1.148-1.148-1.148"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                      <Link to="https://twitter.com/Red Coral Real Estates">
                        <svg className="SvgIcon twitter">
                          <svg viewBox="0 0 25 24">
                            <path
                              d="M21.863 6.828c.015.212.015.424.015.636 0 6.464-4.92 13.913-13.913 13.913-2.77 0-5.343-.802-7.508-2.195.393.045.772.061 1.18.061a9.78 9.78 0 006.07-2.09 4.896 4.896 0 01-4.571-3.391c.302.046.605.076.923.076.439 0 .878-.061 1.287-.167a4.89 4.89 0 01-3.92-4.799v-.061c.65.364 1.408.59 2.209.621a4.888 4.888 0 01-2.178-4.071c0-.909.24-1.741.664-2.468a13.904 13.904 0 0010.084 5.116 5.426 5.426 0 01-.121-1.12A4.886 4.886 0 0116.972 2c1.408 0 2.68.59 3.573 1.544a9.593 9.593 0 003.103-1.181 4.868 4.868 0 01-2.15 2.695 9.789 9.789 0 002.816-.757 10.522 10.522 0 01-2.451 2.527z"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                      <Link to="https://www.instagram.com/Red Coral Real  Estaterealestate/">
                        <svg className="SvgIcon instagram">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M12 1c-2.987 0-3.362.013-4.535.066-1.171.054-1.971.24-2.67.511a5.412 5.412 0 00-1.949 1.269 5.412 5.412 0 00-1.269 1.949c-.271.699-.457 1.499-.511 2.67C1.013 8.638 1 9.013 1 12s.013 3.362.066 4.535c.054 1.171.24 1.971.511 2.67a5.412 5.412 0 001.269 1.949 5.412 5.412 0 001.949 1.269c.699.271 1.499.457 2.67.511C8.638 22.987 9.013 23 12 23s3.362-.013 4.535-.066c1.171-.054 1.971-.24 2.67-.511a5.412 5.412 0 001.949-1.269 5.412 5.412 0 001.269-1.949c.271-.699.457-1.499.511-2.67.053-1.173.066-1.548.066-4.535s-.013-3.362-.066-4.535c-.054-1.171-.24-1.971-.511-2.67a5.412 5.412 0 00-1.269-1.949 5.412 5.412 0 00-1.949-1.269c-.699-.271-1.499-.457-2.67-.511C15.362 1.013 14.987 1 12 1m0 1.982c2.937 0 3.285.011 4.445.064 1.072.049 1.655.228 2.042.379.514.199.88.438 1.265.823.385.385.624.751.823 1.265.151.387.33.97.379 2.042.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.285-.064 4.445-.049 1.072-.228 1.655-.379 2.042-.199.514-.438.88-.823 1.265a3.398 3.398 0 01-1.265.823c-.387.151-.97.33-2.042.379-1.16.053-1.508.064-4.445.064-2.937 0-3.285-.011-4.445-.064-1.072-.049-1.655-.228-2.042-.379a3.398 3.398 0 01-1.265-.823 3.398 3.398 0 01-.823-1.265c-.151-.387-.33-.97-.379-2.042-.053-1.16-.064-1.508-.064-4.445 0-2.937.011-3.285.064-4.445.049-1.072.228-1.655.379-2.042.199-.514.438-.88.823-1.265a3.398 3.398 0 011.265-.823c.387-.151.97-.33 2.042-.379 1.16-.053 1.508-.064 4.445-.064zm0 3.37a5.649 5.649 0 100 11.297 5.649 5.649 0 000-11.297zm0 9.315a3.667 3.667 0 110-7.335 3.667 3.667 0 010 7.335zm7.192-9.539a1.32 1.32 0 11-2.64 0 1.32 1.32 0 012.64 0z"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                      <Link to="https://www.pinterest.com/Red Coral Real  Estate/">
                        <svg className="SvgIcon pinterest">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M11.865 22.13c-1.015 0-1.978-.148-2.912-.429.387-.613.828-1.402 1.042-2.19 0 0 .118-.456.72-2.82.347.68 1.389 1.28 2.5 1.28 3.3 0 5.545-3.005 5.545-7.042 0-3.033-2.577-5.88-6.508-5.88-4.865 0-7.324 3.502-7.324 6.414 0 1.764.668 3.343 2.099 3.932.229.093.44 0 .51-.269.051-.174.157-.628.211-.815.067-.268.04-.347-.147-.574-.414-.495-.682-1.123-.682-2.02 0-2.592 1.94-4.917 5.054-4.917 2.752 0 4.276 1.683 4.276 3.944 0 2.951-1.31 5.451-3.261 5.451-1.071 0-1.872-.882-1.618-1.978.308-1.296.91-2.7.91-3.636 0-.84-.457-1.55-1.39-1.55-1.097 0-1.979 1.137-1.979 2.661 0 0 0 .976.335 1.629-1.125 4.759-1.325 5.589-1.325 5.589-.187.774-.2 1.643-.173 2.365a10.267 10.267 0 01-6.148-9.41C1.6 6.197 6.199 1.6 11.865 1.6S22.13 6.197 22.13 11.865 17.53 22.13 11.865 22.13"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                      <Link to="https://plus.google.com/+Red Coral Real  Estate/posts">
                        <svg className="SvgIcon google">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M2.916 16.756c-1.052-2.092-1.371-4.549-.912-6.85.185-.924.487-1.825.907-2.669C4.116 4.791 6.275 2.863 8.808 1.98a10.372 10.372 0 017.015.063 10.091 10.091 0 013.354 2.115c-.324.36-.677.695-1.013 1.044l-1.915 1.95a5.652 5.652 0 00-2.245-1.347 6.046 6.046 0 00-3.058-.125 6.198 6.198 0 00-3.113 1.762 6.6 6.6 0 00-1.508 2.49l.001.002a6.505 6.505 0 000 4.127l-.01.008A6.58 6.58 0 008.7 17.323a6.17 6.17 0 002.318 1.02c.817.172 1.663.15 2.486.006a5.91 5.91 0 002.295-.923l.003.002a5.008 5.008 0 002.08-3.277h-5.677c.006-1.378.006-2.758.006-4.138h9.798c.325 1.864.252 3.808-.263 5.633-.476 1.67-1.378 3.221-2.637 4.4l-.003-.001c-1.192 1.122-2.686 1.885-4.264 2.255-1.747.405-3.594.417-5.33-.057A10.234 10.234 0 015.726 20.3a10.684 10.684 0 01-2.821-3.536l.01-.008z"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                      <Link to="https://www.linkedin.com/company/Red Coral Real  Estate">
                        <svg className="SvgIcon linkedin">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M19 0H5a5 5 0 00-5 5v14a5 5 0 005 5h14a5 5 0 005-5V5a5 5 0 00-5-5zM8 19H5V8h3v11zM6.5 6.732c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zM20 19h-3v-5.604c0-3.368-4-3.113-4 0V19h-3V8h3v1.765c1.396-2.586 7-2.777 7 2.476V19z"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={8}
            data-react-server-timing-offset={62}
          >
       
          </div>
          <div
            data-react-server-root-id={9}
            data-react-server-timing-offset={62}
          >
       
          </div>
          <div
            data-react-server-root-id={10}
            data-react-server-timing-offset={62}
          >
          
          </div>
          <div
            data-react-server-root-id={11}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={12}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={13}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={14}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={15}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={16}
            data-react-server-timing-offset={62}
          />
          <div
            data-react-server-root-id={17}
            data-react-server-timing-offset={62}
          />
          <div data-react-server-container={18}>
            <div
              data-react-server-root-id={19}
              data-react-server-timing-offset={62}
            />
            <div
              data-react-server-root-id={20}
              data-react-server-timing-offset={62}
            />
            <div
              data-react-server-root-id={21}
              data-react-server-timing-offset={62}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={23}
            data-react-server-timing-offset={62}
          >
        <Footer />
          </div>
          <div
            data-react-server-root-id={24}
            data-react-server-timing-offset={62}
          >
            <noscript />
          </div>
        </div>
        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div className="ot-pc-logo" role="img" aria-label="Red Coral Real Estates-logo">
                  <img
                    alt="Red Coral Real Estates-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Red Coral Real  Estate-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>
     
              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
         
          </div>
        </div>
 
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2014%3A15%3A36%20GMT&n=-1&b=Contact%20Us%20%7C%20Redfin&.yp=10184908&f=https%3A%2F%2Fwww.redfin.com%2Fabout%2Fcontact-us&e=https%3A%2F%2Fwww.redfin.com%2Fcareers&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />
    
        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
