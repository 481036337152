import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar2 from "./Navbar2";
import partner1 from "./Images/partner1.jpg"
import partner2 from "./Images/partner2.jpg"
import partner3 from "./Images/partner3.jpg"
import partner4 from "./Images/partner4.jpg"
import partner5 from "./Images/partner5.jpg"

function Partner() {
  return (
    <div>
      <div>
        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={78}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={91}
          >
            <Navbar2 />
          </div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={94}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={94}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={95}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={95}
          />
          <div
            data-react-server-root-id={6}
            data-react-server-timing-offset={97}
          >
            <section className="SplitScreenHeroWidget bg-gray ie-min-height-fix">
              <div>
                <div
                  className="image-container"
                  role="img"
                  aria-label="A modern home at sunset with a Red Coral Real Estates listing sign"
                  style={{
                    backgroundImage:
             `url(${partner1})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                >
                  <img
                    src="https://ssl.cdn-redfin.com/v461.2.4/images/merch/splitScreenHeroImages/agents_and_clients_no_yard_sign.jpg"
                    alt=""
                    style={{ display: "none" }}
                  />
                </div>
                <div className="text-cta-container bg-gray">
                  <div className="text-cta-container-row text-cta-container-gutter header-type-configured">
                    <h1 className="heading-larger margin-vert-medium copy-header  text-and-disclaimer-flyout">
                      <span>
                        Partner with Red Coral Real Estates, get high-quality
                        referrals
                      </span>
                    </h1>
                    <p className="margin-vert-medium copy font-size-base font-weight-roman font-line-height-spacious text-and-disclaimer-flyout">
                      <span>
                        There’s no upfront cost or obligation to join—just pay a
                        referral fee once you close a transaction.
                      </span>
                    </p>
                    <div className="cta">
                      <a
                        href="https://accounts.redcoralhomes.com/login.php"
                        className="button Button primary"
                        tabIndex={0}
                        role="button"
                      >
                        <span>Apply Now</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={7}
            data-react-server-timing-offset={99}
          >
            <section
              className="ThreeUpWidget bg-white padding-bottom-compact padding-top-compact"
              data-rf-test-id
            >
              <div className="section-content">
                <div className="row-center">
                  <div className="title-container header-type-configured col-12" />
                  <div className="three-up-squares col-12 col-lg-12">
                    <div className="ValueProp value-prop header-type-configured ">
                      <div className="prop-copy">
                        <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                          <span>Stay at your brokerage</span>
                        </h3>
                        <p className="margin-vert-medium value-copy text-and-disclaimer-flyout">
                          <span>
                            Keep your license at your current brokerage, and
                            we’ll send you referrals for motivated buyers and
                            sellers searching for homes in your top
                            neighborhoods.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="ValueProp value-prop header-type-configured ">
                      <div className="prop-copy">
                        <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                          <span>Get serious clients</span>
                        </h3>
                        <p className="margin-vert-medium value-copy text-and-disclaimer-flyout">
                          <span>
                            We don’t sell leads. Instead, customer reviews are
                            displayed on a Partner’s Red Coral Real Estates.com
                            profile to help buyers and sellers decide which
                            agent to choose when they’re ready.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="ValueProp value-prop header-type-configured ">
                      <div className="prop-copy">
                        <h3 className="heading-medium margin-vert-medium value-header heading-size-preset-4 text-and-disclaimer-flyout">
                          <span>Win with Red Coral Real Estates</span>
                        </h3>
                        <p className="margin-vert-medium value-copy text-and-disclaimer-flyout">
                          <span>
                            Take advantage of the number one brokerage website
                            in the nation and our first-class marketing, with no
                            upfront cost—only pay when you close a transaction.
                          </span>
                          <Link
                            className="DisclaimerFlyoutAsterisk"
                            to="/disclaimer"
                            aria-label="disclaimer"
                          >
                            <svg className="SvgIcon asterisk size-tiny">
                              <svg viewBox="0 0 14 14">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14 7A7 7 0 110 7a7 7 0 0114 0zM6.996 8.207c.038 0 .064.012.076.038l1.368 2.26a.187.187 0 00.171.096.509.509 0 00.247-.095l.798-.551c.127-.076.19-.159.19-.247 0-.038-.025-.095-.076-.171L8.174 7.599c-.038-.038-.057-.076-.057-.114a.122.122 0 01.095-.095l2.28-.684c.114-.026.171-.095.171-.21a.264.264 0 00-.018-.113l-.304-.95c-.026-.127-.09-.19-.19-.19a.292.292 0 00-.114.038l-2.28.988c-.039.012-.07.012-.096 0-.025-.013-.038-.038-.038-.076v-.038l.19-2.546a.182.182 0 00-.057-.133c-.025-.051-.076-.076-.152-.076H6.388a.205.205 0 00-.152.057c-.038.038-.057.076-.057.114v.038l.19 2.527c0 .088-.038.133-.114.133l-.076-.02-2.204-.93c-.126-.064-.221-.026-.285.114l-.36.969c-.064.19-.026.304.113.342l2.337.646c.076.012.114.05.114.114l-.038.076-1.672 2.052c-.05.05-.076.101-.076.152 0 .05.038.107.114.17l.912.647c.076.038.14.057.19.057.09 0 .152-.026.19-.076L6.92 8.245c.026-.026.051-.038.076-.038z"
                                />
                              </svg>
                            </svg>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={8}
            data-react-server-timing-offset={100}
          >
            <section className="SideBySideImageTextWidget bg-gray img-left font-color-black padding-bottom-compact padding-top-compact">
              <div className="content-row row-center">
                <div className="visual-section col-12 col-lg-6">
                  <img
                    src={partner2}
                    alt="A house with a red door"
                  />
                </div>
                <div className="action-section header-type-configured col-12 col-lg-6">
                  <div className="left-margin">
                    <div className="header padding-bottom-medium">
                      <h2 className="heading-large margin-vert-medium text-and-disclaimer-flyout">
                        <span>Broker in charge?</span>
                      </h2>
                    </div>
                    <div className="copy">
                      <div className="font-line-height-spacious text-and-disclaimer-flyout">
                        <span>
                          If you're inquiring on behalf of your brokerage, fill
                          out the form below and we'll be in touch about
                          opportunities to partner with your office.
                        </span>
                      </div>
                    </div>
                    <div className="cta primary_button">
                      <a
                       href="https://accounts.redcoralhomes.com/login.php"
                        className="button Button margin-top-larger primary"
                        tabIndex={0}
                        role="button"
                      >
                        <span>Apply as a Broker</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={9}
            data-react-server-timing-offset={100}
          >
            <div className="TextWidget bg-white padding-horiz-none padding-bottom-compact padding-top-compact">
              <div className="row-center">
                <div className>
                  <div>
                    <h2 className="heading-large margin-vert-medium text-center text-and-disclaimer-flyout">
                      <span>
                        Hear from Red Coral Real Estates Partner Agents
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={10}
            data-react-server-timing-offset={101}
          >
        
          </div>
          <div
            data-react-server-root-id={11}
            data-react-server-timing-offset={102}
          >
            <div className="TwoColumnTextWidget content bg-gray padding-bottom-compact padding-top-compact">
              <div className="row-center">
                <div className="column justify-center col-12 col-md-6">
                  <h1 className="heading-larger margin-vert-medium text-left  text-and-disclaimer-flyout">
                    <span>Agent requirements</span>
                  </h1>
                </div>
                <div className="column justify-start col-12 col-md-6">
                  <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                    <span>
                      ✓ An active real estate license in good standing
                    </span>
                  </p>
                  <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                    <span>✓ Your fair share of closed client transactions</span>
                  </p>
                  <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                    <span>✓ Proven excellence based on our client surveys</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={12}
            data-react-server-timing-offset={103}
          >
            <div className="ThreeCardsWidget">
              <section className="CardsContainer bg-white padding-bottom-compact padding-top-compact ">
                <div className="row-center">
                  <div className="title-container col-12 header-type-configured">
                    <h2 className="heading-large margin-vert-medium title-text  text-and-disclaimer-flyout">
                      <span>Better together</span>
                    </h2>
                  </div>
                  <div className="subtitle-container col-12">
                    <div className="subtitle-text text-and-disclaimer-flyout">
                      <span>
                        Real estate agents are set up for success when they
                        partner with Red Coral Real Estates.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row-center">
                  <div className="cards-container col-12">
                    <div aria-hidden="true" className="card bordered-card">
                      <div className="card-image-wrapper full-width">
                        <img
                          className="card-image full-width"
                          src={partner3}
                          alt="Person looking at a home on a tablet"
                        />
                      </div>
                      <div className="copy-container ">
                        <Link>
                          <h3 className="heading-medium margin-vert-medium title-text heading-medium card-header  text-and-disclaimer-flyout">
                            <span>A brighter spotlight</span>
                          </h3>
                        </Link>
                        <p className="margin-vert-medium card-text text-and-disclaimer-flyout">
                          <span>
                            More than one million potential buyers and sellers
                            visit our site and app every day. Red Coral Real
                            Estates.com is the largest brokerage website in the
                            nation, giving you incredible exposure to new
                            clients.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div aria-hidden="true" className="card bordered-card">
                      <div className="card-image-wrapper full-width">
                        <img
                          className="card-image full-width"
                          src={partner4}
                          alt="Man and woman preparing food in a modern kitchen"
                        />
                      </div>
                      <div className="copy-container ">
                        <Link>
                          <h3 className="heading-medium margin-vert-medium title-text heading-medium card-header  text-and-disclaimer-flyout">
                            <span>An established client base</span>
                          </h3>
                        </Link>
                        <p className="margin-vert-medium card-text text-and-disclaimer-flyout">
                          <span>
                            85,000+ customers bought and/or sold homes with Red
                            Coral Real Estates Partner Agents.
                          </span>
                        </p>
                      </div>
                    </div>
                    <div aria-hidden="true" className="card bordered-card">
                      <div className="card-image-wrapper full-width">
                        <img
                          className="card-image full-width"
                          src={partner5}
                          alt="Living room furnished with mid-century style furniture and decor"
                        />
                      </div>
                      <div className="copy-container ">
                        <Link>
                          <h3 className="heading-medium margin-vert-medium title-text heading-medium card-header  text-and-disclaimer-flyout">
                            <span>High marks for partner agents</span>
                          </h3>
                        </Link>
                        <p className="margin-vert-medium card-text text-and-disclaimer-flyout">
                          <span>
                            93% of customers who closed would recommend their
                            Red Coral Real Estates Partner Agent to a friend.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div
            data-react-server-root-id={13}
            data-react-server-timing-offset={107}
          ></div>





          <div
            data-react-server-root-id={14}
            data-react-server-timing-offset={108}
          >
            <section className="CTAButtonWidget bg-white padding-bottom-compact padding-top-compact">
              <div className="text-center row-center">
                <div className="col-12 col-md-10 col-push-md-1 col-lg-8 col-push-lg-2 header-type-configured">
                  <h2 className="heading-large margin-vert-medium table-title heading-size-preset-3 text-and-disclaimer-flyout">
                    <span>Let’s get started</span>
                  </h2>
                  <p>
                    <span>
                      Ready to learn more about partnering with us? Fill out our
                      quick application, and we'll contact you about next steps
                      and availability in your area.
                    </span>
                  </p>
                  <div>
                    <a
                        href="https://accounts.redcoralhomes.com/login.php"
                      className="button Button primary"
                      tabIndex={0}
                      role="button"
                    >
                      <span>Apply Now</span>
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={15}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={16}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={17}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={18}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={19}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={20}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={21}
            data-react-server-timing-offset={109}
          />
          <div
            data-react-server-root-id={22}
            data-react-server-timing-offset={109}
          />
          <div data-react-server-container={23}>
            <div
              data-react-server-root-id={24}
              data-react-server-timing-offset={109}
            />
            <div
              data-react-server-root-id={25}
              data-react-server-timing-offset={109}
            />
            <div
              data-react-server-root-id={26}
              data-react-server-timing-offset={109}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={28}
            data-react-server-timing-offset={109}
          >
            <Footer />
          </div>
          <div
            data-react-server-root-id={29}
            data-react-server-timing-offset={109}
          >
            <noscript />
          </div>
        </div>
        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div
                  className="ot-pc-logo"
                  role="img"
                  aria-label="Red Coral Real Estates-logo"
                >
                  <img
                    alt="Red Coral Real Estates-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Red Coral Real Estates-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            {/* <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button> */}
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>

              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
          </div>
        </div>
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2013%3A17%3A28%20GMT&n=-1&b=Partner%20with%20Redfin%2C%20get%20high-quality%20referrals&.yp=10184908&f=https%3A%2F%2Fwww.redfin.com%2Fpartner&e=https%3A%2F%2Fwww.redfin.com%2F&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />

        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partner;
