import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer";
import Navbar2 from "./Navbar2";
import career1 from "./Images/career1.jpg";
import career2 from "./Images/carear2.jpg";
import career3 from "./Images/carear3.svg";
import career4 from "./Images/career4.svg";
import career5 from "./Images/career5.svg";
import career6 from "./Images/career6.svg"; 
import career21 from "./Images/career21.jpg";    
import career22 from "./Images/career22.jpg";   

function Careers() {
  return (
    <div>
      <div>
        <iframe
          style={{
            display: "none",
            height: 0,
            opacity: 0,
            visibility: "hidden",
            border: 0,
            width: 0,
          }}
          name="__uspapiLocator"
        />

        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={68}
          >
            <Navbar2 />
          </div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={6}
            data-react-server-timing-offset={68}
          >
        
          </div>
          <div
            data-react-server-root-id={7}
            data-react-server-timing-offset={68}
          >
            <div
              className="TopBannerSection"
              style={{
                backgroundImage: `url(${career1})`,
                width: "100%",
                height: "100%",
              }}
            >
              <div className="banner-alignment">
                <div className="banner-contents">
                  x
                  <h1 className="title-text">
                    <span>Be a Part of Something Better</span>
                  </h1>
                  <p className="top-banner-subtitle-text">
                    Our mission is to redefine real estate in the customer’s
                    favor. From the technology we build to the brokerage
                    services we provide.
                  </p>
                  <a
                    href="https://accounts.redcoralhomes.com/register.php"
                    className="button Button v3 primary"
                    data-name="allJobsCTA"
                    tabIndex={0}
                    role="button"
                  >
                    <span>Register</span>
                  </a>p
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={8}
            data-react-server-timing-offset={68}
          >
            <div
              className="CenteredTextArea top-banner-subtitle-text hide-desktop"
              id
            >
              <div className="section-row">
                <div className="section-content">
                  <h2 className="section-heading" />
                  <p className="top-banner-subtitle-text">
                    Our mission is to redefine real estate in the customer’s
                    favor. From the technology we build to the brokerage
                    services we provide.
                  </p>
                  <a
                   href="https://accounts.redcoralhomes.com/register.php"
                    className="button Button v3 primary"
                    data-name="allJobsCTA"
                    tabIndex={0}
                    role="button"
                  >
                    <span>Register</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={9}
            data-react-server-timing-offset={68}
          >
            <div className="BeYourself ">
              <div className="section-row">
                <div className="section">
                  <div className="col">
                    <h2>Be yourself</h2>
                    <p>
                      We promote and maintain a diverse community where people
                      feel supported, valued, and included, regardless of who
                      they are or where they came from.
                    </p>
                  </div>
                  <div className="col">
                    <h2>Make a difference</h2>
                    <p>
                      Buying or selling a home can be the most important event
                      in a person’s lifetime. At Red Coral Real Estates you’ll
                      work to make that process better for customers, affecting
                      their everyday happiness and financial well-being.
                    </p>
                  </div>
                  <div className="col">
                    <h2>Learn from the best</h2>
                    <p>
                      Red Coral Real Estates attracts the most talented people
                      from all fields. Whether you’re an engineer, marketer,
                      real estate agent, or something else, you’ll be challenged
                      and inspired every day.{" "}
                    </p>
                  </div>
                </div>
              </div>
              <div className="FullBleedVideo small">
                <div
                  className="section-row clickable "
                  style={{
                    backgroundImage: `url(${career2})`,
                  }}
                />
                <div className="section-content copy-right">
                  <div
                    className="section-container clickable"
                    id="videoVideoClickNode"
                  >
                    <div className="video-play-button-container">
                      <svg className="SvgIcon play-circle play">
                        <svg viewBox="0 0 24 24">
                          <g fillRule="evenodd">
                            <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10S2 17.514 2 12 6.486 2 12 2m0-2C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM9 7.451v9.098a.25.25 0 00.382.212l7.279-4.549a.25.25 0 000-.424L9.382 7.239A.25.25 0 009 7.451z" />
                          </g>
                        </svg>
                      </svg>
                    </div>
                    <div className="video-copy-container">
                      <h3 className="intro-text ">
                        Hear what employees love most about working at Red Coral
                        Real Estates.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-row awards">
                <div className="section-container">
                  <div className="sub-container">
                    <div className="award">
                      <img className="icon" src={career3} />
                      <div className="text">
                        <h4>Best-Led Companies</h4>
                        <p>Inc.</p>
                      </div>
                    </div>
                    <div className="award">
                      <img className="icon" src={career4} />
                      <div className="text">
                        <h4>Q1 Best Company Outlook</h4>
                        <p>Comparably</p>
                      </div>
                    </div>
                  </div>
                  <div className="sub-container">
                    <div className="award">
                      <img className="icon" src={career5} />
                      <div className="text">
                        <h4>Best Places to Work</h4>
                        <p>Built In</p>
                      </div>
                    </div>
                    <div className="award">
                      <img className="icon" src={career6} />
                      <div className="text">
                        <h4>America’s Best Mid-Size Employers</h4>
                        <p>Forbes</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={10}
            data-react-server-timing-offset={68}
          >
            <div className="CenteredTextArea perks-intro" id>
              <div className="section-row">
                <div className="section-content">
                  <h2 className="section-heading">Pile on the perks!</h2>
                  <p>
                    Based on your role and location, our comprehensive benefits
                    include:
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={11}
            data-react-server-timing-offset={68}
          >
            <div className="TextTable PerksTable">
              <div className="section-row">
                <div className="section-container undefined">
                  <div className="table">
                    <div className="table-row">
                      <div className="subsection">
                        <p className="font-weight-bold">Total Compensation</p>
                        <p>
                          In addition to a competitive salary, you could be
                          eligible for bonuses, a range of 401(k) plans, and our
                          ESPP (employee stock purchase plan).
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Healthcare</p>
                        <p>
                          Choose from various full-family medical, dental, and
                          vision plans, with minimal out-of-pocket expenses.
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Paid Time Off</p>
                        <p>
                          Enjoy three weeks of paid vacation and 10 paid
                          holidays, plus eight sick days.
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Zero Agent Fees</p>
                        <p>
                          Totalling more than $28K per year, we pay our agents’
                          phone minutes and data, mileage, state license dues,
                          association fees, MLS dues, and more.
                        </p>
                      </div>
                    </div>
                    <div className="table-row">
                      <div className="subsection">
                        <p className="font-weight-bold">Real Estate Savings</p>
                        <p>
                          Buy or sell your home with a Red Coral Real Estates
                          Agent and get extra savings on top of excellent
                          service.
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Parental Leave</p>
                        <p>
                          We offer generous, fully paid leave for mothers and
                          fathers, including adoptive parents.
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Career Advancement</p>
                        <p>
                          Take advantage of a wide range of training and
                          continuing education opportunities to advance your
                          career.
                        </p>
                      </div>
                      <div className="subsection">
                        <p className="font-weight-bold">Commuter Benefits</p>
                        <p>
                          Spend less money getting to and from the office with
                          our transit subsidies, plus purchase all of your
                          transit and parking on a pre-tax basis.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="children" />
                </div>
              </div>
            </div>
          </div>
          <div className="ImageGridContainer HomeSweetImageGrid">
            <h2 className="title">Home sweet home</h2>
            <div className="ImageGridRow">
              <div
                className="ImageGridText col-12 col-md-6 row2 Nationwide"
                style={{
                  backgroundImage: `url(https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/careers_map_international_desktop_2x.png)`
        
                }}
              >
                <svg className="SvgIcon activity">
                  <svg viewBox="0 0 24 24">
                    <path
                      d="M2 22v-1.585l4.501-4.485c.161.041.326.07.499.07a1.99 1.99 0 001.437-.614l4.65 1.165a1.992 1.992 0 002.236 1.424 2.022 2.022 0 001.591-1.397 1.964 1.964 0 00-.096-1.402l2.995-4.195c.063.006.123.019.187.019a2 2 0 10-2-2c0 .295.068.573.182.825l-2.996 4.194C15.124 14.013 15.064 14 15 14c-.563 0-1.07.235-1.434.61l-4.654-1.167A1.994 1.994 0 005 14c0 .182.032.356.078.524L2 17.591V.25A.25.25 0 001.75 0H.25A.25.25 0 000 .25v23.5c0 .138.112.25.25.25h23.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H2"
                      fillRule="evenodd"
                    />
                  </svg>
                </svg>
                <h2>International offices, one team</h2>
                <p>
                  redcoral Agents operate in 80+ markets across the U.S. and
                  Canada, from <a href="/city/30749/NY/New-York">New York</a>{" "}
                  and <a href="https://www.redfin.ca/on/toronto">Toronto</a> to{" "}
                  <a href="/city/30794/TX/Dallas">Dallas</a> and{" "}
                  <a href="/city/16409/CA/Sacramento">Sacramento</a>. We’re
                  expanding quickly and looking for top talent like you to grow
                  with us.
                </p>
                <a href="#" className="see-all-jobs-link">
                  See All Jobs
                </a>
              </div>
              <div className="ImageGridImage col-12 col-md-6 row2 ImageGridImage">
                <div className="sliding-div">
                  <div
                    className="image"
                    style={{
                      backgroundImage:
                        "url(",
                    }}
                  />
                  <div className="sliding-div-content">
                    <div className="sliding-div-button">
                      <svg className="SvgIcon show-less">
                        <svg viewBox="0 0 24 24">
                          <path
                            d="M16.116 14.53L12 10.414 7.884 14.53a.25.25 0 01-.354 0l-1.06-1.06a.25.25 0 010-.354l5.353-5.353a.25.25 0 01.354 0l5.353 5.353a.25.25 0 010 .354l-1.06 1.06a.25.25 0 01-.354 0"
                            fillRule="evenodd"
                          />
                        </svg>
                      </svg>
                    </div>
                    <h3>Seattle HQ</h3>
                    <h4 />
                    <div className="text">
                      <p>
                        Located in Denny Triangle, our spacious Seattle HQ is a
                        commuter’s dream. Each floor is complete with a
                        fully-stocked kitchen, open work spaces, and sweeping
                        views of the city.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ImageGridRow">
              <div className="ImageGridColumn hide-mobile">
                <div
                  className="col-md-6 row2 RedfinLifeGrid2"
                  style={{
                    backgroundImage:
                      `url(${career21})`,
                  }}
                />
                <div
                  className="col-md-6 row1 RedfinLifeGrid3"
                  style={{
                    backgroundImage:
                      `url(${career22})`,
                  }}
                />
                <div
                  className="col-md-6 row1 RedfinLifeGrid4"
                  style={{
                    backgroundImage:
                      "url(https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/redfin_life_grid_4.jpg)",
                  }}
                />
              </div>
              <div className="ImageGridColumn full-mobile">
                <div className="ImageGridImage col-12 row2 ImageGridImage">
                  <div className="sliding-div">
                    <div
                      className="image"
                      style={{
                        backgroundImage:
                          "url(https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/redfin_life_grid_5_mobile.jpg)",
                      }}
                    />
                    <div className="sliding-div-content">
                      <div className="sliding-div-button">
                        <svg className="SvgIcon show-less">
                          <svg viewBox="0 0 24 24">
                            <path
                              d="M16.116 14.53L12 10.414 7.884 14.53a.25.25 0 01-.354 0l-1.06-1.06a.25.25 0 010-.354l5.353-5.353a.25.25 0 01.354 0l5.353 5.353a.25.25 0 010 .354l-1.06 1.06a.25.25 0 01-.354 0"
                              fillRule="evenodd"
                            />
                          </svg>
                        </svg>
                      </div>
                      <h3>San Francisco</h3>
                      <h4 />
                      <div className="text">
                        <p>
                          Our San Francisco office is located in the heart of
                          the Financial District, offering easy commutes from
                          all over the Bay Area. The modern office design and
                          open floor plan are complemented by picturesque views
                          of the Bay and Coit Tower.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ImageGridRow">
              <img
                className="hide-mobile col2 row1"
                src="https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/redfin_life_grid_6.jpg"
              />
              <img
                className="hide-mobile col1 row1"
                src="https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/redfin_life_grid_7.jpg"
              />
              <img
                className="hide-mobile col1 row1"
                src="https://ssl.cdn-redfin.com/v465.1.0/images/jobs/engineering-hq/redfin_life_grid_8.jpg"
              />
            </div>
          </div>
          <div
            data-react-server-root-id={13}
            data-react-server-timing-offset={68}
          >
            <div className="CenteredTextArea JobsFooter footer" id>
              <div className="section-row">
                <div className="section-content">
                  <h2 className="section-heading" />
                  <h3>Apply Now</h3>
                  <p>
                    Check out all our teams and open positions at Red Coral Real
                    Estates to see what interests you.
                  </p>
                  <a
                    href="https://accounts.redcoralhomes.com/register.php"
                    className="button Button v3 primary"
                    data-name="SeeAllJob"
                    tabIndex={0}
                    role="button"
                  >
                    <span>Register</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={14}
            data-react-server-timing-offset={68}
          >
            <div className="CenteredTextArea EqualOpportunitySection" id>
              <div className="section-row">
                <div className="section-content">
                  <h2 className="section-heading" />
                  <h3>Equal Opportunity</h3>
                  <p>
                    Red Coral Real Estates is an equal opportunity employer.
                    Individuals seeking employment at Red Coral Real Estates are
                    considered without regard to race, color, religion, national
                    origin, age, sex, marital status, ancestry, physical or
                    mental disability, veteran status or sexual orientation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={15}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={16}
            data-react-server-timing-offset={69}
          />
          <div
            data-react-server-root-id={17}
            data-react-server-timing-offset={69}
          />
          <div
            data-react-server-root-id={18}
            data-react-server-timing-offset={69}
          />
          <div
            data-react-server-root-id={19}
            data-react-server-timing-offset={69}
          />
          <div
            data-react-server-root-id={20}
            data-react-server-timing-offset={69}
          />
          <div
            data-react-server-root-id={21}
            data-react-server-timing-offset={69}
          />
          <div data-react-server-container={22}>
            <div
              data-react-server-root-id={23}
              data-react-server-timing-offset={69}
            />
            <div
              data-react-server-root-id={24}
              data-react-server-timing-offset={69}
            />
            <div
              data-react-server-root-id={25}
              data-react-server-timing-offset={69}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={27}
            data-react-server-timing-offset={69}
          >
            <Footer />
          </div>
          <div
            data-react-server-root-id={28}
            data-react-server-timing-offset={69}
          >
            <noscript />
          </div>
        </div>

        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div
                  className="ot-pc-logo"
                  role="img"
                  aria-label="Red Coral Real Estates-logo"
                >
                  <img
                    alt="Red Coral Real Estates-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Red Coral Real Estates-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>

              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
          </div>
        </div>
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2013%3A30%3A34%20GMT&n=-1&b=Welcome%20%7C%20Redfin&.yp=10184908&f=https%3A%2F%2Fwww.redfin.com%2Fcareers&e=https%3A%2F%2Fwww.redfin.com%2Fpartner&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />

        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
