import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
// import {theme} from "./theme"
// import { ThemeProvider } from '@emotion/react';
import {
  BrowserRouter,

} from "react-router-dom";








const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <ThemeProvider theme={theme}>  
   <BrowserRouter>

      <App/>
    
  
  


</BrowserRouter>

  // </ThemeProvider>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

