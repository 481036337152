import React from "react";
import Footer from "./Footer"
import Navbar2 from "./Navbar2"
import { Link } from "react-router-dom";
import a31 from "./Images/31.jpg"

function Buy4() {
  return (
    <div>
      <div>

        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={55}
          >
        <Navbar2/>
          </div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={6}
            data-react-server-timing-offset={55}
          >
            <div
              className="TopBannerSection"
              style={{
                backgroundImage:
                  `url(${a31})`,
              }}
            >
              <div className="banner-alignment">
                <div className="banner-contents">
                  <h1 className="title-text">
                    <span>Free Real Estate Classes and Events</span>
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={7}
            data-react-server-timing-offset={55}
          >
            <section className="intro-text">
              <p>
                Buying or selling a home can be complicated, and Red Coral Real Estates is here
                to help! Join us for a free class to learn how to navigate the
                process from start to finish while enjoying food and drinks on
                us. Or, if you prefer to learn from the comfort of your home or
                office, sign up for one of our webinars instead! Either way, a
                local Red Coral Real Estates Agent will make sure you're ready for your real
                estate journey.
              </p>
            </section>
          </div>
          <div
            data-react-server-root-id={8}
            data-react-server-timing-offset={55}
          >
         
          </div>
          <div
            data-react-server-root-id={9}
            data-react-server-timing-offset={55}
          >
            <section className="ClassCards">
              <section className="GhostTownCard">
                <div className="container">
                  <h3>
                    <svg className="SvgIcon appointments">
                      <svg viewBox="0 0 24 24">
                        <g fillRule="nonzero">
                          <path d="M0 1.25A.25.25 0 01.25 1h23.5a.25.25 0 01.25.25v21.5a.25.25 0 01-.25.25H.25a.25.25 0 01-.25-.25V1.25zM18 3a1 1 0 100 2 1 1 0 000-2zM6 3a1 1 0 100 2 1 1 0 000-2zM2 7v14h20V7H2z" />
                          <path d="M16.75 17h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM12.75 17h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM8.75 17h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM16.75 13h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM12.75 13h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25zM8.75 13h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25z" />
                        </g>
                      </svg>
                    </svg>
                    No Upcoming Classes
                  </h3>
                  <p>
                    Sorry, there aren't any upcoming classes in your area. Don't
                    worry, you can learn more about buying or selling by talking
                    with a Red Coral Real Estates agent.
                  </p>
                </div>
              </section>
            </section>
          </div>
          <div
            data-react-server-root-id={10}
            data-react-server-timing-offset={55}
          >
            <section className="learn-how">
              <div className="row fluid-gutter">
                <div className="col-12">
                  <h2>Learn how to buy or sell in your market</h2>
                  <p>
                    Looking to buy in a hot market like{" "}
                    <Link to="#">New York</Link>,{" "}
                    <Link to="#">Denver</Link>, or{" "}
                    <Link to="#">Portland</Link>? Or do you
                    have a home to sell in{" "}
                    <Link herf="#">Phoenix</Link>,{" "}
                    <Link to="#">Las Vegas</Link>, or{" "}
                    <Link to="#">Fort Lauderdale</Link>?
                    Whatever your situation and challenges, our real estate
                    classes can give you the tools to navigate your buying or
                    selling journey.
                  </p>
                </div>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={11}
            data-react-server-timing-offset={55}
          >
            <div
              className="horiz-gif-container twoCol multipleFieldsVisible bg-color-white"
              id="consult-form"
            >
              <div className="title-container header-type-configured">
                <h2 className="heading-large margin-vert-medium title  text-and-disclaimer-flyout">
                  <span>Talk to a local Red Coral Real Estates Agent</span>
                </h2>
                <p className="subtitle">
                  We’re here to help seven days a week.
                </p>
                <p>We’re here to help seven days a week. Create an account and talk to a agent.</p>
              </div>
              <section id="contactus" role="form" aria-label="Contact Red Coral Real Estates">
                <div className="GeneralInquiryForm">
                  <div className="contact-text">
                    <h3>Ask an Agent</h3>
                    <h3 />
                 
                  </div>
               
                </div>
              </section>
            </div>
          </div>
          <div
            data-react-server-root-id={12}
            data-react-server-timing-offset={55}
          >
            <section className="seo-content">
              <div className="seo-column">
                <h4>Free Classes</h4>
                <p>
                  Whether you're interested in a real estate job at Red Coral Real Estates or
                  want to master the basics of buying or selling a home, free
                  Red Coral Real Estates real estate classes and events are a great way to get
                  started.
                </p>
              </div>
              <div className="seo-column">
                <h4>Taught by Local Experts</h4>
                <p>
                  Our Red Coral Real Estates experts are available to answer all of your
                  questions and share their knowledge of top real estate topics
                  and trends, including the types of mortgage loans that are
                  available, and how to get pre-approved.
                </p>
              </div>
              <div className="seo-column">
                <h4>Winning Strategies</h4>
                <p>
                  Other popular classes include the best strategies for buying
                  in a competitive housing market, and important tactics for
                  selling your home for top dollar. Our free classes are also a
                  great way to get acquainted with Red Coral Real Estates, a next-generation
                  brokerage that’s redefining real estate in the consumer’s
                  favor.
                </p>
              </div>
            </section>
          </div>
          <div
            data-react-server-root-id={13}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={14}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={15}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={16}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={17}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={18}
            data-react-server-timing-offset={55}
          />
          <div
            data-react-server-root-id={19}
            data-react-server-timing-offset={55}
          />
          <div data-react-server-container={20}>
            <div
              data-react-server-root-id={21}
              data-react-server-timing-offset={55}
            />
            <div
              data-react-server-root-id={22}
              data-react-server-timing-offset={55}
            />
            <div
              data-react-server-root-id={23}
              data-react-server-timing-offset={55}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={25}
            data-react-server-timing-offset={55}
          >
      <Footer/>
          </div>
          <div
            data-react-server-root-id={26}
            data-react-server-timing-offset={55}
          >
            <noscript />
          </div>
        </div>

        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div className="ot-pc-logo" role="img" aria-label="Red Coral Real Estates-logo">
                  <img
                    alt="Red Coral Real Estates-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Red Coral Real Estates-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>
        
              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
         
          </div>
        </div>
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2009%3A04%3A24%20GMT&n=-1&b=Classes%20and%20Events&.yp=10184908&f=https%3A%2F%2Fwww.Red Coral Real Estates.com%2Fbuy-a-home%2Fclasses-and-events&e=https%3A%2F%2Fwww.Red Coral Real Estates.com%2Fbuy-a-home%2Fopenbook%2Flong-island&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />

        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Buy4;
