import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer"
import Navbar2 from "./Navbar2"
import r from "./Images/r.png"
import { Slider, useForkRef } from "@mui/material";

function Rates() {
  const [set, setset] = useState(0)
  const [set1, set1set] = useState(0)

  
  const [loanAmount, setLoanAmount] = useState();
  const [interestRate, setInterestRate] = useState('');
  const [loanTerm, setLoanTerm] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [downPayment, setDownPayment] = useState(0);


useEffect(() => {
  calculateMonthlyPayment()
  Downpayment() 
}, [set1, set])


  const calculateMonthlyPayment = () => {
    const r = 7 / 1200; // monthly interest rate
    const n = 30 * 12; // number of payments
    const numerator = r * (1 + r) ** n;
    const denominator = (1 + r) ** n - 1;
    const result = (set*100000 * (numerator / denominator)).toFixed(2);
    console.log(loanAmount)
    console.log(denominator)
    console.log(numerator)
    setMonthlyPayment(result);
  };


  const Downpayment = () => {
    if(set && set1 ){
      const d = set1 % set
      setDownPayment(d)
    }
    else{
      setDownPayment(0)
    }

  };

  return (
    <div>
  
      <div id="content" data-react-server-content>
        <div
          data-react-server-root-id={0}
          data-react-server-timing-offset={71}
        />
        <div data-react-server-root-id={1} data-react-server-timing-offset={82}>
          <div id="header" data-rf-test-name="header" className>
          <Navbar2 />
          </div>
        </div>
        <div
          data-react-server-root-id={2}
          data-react-server-timing-offset={84}
        />
        <div
          data-react-server-root-id={3}
          data-react-server-timing-offset={85}
        />
        <div
          data-react-server-root-id={4}
          data-react-server-timing-offset={85}
        />
        <div
          data-react-server-root-id={5}
          data-react-server-timing-offset={85}
        />
        <div data-react-server-root-id={6} data-react-server-timing-offset={94}>
          <section className="MortgageCalculatorWidget" style={{marginTop:"4rem"}}>
            <div className="CalculatorWidgetTitle row-center">
              <div className="col-md-10 col-push-md-1 col-lg-8 col-push-lg-2">
                <h1 className="heading-larger text-center">
                  Mortgage Calculator
                </h1>
                <h2 className="text-base font-line-height-spacious text-center margin-top-smaller">
                  Estimate your mortgage payment, including the principal and
                  interest, taxes, insurance, HOA, and PMI. Add your location
                  for more accurate estimates.
                </h2>
              </div>
            </div>
            <div className="StandaloneMortgageCalculator row-center">
              <div className="calculator-container">
                <div className="form-panel col-12 col-md-4">
                  <div className="MortgageForm">
                    <form
                      className="ActionEmittingForm StandardInputs decorate-required"
                      method="post"
                      noValidate
                      data-rf-form-name="MortgageForm"
                      data-rf-test-name="ActionEmittingForm"
                    >
                      <div className="StaticFieldWrapper StaticTextSliderField">
                        <div className="field TextAndSlider">
                          <span
                            data-rf-test-name="Text"
                            className="field text Text optional value"
                          >
                            <label
                              className="label"
                              data-rf-test-name="label"
                              aria-label="Home price"
                            >
                              Home price
                            </label>
                            <span className="input">
                              <div role="presentation" className="value text">
                                <input
                                  type="text"
                                  name="value"
                                  defaultValue="$250,000"
                                  placeholder=" "
                                  value={set*100000}
                                
                                  inputMode
                                  className="currency"
                                  data-rf-input-event-type="onInput"
                                  data-rf-test-name="input"
                                  aria-label="Home price"
                                  tabIndex={0}
                                  autoComplete="off"
                                  style={{paddingInline:"1rem"}}
                                />
                              </div>
                            </span>
                          </span>
                       
                          <Slider defaultValue={50}  onChange={(e)=>{setset(e.target.value)}} valueLabelDisplay="auto" />
                        </div>
                       
                      </div>
                      <div className="StaticFieldWrapper StaticTextSliderField">
                        <div className="field TextAndSlider TextAndSliderWithPercent">
                          <div className="text-with-percent">
                            <span
                              data-rf-test-name="Text"
                              className="field text Text optional value"
                            >
                              <label
                                className="label"
                                data-rf-test-name="label"
                                aria-label="Down payment"
                              >
                                Down payment
                              </label>
                              <span className="input">
                                <div role="presentation" className="value text">
                                  <input
                                    type="text"
                                    name="value"
                                    defaultValue="$85,000"
                                    placeholder=" "
                                    value={set1*100000}
                                    inputMode
                                    className="currency"
                                    data-rf-input-event-type="onInput"
                                    data-rf-test-name="input"
                                    aria-label="Down payment"
                                    tabIndex={0}
                                    autoComplete="off"
                                    style={{paddingInline:"1rem"}}
                                  />
                                </div>
                              </span>
                            </span>
                            <span
                              data-rf-test-name="Text"
                              className="field text Text optional percent"
                            >
                              <label
                                className="label"
                                data-rf-test-name="label"
                                aria-label="Down payment"
                              >
                                {" "}
                              </label>
                              <span className="input">
                                <div role="presentation" className="value text">
                                  <input
                                    type="text"
                                    name="percent"
                                    defaultValue="34%"
                                    placeholder=" "
                                      value={downPayment + "%"}
                                    inputMode
                                    className="percent"
                                    data-rf-input-event-type="onInput"
                                    data-rf-test-name="input"
                                    aria-label="Down payment"
                                    tabIndex={0}
                                    autoComplete="off"
                                    style={{paddingInline:"1rem"}}
                                  />
                                </div>
                              </span>
                            </span>
                          </div>
                          <Slider defaultValue={50} aria-label="Default"   onChange={(e)=>{set1set(e.target.value)}}/>
                          <input type="hidden" name="downPayment" />
                        </div>
                        <div className="helper-text">
                          Cash you can pay when you close.
                        </div>
                      </div>
                  
                    </form>
                
                  </div>
                </div>
                <div className="summary-panel col-12 col-md-8">
                  <div className="summary-and-lender-container">
                    <div className="MortgageSummary">
                      <div className="CalculatorSummary">
                        <div className="sectionText">
                          <p className="title heading-medium">
                          ${monthlyPayment}
                          </p>
                          <div className="subTitle">
                            <span className="font-size-base">
                              {" "}
                              {/* */}30 Year Fixed, 6.750% Interest{/* */}{" "}
                            </span>
                            <span>
                              <div className="DefinitionFlyout definition-flyout-container react inline-block">
                                <span className="DefinitionFlyoutLink inline-block">
                                  <div
                                    className="definition-icon info"
                                    tabIndex={0}
                                  >
                                    <svg className="SvgIcon info size-small">
                                      <svg viewBox="0 0 24 24">
                                        <g fillRule="nonzero">
                                          <path d="M12 0c6.627 0 12 5.373 12 12s-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0zm0 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2z" />
                                          <path d="M13 16v-5.75a.25.25 0 00-.25-.25h-2.5a.25.25 0 00-.25.25V12h1v4h-1v1.75c0 .138.112.25.25.25h3.5a.25.25 0 00.25-.25V16h-1zM12.75 8h-1.5a.25.25 0 01-.25-.25v-1.5a.25.25 0 01.25-.25h1.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25z" />
                                        </g>
                                      </svg>
                                    </svg>
                                  </div>
                                </span>
                              </div>
                            </span>
                          </div>
                        </div>
                    
                     
                      </div>
                    </div>
                    <hr />
                
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div data-react-server-root-id={7} data-react-server-timing-offset={95}>
          <div className="TextWidget bg-white padding-horiz-none padding-bottom-compact padding-top-compact">
            <div className="row-center">
              <div className="col-md-10 col-push-md-1 col-lg-6 col-push-lg-3">
                <div>
                  <h2 className="heading-large margin-vert-medium text-center text-and-disclaimer-flyout">
                    <span>Calculating your mortgage payment</span>
                  </h2>
                </div>
                <div>
                  <p className="margin-vert-medium text-center text-and-disclaimer-flyout">
                    <span>
                      Red Coral Real Estates's mortgage calculator estimates your monthly
                      mortgage payment based on a number of factors. Your
                      mortgage payment includes your principal and interest,{" "}
                       down payment, loan term, homeowners insurance, property taxes, and HOA
                      fees. This gives you the ability to compare a number of
                      different home loan scenarios and how it will impact your
                      budget.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-react-server-root-id={8} data-react-server-timing-offset={96}>
          <div className="TwoColumnTextWidget content bg-white padding-bottom-compact padding-top-compact">
            <div className="row-center">
              <div className="column justify-start col-12 col-md-6">
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>How do you decide how much house you can afford?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    As a general rule, when buying a home you should try to keep
                    your house payment lower than 30% of your gross monthly
                    income. This should include mortgage interest, property
                    taxes, HOA fees, and maintenance. If you choose to go above
                    that percentage, it could impact you financially by taking
                    away the ability to save or pay for unforeseen expenses. Use
                    our{" "}
                    <Link to="#">
                      affordability calculator
                    </Link>{" "}
                    to help you determine how much house you can afford.
                  </span>
                </p>
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What can you do to lower your mortgage payment?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    There are a number of things that you can do to help lower
                    your monthly mortgage payment if you can't afford the home
                    of your dreams. Try different scenarios on our mortgage
                    calculator, but some ways to reduce your mortgage payment
                    are as follows:{" "}
                  </span>
                </p>
                <ul className="TextComponentList text-left  text-and-disclaimer-flyout">
                  <li>
                    <span>Improve your credit score</span>
                  </li>
                  <li>
                    <span>
                      Put 20% down or as much as you can for your down payment
                    </span>
                  </li>
                  <li>
                    <span>
                      Try to avoid PMI (private mortgage insurance) if you can
                    </span>
                  </li>
                  <li>
                    <span>
                      Choose a longer-term mortgage like a 30-year rather than a
                      15-year loan
                    </span>
                  </li>
                  <li>
                    <span>
                      Get a lower mortgage interest rate by shopping around to
                      different lenders
                    </span>
                  </li>
                </ul>
              </div>
              <div className="column justify-start col-12 col-md-6">
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>How do we calculate your monthly payment?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    The formula we use in our mortgage calculator is: P =
                    L*(c*(1 + c)^n)/((1 + c)^n - 1), where:
                  </span>
                </p>
                <ul className="TextComponentList text-left  text-and-disclaimer-flyout">
                  <li>
                    <span>P = Monthly mortgage payment</span>
                  </li>
                  <li>
                    <span>L = Mortgage loan amount</span>
                  </li>
                  <li>
                    <span>C = Your mortgage interest rate</span>
                  </li>
                  <li>
                    <span>
                      N = Number of monthly payments over the lifetime of the
                      mortgage
                    </span>
                  </li>
                </ul>
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What type of mortgage is right for me?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    Each situation is different, but here are some guiding
                    principles for each type of mortgage:
                  </span>
                </p>
                <ul className="TextComponentList text-left  text-and-disclaimer-flyout">
                  <li>
                    <span>
                      30-year fixed-rate mortgage - The most common option,
                      typically has a lower monthly payment and your payment
                      doesn't change.
                    </span>
                  </li>
                  <li>
                    <span>
                      15-year fixed-rate mortgage- Similar to the 30-year
                      fixed-rate mortgage, this option pays off your mortgage in
                      15 years, saving you money on interest.
                    </span>
                  </li>
                  <li>
                    <span>
                      7/1 ARM - ARM stands for an adjustable-rate mortgage which
                      means your interest rate can fluctuate after 7 years.
                      Generally, this is best used if you know you'll be in the
                      home for less than 7 years because the interest rate could
                      go up after those 7 years.{" "}
                    </span>
                  </li>
                  <li>
                    <span>
                      5/1 ARM - Similar to the 7/1 ARM, but the interest rate
                      can change after 5 years
                    </span>
                  </li>
                  <li>
                    <span>
                      FHA 30-year fixed - Best for homebuyers with lower credit
                      scores. Also, a great option if you want to put down a
                      smaller down payment.
                    </span>
                  </li>
                  <li>
                    <span>
                      VA loan - 30-year fixed-rate for qualifying veterans and
                      active military. The benefit of this loan is not being
                      required to put any money down and avoiding PMI.
                    </span>
                  </li>
                  <li>
                    <span>
                      Jumbo funding - These are for loan amounts that exceed
                      conventional loan limits
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div data-react-server-root-id={9} data-react-server-timing-offset={96}>
          <div className="TextWidget bg-gray padding-horiz-none padding-bottom-compact padding-top-compact">
            <div className="row-center">
              <div className="col-md-10 col-push-md-1 col-lg-6 col-push-lg-3">
                <div>
                  <h2 className="heading-large margin-vert-medium text-center text-and-disclaimer-flyout">
                    <span>Terminology</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-react-server-root-id={10}
          data-react-server-timing-offset={97}
        >
          <div className="TwoColumnTextWidget content bg-white padding-bottom-compact padding-top-compact">
            <div className="row-center">
              <div className="column justify-start col-12 col-md-6">
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What is a property tax?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    As a homeowner, you’ll pay property tax either twice a year
                    or as part of your monthly home payment. This tax is a
                    percentage of a home’s assessed value and varies by area.
                    For example, a $500,000 home in San Francisco, taxed at a
                    rate of 1.159%, translates to a payment of $5,795 annually.
                  </span>
                </p>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>&nbsp;</span>
                </p>
                <p className="margin-vert-medium text-center  text-and-disclaimer-flyout">
                  <span>500,000 x .01159 = $5,795</span>
                </p>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>&nbsp;</span>
                </p>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    It’s important to consider taxes when deciding how much
                    house you can afford. When you buy a home, you will
                    typically have to pay some property tax back to the seller
                    as part of closing costs. Because property tax is calculated
                    on the home’s assessed value, the amount typically can
                    change drastically once a home is sold, depending on how
                    much the value of the home has increased or decreased. Our
                    mortgage calculator takes the price of the home and gives
                    you an estimate of how much your property tax will be.
                  </span>
                </p>
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>
                    How much is homeowners insurance and what does it cover?
                  </span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    Homeowners insurance is a combination of two types of
                    coverage:
                  </span>
                </p>
                <ul className="TextComponentList text-left  text-and-disclaimer-flyout">
                  <li>
                    <span>
                      Property insurance protects homeowners from a variety of
                      potential threats such as weather-related damages,
                      vandalism, and theft.
                    </span>
                  </li>
                  <li>
                    <span>
                      Liability insurance protects homeowners from lawsuits or
                      claims filed by third parties for accidents that happen
                      within the home.
                    </span>
                  </li>
                </ul>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    The cost of a homeowners insurance policy will vary
                    depending on the type of property being insured (e.g.
                    condominium, mobile home, single-family residence, etc.) and
                    the amount of coverage the owner desires. Lenders require
                    that buyers obtain homeowners insurance in order for the
                    insurance premium to be included in the monthly mortgage
                    payment. A mortgage calculator gives an estimate of your
                    potential cost, but talk to an insurance company to
                    determine the exact amount.{" "}
                  </span>
                </p>
              </div>
              <div className="column justify-start col-12 col-md-6">
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What is a jumbo loan?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    A jumbo loan is used when the mortgage exceeds the limit for
                    Fannie Mae and Freddie Mac, the government-sponsored
                    enterprises that buy loans from banks. Jumbo loans can be
                    beneficial for buyers looking to finance luxury homes or
                    homes in areas with higher median sale prices. However,
                    interest rates on jumbo loans are much higher because
                    lenders don't have the assurance that Fannie or Freddie will
                    guarantee the purchase of the loans.
                  </span>
                </p>
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What is a mortgage interest rate and APR?</span>
                </h3>
                <h4 className="font-size-base font-weight-bold font-line-height-spacious margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>Mortgage Interest Rate</span>
                </h4>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    The mortgage interest rate is the amount charged by a lender
                    in exchange for loaning money to a buyer. It is a yearly
                    percentage of the total loan amount and is calculated into
                    the monthly mortgage payment.
                  </span>
                </p>
                <h4 className="font-size-base font-weight-bold font-line-height-spacious margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>Annual Percentage Rate (APR)</span>
                </h4>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    APR (%) is a number designed to help you evaluate the total
                    cost of a mortgage. In addition to the interest rate, it
                    takes into account the fees other costs you may encounter
                    over the life of the loan. The APR is calculated according
                    to federal requirements and is required by law to be stated
                    in all home mortgage estimates. This allows you to better
                    compare how much mortgage you can afford from different
                    lenders and to see which is the right one for you.
                  </span>
                </p>
                <h3 className="heading-medium margin-vert-medium text-left bottom-border text-and-disclaimer-flyout">
                  <span>What is Private Mortgage Insurance (PMI)?</span>
                </h3>
                <p className="margin-vert-medium text-left  text-and-disclaimer-flyout">
                  <span>
                    Mortgage insurance protects the mortgage lender against loss
                    if a borrower fails to pay on a loan or commonly known as
                    defaults. Private mortgage insurance (PMI) is typically
                    required for borrowers of conventional loans with a down
                    payment of less than 20%. Once you’ve paid down the mortgage
                    balance to 80% of the home's original appraisal value you
                    are able to ask your mortgage service to remove PMI.{" "}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          data-react-server-root-id={11}
          data-react-server-timing-offset={97}
        >
       
        </div>
        <div
          data-react-server-root-id={12}
          data-react-server-timing-offset={97}
        >
      
        </div>
        <div
          data-react-server-root-id={13}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={14}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={15}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={16}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={17}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={18}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={19}
          data-react-server-timing-offset={97}
        />
        <div
          data-react-server-root-id={20}
          data-react-server-timing-offset={97}
        />
        <div data-react-server-container={21}>
          <div
            data-react-server-root-id={22}
            data-react-server-timing-offset={97}
          />
          <div
            data-react-server-root-id={23}
            data-react-server-timing-offset={97}
          />
          <div
            data-react-server-root-id={24}
            data-react-server-timing-offset={97}
          >
            <div className="GoogleOneTapSignOnContainer original">
              <div className="oneTapContainerWrapper">
                <div id="GoogleOneTapParentDiv" />
              </div>
            </div>
          </div>
        </div>
        <div
          data-react-server-root-id={26}
          data-react-server-timing-offset={97}
        >
       <Footer />
        </div>
        <div
          data-react-server-root-id={27}
          data-react-server-timing-offset={97}
        >
          <noscript />
        </div>
      </div>

   
      <div id="onetrust-consent-sdk">
        <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
        <div
          id="onetrust-pc-sdk"
          className="otPcCenter ot-hide ot-fade-in otRelFont"
          lang="en"
          aria-label="Preference center"
          role="region"
        >
          <div
            role="alertdialog"
            aria-modal="true"
            aria-describedby="ot-pc-desc"
            style={{ height: "100%" }}
            aria-label="Privacy Preference Center"
          >
            {/* Close Button */}
            <div className="ot-pc-header">
              {/* Logo Tag */}
              <div className="ot-pc-logo" role="img" aria-label="Red Coral Real Estates-logo">
                <img
                  alt="Red Coral Real Estates-logo"
                  src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Real Coral Real Estates-inline-16px@2x.png"
                />
              </div>
              <button
                id="close-pc-btn-handler"
                className="ot-close-icon"
                aria-label="Close"
                style={{
                  backgroundImage:
                    'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                }}
              />
            </div>
            {/* Close Button */}
            <div id="ot-pc-content" className="ot-pc-scrollbar">
              <div className="ot-optout-signal ot-hide">
                <div className="ot-optout-icon">
                  <svg xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="ot-floating-button__svg-fill"
                      d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                      fill="#FFF"
                      fillRule="evenodd"
                    />
                  </svg>
                </div>
                <span />
              </div>
              <h2 id="ot-pc-title">Privacy Preference Center</h2>
              <div id="ot-pc-desc">
                When you visit any website, it may store or retrieve information
                on your browser, mostly in the form of cookies. This information
                might be about you, your preferences or your device and is
                mostly used to make the site work as you expect it to. The
                information does not usually directly identify you, but it can
                give you a more personalized web experience. Because we respect
                your right to privacy, you can choose not to allow some types of
                cookies. Click on the different category headings to find out
                more and change our default settings. However, blocking some
                types of cookies may impact your experience of the site and the
                services we are able to offer.
              </div>
              <button id="accept-recommended-btn-handler">Allow All</button>
              <section className="ot-sdk-row ot-cat-grp">
                <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                <div
                  className="ot-accordion-layout ot-cat-item ot-vs-config"
                  data-optanongroupid="C0002"
                >
                  <button
                    aria-expanded="false"
                    ot-accordion="true"
                    aria-controls="ot-desc-id-C0002"
                    aria-labelledby="ot-header-id-C0002"
                  />
                  {/* Accordion header */}
                  <div className="ot-acc-hdr ot-always-active-group">
                    <div className="ot-plus-minus">
                      <span />
                      <span />
                    </div>
                    <h4 className="ot-cat-header" id="ot-header-id-C0002">
                      Performance Cookies
                    </h4>
                    <div className="ot-always-active">Always Active</div>
                  </div>
                  {/* accordion detail */}
                  <div className="ot-acc-grpcntr ot-acc-txt">
                    <p
                      className="ot-acc-grpdesc ot-category-desc"
                      id="ot-desc-id-C0002"
                    >
                      These cookies allow us to count visits and traffic sources
                      so we can measure and improve the performance of our site.
                      They help us to know which pages are the most and least
                      popular and see how visitors move around the site. All
                      information these cookies collect is aggregated and
                      therefore anonymous. If you do not allow these cookies we
                      will not know when you have visited our site, and will not
                      be able to monitor its performance.
                    </p>
                  </div>
                </div>
                <div
                  className="ot-accordion-layout ot-cat-item ot-vs-config"
                  data-optanongroupid="C0003"
                >
                  <button
                    aria-expanded="false"
                    ot-accordion="true"
                    aria-controls="ot-desc-id-C0003"
                    aria-labelledby="ot-header-id-C0003"
                  />
                  {/* Accordion header */}
                  <div className="ot-acc-hdr ot-always-active-group">
                    <div className="ot-plus-minus">
                      <span />
                      <span />
                    </div>
                    <h4 className="ot-cat-header" id="ot-header-id-C0003">
                      Functional Cookies
                    </h4>
                    <div className="ot-always-active">Always Active</div>
                  </div>
                  {/* accordion detail */}
                  <div className="ot-acc-grpcntr ot-acc-txt">
                    <p
                      className="ot-acc-grpdesc ot-category-desc"
                      id="ot-desc-id-C0003"
                    >
                      These cookies enable the website to provide enhanced
                      functionality and personalisation. They may be set by us
                      or by third party providers whose services we have added
                      to our pages. If you do not allow these cookies then some
                      or all of these services may not function properly.
                    </p>
                  </div>
                </div>
                <div
                  className="ot-accordion-layout ot-cat-item ot-vs-config"
                  data-optanongroupid="C0001"
                >
                  <button
                    aria-expanded="false"
                    ot-accordion="true"
                    aria-controls="ot-desc-id-C0001"
                    aria-labelledby="ot-header-id-C0001"
                  />
                  {/* Accordion header */}
                  <div className="ot-acc-hdr ot-always-active-group">
                    <div className="ot-plus-minus">
                      <span />
                      <span />
                    </div>
                    <h4 className="ot-cat-header" id="ot-header-id-C0001">
                      Strictly Necessary Cookies
                    </h4>
                    <div className="ot-always-active">Always Active</div>
                  </div>
                  {/* accordion detail */}
                  <div className="ot-acc-grpcntr ot-acc-txt">
                    <p
                      className="ot-acc-grpdesc ot-category-desc"
                      id="ot-desc-id-C0001"
                    >
                      These cookies are necessary for the website to function
                      and cannot be switched off in our systems. They are
                      usually only set in response to actions made by you which
                      amount to a request for services, such as setting your
                      privacy preferences, logging in or filling in forms. You
                      can set your browser to block or alert you about these
                      cookies, but some parts of the site may not work.
                    </p>
                  </div>
                </div>
                <div
                  className="ot-accordion-layout ot-cat-item ot-vs-config"
                  data-optanongroupid="C0004"
                >
                  <button
                    aria-expanded="false"
                    ot-accordion="true"
                    aria-controls="ot-desc-id-C0004"
                    aria-labelledby="ot-header-id-C0004"
                  />
                  {/* Accordion header */}
                  <div className="ot-acc-hdr ot-always-active-group">
                    <div className="ot-plus-minus">
                      <span />
                      <span />
                    </div>
                    <h4 className="ot-cat-header" id="ot-header-id-C0004">
                      Advertising/Targeting Cookies
                    </h4>
                    <div className="ot-always-active">Always Active</div>
                  </div>
                  {/* accordion detail */}
                  <div className="ot-acc-grpcntr ot-acc-txt">
                    <p
                      className="ot-acc-grpdesc ot-category-desc"
                      id="ot-desc-id-C0004"
                    >
                      These cookies may be set through our site by our
                      advertising partners. They may be used by those companies
                      to build a profile of your interests and show you relevant
                      adverts on other sites. They do not store directly
                      personal information, but are based on uniquely
                      identifying your browser and internet device. If you do
                      not allow these cookies, you will experience less targeted
                      advertising.
                    </p>
                  </div>
                </div>
                {/* Groups sections starts */}
                {/* Group section ends */}
                {/* Accordion Group section starts */}
                {/* Accordion Group section ends */}
              </section>
            </div>
            <section
              id="ot-pc-lst"
              className="ot-hide ot-hosts-ui ot-pc-scrollbar"
            >
              <div id="ot-pc-hdr">
                <div id="ot-lst-title">
                  <button
                    className="ot-link-btn back-btn-handler"
                    aria-label="Back"
                  >
                    <svg
                      id="ot-back-arw"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 0 444.531 444.531"
                      xmlSpace="preserve"
                    >
                      <title>Back Button</title>
                      <g>
                        <path
                          fill="#656565"
                          d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                        />
                      </g>
                    </svg>
                  </button>
                  <h3>Performance Cookies</h3>
                </div>
                <div className="ot-lst-subhdr">
                  <div className="ot-search-cntr">
                    <p role="status" className="ot-scrn-rdr" />
                    <label
                      htmlFor="vendor-search-handler"
                      className="ot-scrn-rdr"
                    />{" "}
                    <input
                      id="vendor-search-handler"
                      type="text"
                      name="vendor-search-handler"
                    />{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      x="0px"
                      y="0px"
                      viewBox="0 -30 110 110"
                      aria-hidden="true"
                    >
                      <title>Search Icon</title>
                      <path
                        fill="#2e3644"
                        d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                      />
                    </svg>
                  </div>
                  <div className="ot-fltr-cntr">
                    <button
                      id="filter-btn-handler"
                      aria-label="Filter"
                      aria-haspopup="true"
                    >
                      <svg
                        role="presentation"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 402.577 402.577"
                        xmlSpace="preserve"
                      >
                        <title>Filter Icon</title>
                        <g>
                          <path
                            fill="#fff"
                            d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                          />
                        </g>
                      </svg>
                    </button>
                  </div>
                  <div id="ot-anchor" />
                  <section id="ot-fltr-modal">
                    <div id="ot-fltr-cnt">
                      <button id="clear-filters-handler">Clear</button>
                      <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                        <div className="ot-fltr-opts">
                          <div className="ot-fltr-opt">
                            <div className="ot-chkbox">
                              <input
                                id="chkbox-id"
                                type="checkbox"
                                aria-checked="false"
                                className="category-filter-handler"
                              />{" "}
                              <label htmlFor="chkbox-id">
                                <span className="ot-label-txt">
                                  checkbox label
                                </span>
                              </label>{" "}
                              <span className="ot-label-status">label</span>
                            </div>
                          </div>
                        </div>
                        <div className="ot-fltr-btns">
                          <button id="filter-apply-handler">Apply</button>{" "}
                          <button id="filter-cancel-handler">Cancel</button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
              <section id="ot-lst-cnt" className="ot-host-cnt ot-pc-scrollbar">
                <div id="ot-sel-blk">
                  <div className="ot-sel-all">
                    <div className="ot-sel-all-hdr">
                      <span className="ot-consent-hdr">Consent</span>{" "}
                      <span className="ot-li-hdr">Leg.Interest</span>
                    </div>
                    <div className="ot-sel-all-chkbox">
                      <div className="ot-chkbox" id="ot-selall-hostcntr">
                        <input
                          id="select-all-hosts-groups-handler"
                          type="checkbox"
                          aria-checked="false"
                        />{" "}
                        <label htmlFor="select-all-hosts-groups-handler">
                          <span className="ot-label-txt">checkbox label</span>
                        </label>{" "}
                        <span className="ot-label-status">label</span>
                      </div>
                      <div className="ot-chkbox" id="ot-selall-vencntr">
                        <input
                          id="select-all-vendor-groups-handler"
                          type="checkbox"
                          aria-checked="false"
                        />{" "}
                        <label htmlFor="select-all-vendor-groups-handler">
                          <span className="ot-label-txt">checkbox label</span>
                        </label>{" "}
                        <span className="ot-label-status">label</span>
                      </div>
                      <div className="ot-chkbox" id="ot-selall-licntr">
                        <input
                          id="select-all-vendor-leg-handler"
                          type="checkbox"
                          aria-checked="false"
                        />{" "}
                        <label htmlFor="select-all-vendor-leg-handler">
                          <span className="ot-label-txt">checkbox label</span>
                        </label>{" "}
                        <span className="ot-label-status">label</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ot-sdk-row">
                  <div className="ot-sdk-column" />
                </div>
              </section>
            </section>
   
            <span
              className="ot-scrn-rdr"
              aria-atomic="true"
              aria-live="polite"
            />
            {/* Vendor Service container and item template */}
          </div>
       
        </div>
      </div>
      <img
        className="ywa-10000"
        src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2018%3A12%3A47%20GMT&n=-1&b=Mortgage%20Calculator%20with%20PMI%20and%20Taxes%20%7C%20redfin.com&.yp=10184908&f=https%3A%2F%2Fwww.redfin.com%2Fmortgage-calculator&e=https%3A%2F%2Fwww.redfin.com%2Ftodays-mortgage-rates&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
        alt="dot image pixel"
        style={{ display: "none" }}
      />
   
      <div id="fb-root" className=" fb_reset">
        <div
          style={{
            position: "absolute",
            top: "-10000px",
            width: "0px",
            height: "0px",
          }}
        >
          <div />
        </div>
      </div>
    </div>
  );
}

export default Rates;
