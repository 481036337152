import React from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material"; 

function NavMenu() {
  return (
  <Box  sx={{display:{xs:"flex", sm:"none", flexDirection:"row", justifyContent:"flex-end",  }}}>
  <div className="overlay">
      <div className="mobileNavCanvas appNavigation openMobileNavCanvas"  style ={{width:"80vw", backgroundColor:"#fff", zIndex:"1000"}} >
        <div
          data-rf-test-name="MobileNavScrollPane"
          className="mobileNavScrollPane"
        >
          <div className="UserNavMenu appNavigation">
            <ul className="userNodeContainer">
              <li className="userNode">
                <div className="loggedOut">
                  <div className="logoRow">
                    <span className="Svg RedfinLogo fill-Red Coral Real Estates-red">
                      <svg width={110} height={30} viewBox="0 0 110 30">
                        <path d="M56.8 5.7c-2-1.3-4.8-1.8-8.4-1.8h-6.9v22.9h7.1c2.5 0 4.7-.3 6.5-1s3.2-1.9 4.2-3.6 1.5-3.9 1.5-6.7c0-2.4-.3-4.4-.9-6-.9-1.7-1.8-3-3.1-3.8zm-1.9 13.6c-.3 1.1-.9 2-1.7 2.6-.9.7-2.3 1-4.1 1h-2.5V7.5h2.1c2 0 3.6.3 4.6 1.1.7.6 1.3 1.3 1.6 2.4s.5 2.4.5 4c0 1.8-.1 3.2-.5 4.3zm9 7.4h5.2V17h6.1v-3.8h-6.1V7.6h9.3V3.8H63.9v22.9zm17.9 0H87V3.8h-5.2v22.9zM110 3.8h-5.3V17L95 3.8h-4.2v22.9h5.3V13.2l9.6 13.5h4.3V3.8zM21.7 27.6c-.4-.2-.9-.5-2.1-2.5l-2.4-3.9-2.8-4.6-.2-.4c.1 0 .3-.1.4-.1 3.2-1.1 5.1-4 5.1-8.2 0-2.1-.8-4-2.4-5.4-.2-.2-.5-.4-.7-.6C14.4.5 11.5 0 7.7 0H.4C0 0-.1.3.1.5s.4.5.4 1.3v23c-.1.9-.3 1.2-.4 1.4-.2.2-.1.5.4.5h5.3V17h2.4l1.7 2.9 2.1 3.6 1 1.8c1.2 2 2.3 3.3 4.2 3.6.2 0 1.2.2 1.6.2h.4c.8 0 1.6-.3 2.1-.5 0 0 .1-.1.2-.1.7-.4.5-.8.2-.9zM9.2 13.2H5.8V3.8h3.4c1.7 0 3 .2 3.9 1.1.4.3.6.7.8 1.2.2.6.4 1.3.4 2.2 0 1.2-.1 4.9-5.1 4.9zM28.5 17h6.1v-3.8h-6.1V7.6h9.3V3.8H23.3v22.9h14.5v-3.8h-9.3V17z" />
                      </svg>
                    </span>
                    <button className="close button text">
                      <svg className="SvgIcon close size-small">
                        <svg viewBox="0 0 24 24">
                          <g fillRule="evenodd">
                            <path d="M21.105 4.134l-1.061-1.061a.252.252 0 00-.354 0l-7.601 7.602-7.602-7.602a.25.25 0 00-.353 0l-1.061 1.06a.252.252 0 000 .355l7.602 7.6-7.602 7.603a.25.25 0 000 .353l1.06 1.06a.25.25 0 00.354 0l7.602-7.601 7.6 7.602a.252.252 0 00.355 0l1.06-1.061a.25.25 0 000-.353l-7.601-7.602 7.602-7.601a.252.252 0 000-.354" />
                          </g>
                        </svg>
                      </svg>
                    </button>
                  </div>
                  <div className="reg-buttons row">
               
                    <a  href="https://accounts.redcoralhomes.com/login.php"
                      type="button"
                      className="button Button tertiary-alt compact col-6"
                      tabIndex={0}
                      role="button"
                      data-rf-test-name="mwLogInLink"
                    >
                      <span>Log In</span>
                    </a>
                
              

       
              <a href="https://accounts.redcoralhomes.com/register.php"
                      type="button"
                      className="button Button tertiary-alt compact unpadded col-6"
                      tabIndex={0}
                      role="button"
                      data-rf-test-name="mwSignUpLink"
                    >
                      <span>Sign Up</span>
                    </a>
         
                  
                  </div>
                  <div className="reg-message">
                    Members get daily listing updates
                  </div>
                </div>
              </li>
            </ul>
            <ul className="primaryItems">
              <li className="searchLabel">SEARCH</li>
              <li className=" extraIndent">
                <Link
                 to="/buy"
                  className="MenuItem"
              
                 
                >
                  <svg className="SvgIcon for-sale size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 12 18">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.35 3.493v8.728h6.189V3.493H2.35zM2 2.143a1 1 0 00-1 1v9.428a1 1 0 001 1h6.889a1 1 0 001-1V3.143a1 1 0 00-1-1H2z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M.325 1C.325.627.627.325 1 .325h9c.925 0 1.675.75 1.675 1.675v15a.675.675 0 11-1.35 0V2A.325.325 0 0010 1.675H1A.675.675 0 01.325 1z"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Buy
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className=" extraIndent">
                <Link
                  to="/selling-options"
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                
                >
                  <svg className="SvgIcon for-sale size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 12 18">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M2.35 3.493v8.728h6.189V3.493H2.35zM2 2.143a1 1 0 00-1 1v9.428a1 1 0 001 1h6.889a1 1 0 001-1V3.143a1 1 0 00-1-1H2z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M.325 1C.325.627.627.325 1 .325h9c.925 0 1.675.75 1.675 1.675v15a.675.675 0 11-1.35 0V2A.325.325 0 0010 1.675H1A.675.675 0 01.325 1z"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Sell
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className=" extraIndent">
                <Link
                 to="/rent"
                  className="MenuItem"
               
                 
                >
                  <svg className="SvgIcon for-rent size-medium menuIcon rfSvg fill-link-black forRentIcon">
                    <svg viewBox="0 0 20 20">
                      <g clipPath="url(#for-rent-a)">
                        <path d="M10.305 9.954l-.408-.29-.014.558.422-.268zm-2.256 1.524l.383-.32-.5-.166.117.486zm.296.355l-.383.32.275.168.108-.488zm.65.143l.268.422-.16-.91-.109.488zm-.793.505l-.269-.421-.153.69.422-.268zm.505.793l-.422.269.314.219.108-.488zm1.314.29l.44.24-.332-.727-.108.488zm-.441.81l-.44-.239-.005.468.445-.229zm.353.69l-.444.228.336.26.108-.488zm1.065.235l.449.221-.34-.709-.109.488zm-.38.771l-.448-.22.026.489.422-.269zm.622.975l-.422.27.48.227-.058-.497zm.877-.102l.358-.35-.415-.147.057.497zm.37.379l.4.299-.042-.649-.358.35zm-.347.464l-.4-.3.262.78.138-.48zm2.592.748l-.139.48.584-.253-.445-.227zm.463-.91l.445.227-.024-.495-.421.268zM10.43 8.455c-.1.45-.285.857-.534 1.21l.816.577c.325-.459.565-.989.694-1.57l-.976-.217zM7.884 4.46a3.35 3.35 0 012.547 3.995l.976.217A4.35 4.35 0 008.1 3.484l-.216.976zM3.888 7.007A3.35 3.35 0 017.884 4.46l.216-.976A4.35 4.35 0 002.912 6.79l.976.216zm2.547 3.995a3.35 3.35 0 01-2.547-3.995l-.976-.216a4.35 4.35 0 003.307 5.188l.216-.977zm1.498-.01a3.333 3.333 0 01-1.498.01l-.216.977a4.335 4.335 0 001.945-.014l-.231-.973zm-.268.807l.297.355.767-.642-.297-.355-.767.643zm.572.522l.649.143.216-.976-.649-.143-.216.976zm.488-.766l-.792.505.537.843.793-.505-.538-.843zM7.78 12.75l.505.793.843-.538-.505-.792-.843.537zm.819 1.012l1.314.291.216-.976-1.314-.291-.216.976zm.983-.436l-.441.81.878.478.441-.81-.878-.478zm-.447 1.277l.354.689.89-.457-.354-.689-.89.457zm.69.949l1.065.235.216-.976-1.064-.236-.217.977zm.725-.474l-.38.771.896.442.38-.77-.896-.443zm-.354 1.26l.622.976.843-.537-.621-.976-.844.538zm1.102 1.204l.877-.102-.116-.994-.877.103.116.993zm.462-.25l.37.379.715-.699-.37-.378-.715.698zm.327-.27l-.347.464.8.6.348-.465-.801-.599zm-.085 1.244l2.591.748.277-.96-2.591-.749-.277.961zm3.175.495l.463-.91-.89-.453-.464.909.891.454zm.44-1.405l-4.89-7.671-.844.537 4.89 7.671.843-.537z" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.101 6.37c0 .53-.106 1.033-.299 1.491l6.433 6.432-.256.989-2.692-.17.239-.529-.443-.29-.835.29-.818-.818.205-.835h-1.09l-.495-.596.256-.886H11.96l-.665-.664.665-.665h-.665l-.366-.282a3.85 3.85 0 112.172-3.466zm-4.756.077a.982.982 0 100-1.963.982.982 0 000 1.963z"
                        />
                        <path d="M12.802 7.861l-.46-.194.107.548.353-.354zm6.433 6.432l.484.126-.13-.48-.354.354zm-.256.989l-.031.498.515-.373-.484-.126zm-2.692-.17l-.455-.207.424.705.031-.499zm.239-.529l.455.206-.182-.624-.273.418zm-.443-.29l.273-.418-.437-.054.164.472zm-.835.29l-.354.354.518.118-.164-.472zm-.818-.818l-.486-.119.133.473.353-.354zm.205-.835l.485.12-.485-.62v.5zm-1.09 0l-.386.32.385.18v-.5zm-.495-.596l-.48-.139.095.458.385-.319zm.256-.886l.48.139-.48-.639v.5zm-1.346 0l-.354.354.354.146v-.5zm-.665-.664l-.353-.354v.707l.353-.353zm.665-.665l.353.354-.353-.854v.5zm-.665 0l-.305.396.305.104v-.5zm-.366-.282l.305-.396-.523-.053.218.45zm2.334-1.782a4.339 4.339 0 00.338-1.684h-1c0 .46-.092.898-.26 1.296l.922.388zm6.325 5.885l-6.432-6.433-.707.708 6.432 6.432.707-.707zm-.125 1.467l.256-.989-.968-.25-.256.988.968.25zm-3.207.203l2.692.17.063-.997-2.692-.17-.063.997zm-.186-1.233l-.238.528.91.412.24-.528-.912-.412zm-.26.335l.442.29.547-.838-.443-.29-.547.838zm-.398.343l.835-.29-.328-.944-.835.29.328.944zm-1.335-.936l.817.818.707-.708-.817-.817-.707.707zm.072-1.308l-.204.835.97.238.205-.835-.971-.238zm-.605.62h1.09v-1h-1.09v1zm-.879-.778l.494.596.77-.638-.494-.596-.77.638zm.16-1.344l-.255.886.96.278.256-.886-.96-.277zm-.865.64h1.346v-1H11.96v1zm-1.018-.812l.664.665.707-.707-.664-.665-.707.707zm.664-1.371l-.664.664.707.707.664-.664-.707-.707zm-.31.853h.664v-1h-.665v1zm-.672-.385l.366.281.61-.792-.366-.282-.61.793zm-1.373.487c.679 0 1.322-.155 1.896-.434l-.436-.9c-.44.214-.936.334-1.46.334v1zM4.9 6.371a4.35 4.35 0 004.35 4.35v-1a3.35 3.35 0 01-3.35-3.35h-1zm4.35-4.35A4.35 4.35 0 004.9 6.37h1c0-1.85 1.5-3.35 3.35-3.35v-1zm4.35 4.35a4.35 4.35 0 00-4.35-4.35v1c1.85 0 3.35 1.5 3.35 3.35h1zm-4.774-.906a.482.482 0 01-.482.482v1c.818 0 1.482-.663 1.482-1.482h-1zm-.482-.481c.266 0 .482.215.482.481h1c0-.818-.664-1.481-1.482-1.481v1zm-.481.481c0-.266.215-.481.481-.481v-1c-.818 0-1.481.663-1.481 1.481h1zm.481.482a.482.482 0 01-.481-.482h-1c0 .819.663 1.482 1.481 1.482v-1z" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1.006 3.752A3.434 3.434 0 117.76 5.004l.983.183a4.434 4.434 0 10-6.406 3.126 4.635 4.635 0 01.112-1.096 3.435 3.435 0 01-1.443-3.465z"
                        />
                      </g>
                      <defs>
                        <clipPath id="for-rent-a">
                          <path d="M0 0h20v20H0z" />
                        </clipPath>
                      </defs>
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    For Rent
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
        
              <li className>
             
              </li>
              <li className>
                <Link
                  className="MenuItem"
              
                  to="https://projects.redcoralhomes.com/current/how-it-works/index.html"
                >
                  <svg className="SvgIcon diamond size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.871 2.89A2 2 0 017.535 2h8.93a2 2 0 011.664.89l3.703 5.555A1 1 0 0121.8 9.6l-9 12a1 1 0 01-1.6 0l-9-12a1 1 0 01-.032-1.155L5.87 2.891zM10.131 4H7.536L4.87 8h2.596l2.667-4zM12 4.803L9.869 8h4.263L12 4.803zM14.613 10H9.387L12 17.838 14.613 10zm-5.51 5.47L7.279 10H5l4.103 5.47zm5.794 0L16.721 10H19l-4.103 5.47zM19.131 8h-2.596l-2.667-4h2.597l2.666 4z"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    REITS Investment
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className>
                <Link
                  className="MenuItem"
               
                  to="/compare-rates"
                >
                  <svg className="SvgIcon sell-your-home size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path
                        d="M11.5 1.604H3.017L1.487.074a.25.25 0 00-.353 0l-1.06 1.06a.25.25 0 000 .353l1.53 1.53V11.5c0 .067.026.13.072.177l11.75 11.75a.25.25 0 00.355 0l9.645-9.647a.25.25 0 000-.353l-11.75-11.75a.249.249 0 00-.176-.073zm-7.897 9.171V5.018l2.3 2.3c-.187.39-.3.823-.3 1.286a3 3 0 103-3c-.461 0-.894.112-1.286.3l-2.3-2.3h5.759l10 10-7.173 7.171-10-10zm5-3.171c.551 0 1 .449 1 1 0 .55-.449 1-1 1-.55 0-1-.45-1-1 0-.551.45-1 1-1z"
                        fillRule="evenodd"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                  compare rates
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
           
            </ul>
            <ul className="secondaryItems">
          
              <li className>
                <Link
                  className="MenuItem"
               
                  to="/"
                >
                  <svg className="SvgIcon logo-R size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path d="M10.644 10.965h-2.53V3.513h2.53c1.242 0 2.208.184 2.921.851.253.276.46.598.621.966.184.46.276 1.035.276 1.725-.023 1.012-.092 3.91-3.818 3.91zm9.315 11.385c-.322-.138-.667-.414-1.541-1.978l-1.771-3.105-2.07-3.657-.184-.322c.092-.023.207-.069.299-.092 2.392-.874 3.772-3.174 3.772-6.486 0-1.633-.621-3.128-1.817-4.255a5.12 5.12 0 00-.552-.437C14.554.914 12.415.5 9.563.5H4.112c-.345 0-.391.207-.253.391.115.184.276.414.322.989v18.216c-.069.736-.207.966-.322 1.15-.138.184-.069.414.276.414h3.979v-7.728h1.817l1.265 2.3 1.587 2.875.782 1.403c.989 1.771 1.863 2.737 3.565 2.944.23.046.483.046.759.046h.276c.621 0 1.219-.253 1.587-.437.046-.023.092-.046.161-.069.46-.207.322-.529.046-.644z" />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Homepage
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className>
                <Link
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                  to="/selling-options"
                >
                  <svg className="SvgIcon Save size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.65 4v16.14l5.908-4.17a2.5 2.5 0 012.884 0l5.908 4.17V4H4.65zm16.7-1.5a.5.5 0 00-.5-.5H3.15a.5.5 0 00-.5.5v20.535a.5.5 0 00.788.409l8.274-5.84a.5.5 0 01.576 0l8.274 5.84a.5.5 0 00.788-.409V2.5z"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Selling Options
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className>
                <Link
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                  to="/selling-options"
                >
                  <svg className="SvgIcon manage size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path d="M16 18v3.75c0 .138-.102.25-.251.25H.25A.246.246 0 010 21.75v-11.5c0-.138.102-.25.251-.25H4V6.241C4 6.104 4.113 6 4.251 6H8V2.241C8 2.104 8.113 2 8.251 2H23.75c.149 0 .251.112.251.25v11.5a.24.24 0 01-.241.25H20v3.75a.24.24 0 01-.241.25H16zm0-2h2V8H6v2h9.749c.138 0 .251.104.251.25V16zM10 6h9.749c.149 0 .251.112.251.25V12h2V4H10v2zM2 20h12v-8H2v8z" />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Compare Selling Options
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
         
          
         
              <li className>
                <Link
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                  to="/real-estate-agent"
                >
                  <svg className="SvgIcon agent size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path d="M23.3 15.6c-.2-1.4-1.1-2.6-2.4-3.2l-4.2-1.8c.9-1.1 1.4-2.6 1.4-4.1C18.1 2.9 15.3 0 12 0S5.9 2.9 5.9 6.5c0 1.6.5 3 1.4 4.1l-4.2 1.8C1.8 13 .9 14.2.7 15.6L0 20.9c-.1 1.1.6 2.1 1.7 2.2 1.8.3 6.4.9 10.3.9s8.5-.6 10.3-.9c1.1-.2 1.8-1.2 1.7-2.2l-.7-5.3zM12 13c1.1 0 2.2-.3 3.1-.9l.4.2c-1.4 1.6-2.8 3.2-3.5 3.6-.7-.4-2.1-2-3.5-3.6l.4-.2c.9.6 2 .9 3.1.9zm0-11c2.2 0 4 2 4 4.5 0 1.4-.6 2.7-1.5 3.5-.7.6-1.6 1-2.5 1s-1.8-.4-2.5-1C8.6 9.2 8 7.9 8 6.5 8 4 9.8 2 12 2zm0 20c-3.8 0-8.2-.6-10-.8l.7-5.3c.1-.7.6-1.3 1.2-1.6l2.7-1.1c4 4.9 5 4.9 5.4 4.9s1.4 0 5.4-4.9l2.7 1.1c.7.3 1.1.9 1.2 1.6l.7 5.3c-1.8.2-6.2.8-10 .8zm1-2c0 .6-.5 1-1 1-.6 0-1-.4-1-1s.5-1 1-1c.6 0 1 .4 1 1z" />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Agent
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className>
                <Link
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                  to="/compare-rates"
                >
                  <svg className="SvgIcon money-bag size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.7 6.5a1 1 0 011-1h6.522a1 1 0 110 2H8.7a1 1 0 01-1-1z"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.973 9.692C6.653 7.414 9.113 5.5 12 5.5c2.886 0 5.347 1.914 7.027 4.192 1.689 2.29 2.773 5.186 2.773 7.58 0 2.518-1.2 4.271-3.086 5.336C16.902 23.63 14.5 24 12 24s-4.902-.37-6.714-1.392C3.4 21.543 2.2 19.79 2.2 17.272c0-2.394 1.084-5.29 2.773-7.58zm1.61 1.187C5.085 12.91 4.2 15.4 4.2 17.272c0 1.75.77 2.861 2.069 3.594C7.642 21.64 9.64 22 12 22s4.358-.36 5.731-1.134c1.299-.733 2.069-1.843 2.069-3.594 0-1.874-.886-4.364-2.382-6.393C15.913 8.84 13.974 7.5 12 7.5c-1.974 0-3.913 1.338-5.418 3.38z"
                      />
                      <path d="M12 13.742a1.365 1.365 0 01-1.375-1.375c0-.777.617-1.375 1.375-1.375.723 0 1.317.543 1.37 1.266.006.061.052.109.112.109h.69a.118.118 0 00.117-.121 2.28 2.28 0 00-1.83-2.126v-.847a.115.115 0 00-.115-.115h-.688a.115.115 0 00-.114.115v.847a2.28 2.28 0 00-1.834 2.247A2.283 2.283 0 0012 14.658c.758 0 1.375.599 1.375 1.375 0 .777-.617 1.375-1.375 1.375a1.364 1.364 0 01-1.37-1.267.113.113 0 00-.112-.108h-.69a.118.118 0 00-.116.121 2.279 2.279 0 001.83 2.125v.848c0 .063.051.115.114.115h.688a.115.115 0 00.114-.115v-.848a2.28 2.28 0 001.834-2.246A2.283 2.283 0 0012 13.742z" />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.292 9.273c0-.201.163-.365.364-.365h.688c.201 0 .364.164.364.365v.651a2.529 2.529 0 011.83 2.309.367.367 0 01-.367.384h-.689a.363.363 0 01-.36-.34A1.114 1.114 0 0012 11.242c-.622 0-1.125.488-1.125 1.125 0 .636.503 1.125 1.125 1.125 1.4 0 2.542 1.12 2.542 2.541a2.53 2.53 0 01-1.834 2.442v.652a.365.365 0 01-.364.365h-.688a.365.365 0 01-.364-.365v-.651a2.528 2.528 0 01-1.83-2.309.368.368 0 01.367-.384h.689c.205 0 .347.164.36.34A1.114 1.114 0 0012 17.158c.622 0 1.125-.489 1.125-1.125s-.503-1.125-1.125-1.125c-1.4 0-2.542-1.12-2.542-2.541a2.53 2.53 0 011.834-2.443v-.651zm.5.135v.917l-.201.04a2.03 2.03 0 00-1.633 2.002c0 1.142.915 2.041 2.042 2.041.894 0 1.625.709 1.625 1.625 0 .917-.731 1.625-1.625 1.625-.81 0-1.487-.582-1.606-1.375h-.42c.105.884.765 1.58 1.617 1.751l.2.04v.918h.417v-.918l.201-.04a2.03 2.03 0 001.633-2A2.033 2.033 0 0012 13.991a1.615 1.615 0 01-1.625-1.625c0-.917.731-1.625 1.625-1.625.81 0 1.487.581 1.606 1.375h.42a2.029 2.029 0 00-1.617-1.752l-.2-.04v-.917h-.417zm-1.83 6.732v.002-.002zM6.213 0h11.726l-2.825 6.356-1.828-.812L14.861 2H8.987L10.2 5.634 8.3 6.266 6.213 0z"
                      />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Mortgage
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
              <li className>
                <Link
                  className="MenuItem"
              
                  to="/careers"
                >
                  <svg className="SvgIcon suitcase size-medium menuIcon rfSvg fill-link-black">
                    <svg viewBox="0 0 24 24">
                      <path d="M23.111 21.777H2.89v-5.195a5.153 5.153 0 01-1.445-1.113v6.308c0 .413.153.808.424 1.1.27.292.638.456 1.02.456h20.223c.383 0 .75-.164 1.021-.456.271-.292.424-.687.424-1.1V15.47a4.89 4.89 0 01-1.445 1.105v5.203z" />
                      <path d="M18.055 17.423a.697.697 0 00.511-.228.81.81 0 00.212-.55V12.4h-1.445V14h-7.222v1.555h7.222v1.09a.81.81 0 00.212.55c.135.145.319.227.51.227z" />
                      <path d="M23.833 4.666h-6.5v-1.26a1.953 1.953 0 00-.53-1.319 1.683 1.683 0 00-1.24-.532h-5.127a1.683 1.683 0 00-1.24.532 1.954 1.954 0 00-.53 1.32v1.26h-6.5a.697.697 0 00-.51.227.81.81 0 00-.212.55v6.222c0 1.032.38 2.02 1.058 2.75.677.73 1.596 1.14 2.554 1.14h2.166v1.088a.81.81 0 00.212.55c.135.146.319.228.51.228a.697.697 0 00.511-.228.81.81 0 00.212-.55v-4.247H7.222V14H5.056a2.091 2.091 0 01-1.533-.684 2.428 2.428 0 01-.634-1.65V6.222H23.11v5.444c0 .62-.228 1.213-.634 1.65a2.091 2.091 0 01-1.532.684h-.723v1.555h.723c.957 0 1.876-.41 2.553-1.139a4.046 4.046 0 001.058-2.75V5.444a.809.809 0 00-.212-.55.697.697 0 00-.51-.228zm-7.944 0H10.11v-1.22a.362.362 0 01.1-.238.313.313 0 01.225-.097h5.135a.29.29 0 01.12.022.31.31 0 01.102.07.336.336 0 01.096.234v1.23z" />
                    </svg>
                  </svg>
                  <div className="itemTitle">
                    Be a Redcoral Agent
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
          
              <li className>
                <Link
                  className="MenuItem"
                  target="_top"
                  data-rf-test-name
                  to="tel:1-312-278-3509"
                >
                  <div className="itemTitle">
                    1-312-278-3509
                    <span className="itemSubtitle" />
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </Box>
  );
}

export default NavMenu;
