import React from 'react'
import { Link } from "react-router-dom";
import g from "./Images/g.png"
import h from "./Images/h.png"
import i from "./Images/i.png"
import j from "./Images/j.png"
import k from "./Images/k.png"
import italy from "./Images/italy.png"
import france from "./Images/france.png"
import dubai from "./Images/dubai.jpg"

function Footer() {
  return (
    <div>
         <div className="SiteFooter main-content">
          
              <div className="footerContent">
                <div className="flex flex-column col-12 col-md-4 col-lg-3 col-xl-2 linkSection links">
                  <p className="linkHeading">Resources</p>
                  <div className="LinkItem">
                    <Link to="/us-housing-market" role="button" tabIndex={0}>
                      Housing Market
                    </Link>
                  </div>
                  <div className="LinkItem">
                    <Link to="/classes-and-event" role="button" tabIndex={0}>
                      Classes and Event
                    </Link>
                  </div>
                  <div className="LinkItem">
                    <Link to="/real-estate-agent" role="button" tabIndex={0}>
                      Real Estate Agent
                    </Link>
                  </div>
                  <div className="findHomesFaster">
                    <p className="linkHeading">Find homes faster</p>
                    <div className="LinkItem">
                      <Link to="#" role="button" tabIndex={0}>
                        <img
                          className="appBadge"
                          src={h}
                          alt="Download the redcoral App on the Apple App Store"
                        />
                      </Link>
                    </div>
                    <div className="LinkItem">
                      <Link role="button" tabIndex={0}>
                        <img
                          className="appBadge"
                          src={g}
                          alt="Download the redcoral App from the Google Play Store"
                        />
                      </Link>
                    </div>

                 
                  </div>
                </div>
                <div className="flex flex-column col-12 col-md-2 col-lg-2 linkSection links">
                  <p className="linkHeading">Quick Links</p>
                  <div className="LinkItem">
                    <Link to="/buy" role="button" tabIndex={0}>
                      Home for Sale
                    </Link>
                  </div>
                  <div className="LinkItem">
                    <Link to="/condos" role="button" tabIndex={0}>
                      Condos for Sale
                    </Link>
                  </div>
                  <div className="LinkItem">
                    <Link to="/land" role="button" tabIndex={0}>
                      Land for Sale
                    </Link>
                  </div>
              
                </div>
                <div className="flex flex-column col-12 col-md-2 col-lg-2 linkSection">
                  <p className="linkHeading">Find us</p>
               
                  <div className="socialAndCountry">
                    <ul className="footerSocialButtons inlineList">
                      <li id="footerFacebookIcon">
                        <Link
                          className="icon icon-facebook"
                          target="_blank"
                          rel="noopener"
                          to="https://www.facebook.com/Red Coral Real Estate"
                          title="redcoral Facebook"
                          aria-label="redcoral Facebook"
                          alt="redcoral Facebook"
                        >
                          <svg className="SvgIcon facebook">
                            <svg viewBox="0 0 24 24">
                              <path
                                d="M21.252 1.6H2.747c-.633 0-1.147.514-1.147 1.148v18.504c0 .634.514 1.148 1.147 1.148h9.963v-8.055H9.999v-3.139h2.71V8.891c0-2.686 1.641-4.15 4.038-4.15 1.148 0 2.134.086 2.422.125v2.807h-1.662c-1.304 0-1.556.62-1.556 1.529v2.004h3.109l-.405 3.14h-2.704V22.4h5.3c.635 0 1.149-.514 1.149-1.148V2.748c0-.634-.514-1.148-1.148-1.148"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </Link>
                      </li>
                      <li id="footerTwitterIcon">
                        <Link
                          className="icon icon-twitter"
                          target="_blank"
                          rel="noopener"
                          to="https://twitter.com/redcoral"
                          title="redcoral Twitter"
                          aria-label="redcoral Twitter"
                          alt="redcoral Twitter"
                        >
                          <svg className="SvgIcon twitter">
                            <svg viewBox="0 0 25 24">
                              <path
                                d="M21.863 6.828c.015.212.015.424.015.636 0 6.464-4.92 13.913-13.913 13.913-2.77 0-5.343-.802-7.508-2.195.393.045.772.061 1.18.061a9.78 9.78 0 006.07-2.09 4.896 4.896 0 01-4.571-3.391c.302.046.605.076.923.076.439 0 .878-.061 1.287-.167a4.89 4.89 0 01-3.92-4.799v-.061c.65.364 1.408.59 2.209.621a4.888 4.888 0 01-2.178-4.071c0-.909.24-1.741.664-2.468a13.904 13.904 0 0010.084 5.116 5.426 5.426 0 01-.121-1.12A4.886 4.886 0 0116.972 2c1.408 0 2.68.59 3.573 1.544a9.593 9.593 0 003.103-1.181 4.868 4.868 0 01-2.15 2.695 9.789 9.789 0 002.816-.757 10.522 10.522 0 01-2.451 2.527z"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </Link>
                      </li>
                      <li id="footerPinterestIcon">
                        <Link
                          className="icon icon-pinterest"
                          target="_blank"
                          rel="noopener"
                          to="https://www.pinterest.com/Red Coral Real Estate/"
                          title="redcoral Pinterest"
                          aria-label="redcoral Pinterest"
                          alt="redcoral Pinterest"
                        >
                          <svg className="SvgIcon pinterest">
                            <svg viewBox="0 0 24 24">
                              <path
                                d="M11.865 22.13c-1.015 0-1.978-.148-2.912-.429.387-.613.828-1.402 1.042-2.19 0 0 .118-.456.72-2.82.347.68 1.389 1.28 2.5 1.28 3.3 0 5.545-3.005 5.545-7.042 0-3.033-2.577-5.88-6.508-5.88-4.865 0-7.324 3.502-7.324 6.414 0 1.764.668 3.343 2.099 3.932.229.093.44 0 .51-.269.051-.174.157-.628.211-.815.067-.268.04-.347-.147-.574-.414-.495-.682-1.123-.682-2.02 0-2.592 1.94-4.917 5.054-4.917 2.752 0 4.276 1.683 4.276 3.944 0 2.951-1.31 5.451-3.261 5.451-1.071 0-1.872-.882-1.618-1.978.308-1.296.91-2.7.91-3.636 0-.84-.457-1.55-1.39-1.55-1.097 0-1.979 1.137-1.979 2.661 0 0 0 .976.335 1.629-1.125 4.759-1.325 5.589-1.325 5.589-.187.774-.2 1.643-.173 2.365a10.267 10.267 0 01-6.148-9.41C1.6 6.197 6.199 1.6 11.865 1.6S22.13 6.197 22.13 11.865 17.53 22.13 11.865 22.13"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </Link>
                      </li>
                      <li id="footerInstagramIcon">
                        <Link
                          className="icon icon-instagram"
                          target="_blank"
                          rel="noopener"
                          to="https://www.instagram.com/redcoralrealestate/"
                          title="redcoral Instagram"
                          aria-label="redcoral Instagram"
                          alt="redcoral Instagram"
                        >
                          <svg className="SvgIcon instagram">
                            <svg viewBox="0 0 24 24">
                              <path
                                d="M12 1c-2.987 0-3.362.013-4.535.066-1.171.054-1.971.24-2.67.511a5.412 5.412 0 00-1.949 1.269 5.412 5.412 0 00-1.269 1.949c-.271.699-.457 1.499-.511 2.67C1.013 8.638 1 9.013 1 12s.013 3.362.066 4.535c.054 1.171.24 1.971.511 2.67a5.412 5.412 0 001.269 1.949 5.412 5.412 0 001.949 1.269c.699.271 1.499.457 2.67.511C8.638 22.987 9.013 23 12 23s3.362-.013 4.535-.066c1.171-.054 1.971-.24 2.67-.511a5.412 5.412 0 001.949-1.269 5.412 5.412 0 001.269-1.949c.271-.699.457-1.499.511-2.67.053-1.173.066-1.548.066-4.535s-.013-3.362-.066-4.535c-.054-1.171-.24-1.971-.511-2.67a5.412 5.412 0 00-1.269-1.949 5.412 5.412 0 00-1.949-1.269c-.699-.271-1.499-.457-2.67-.511C15.362 1.013 14.987 1 12 1m0 1.982c2.937 0 3.285.011 4.445.064 1.072.049 1.655.228 2.042.379.514.199.88.438 1.265.823.385.385.624.751.823 1.265.151.387.33.97.379 2.042.053 1.16.064 1.508.064 4.445 0 2.937-.011 3.285-.064 4.445-.049 1.072-.228 1.655-.379 2.042-.199.514-.438.88-.823 1.265a3.398 3.398 0 01-1.265.823c-.387.151-.97.33-2.042.379-1.16.053-1.508.064-4.445.064-2.937 0-3.285-.011-4.445-.064-1.072-.049-1.655-.228-2.042-.379a3.398 3.398 0 01-1.265-.823 3.398 3.398 0 01-.823-1.265c-.151-.387-.33-.97-.379-2.042-.053-1.16-.064-1.508-.064-4.445 0-2.937.011-3.285.064-4.445.049-1.072.228-1.655.379-2.042.199-.514.438-.88.823-1.265a3.398 3.398 0 011.265-.823c.387-.151.97-.33 2.042-.379 1.16-.053 1.508-.064 4.445-.064zm0 3.37a5.649 5.649 0 100 11.297 5.649 5.649 0 000-11.297zm0 9.315a3.667 3.667 0 110-7.335 3.667 3.667 0 010 7.335zm7.192-9.539a1.32 1.32 0 11-2.64 0 1.32 1.32 0 012.64 0z"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </Link>
                      </li>
                    </ul>
                    <div className="countrySelector">
                      <p className="linkHeading">Countries</p>
                      <p className="country">
                        <span>
                          <img
                            className="flag"
                            src={i}
                            alt="US flag"
                            loading="lazy"
                          />
                          United States
                        </span>
                      </p>
                      <p className="country">
                        <div>
                          <img
                            className="flag"
                            src={j}
                            alt="Canadian flag"
                            loading="lazy"
                          />
                          Canada
                        </div>


                      </p>

                      <p className="country">
                        <div>
                          <img
                            className="flag"
                            src={dubai}
                            alt="Canadian flag"
                            loading="lazy"
                          />
                          Dubai
                        </div>

                        
                      </p>

                      <p className="country">
                        <div>
                          <img
                            className="flag"
                            src={italy}
                            alt="Canadian flag"
                            loading="lazy"
                          />
                          Italy
                        </div>

                        
                      </p>




                      <p className="country">
                        <div>
                          <img
                            className="flag"
                            src={france}
                            alt="Canadian flag"
                            loading="lazy"
                          />
                          france
                        </div>

                        
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex flex-column col-12 col-md-4 col-lg-5 col-xl-6">
                  <div className="legal">
                    <p className="copyright">
                      Copyright: © {/* */}2024{/* */} redcoral. All rights
                      reserved.
                    </p>
               
                
                    <p className="trademark">
                      redcoral and all redcoral variants, TITLE FORWARD, WALK SCORE,
                      and the R logos, are trademarks of redcoral Corporation,
                      registered or pending in the USPTO.
                    </p>
                    <p className="ca-dre">California DRE #01521930</p>
                
                    
                    <p className="helpReading">
                      If you are using a screen reader, or having trouble
                      reading this website, please call redcoral Customer Support
                      for help at{" "}
                      <Link className="phoneNumber" to="tel:+1802-379-9585">
                        +1802-379-9585
                      </Link>
                      .
                    </p>
                    <p className="fairHousingPolicyBold">
                      <span className="eho">
                        <Link
                          to="#"
                          target="_blank"
                          rel="noopener"
                        >
                          <img
                            className="icon ehoLogo"
                            src={k}
                            alt="Equal Housing Opportunity"
                            loading="lazy"
                          />
                        </Link>
                      </span>
                      <span>
                        redcoral IS COMMITTED TO AND ABIDES BY THE FAIR HOUSING
                        ACT AND EQUAL OPPORTUNITY ACT. READ redcoral'S{" "}
                        <a href="https://dos.ny.gov/system/files/documents/2021/08/fairhousingnotice.pdf" targt="_blank">
                          NEW YORK STATE FAIR HOUSING NOTICE
                        </a>
                        .
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Footer