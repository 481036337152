export const data = [
    {
      "image1": "https://ssl.cdn-redfin.com/photo/92/bigphoto/455/2242455_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/455/genMid.2242455_5_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/455/genMid.2242455_17_0.jpg",
      "price1": "$1,350,000",
      "price 2": "Est. $9,606/mo",
      "address 1": "TBD Surrey Dr",
      "address2": "Round Rock, TX 78664",
      "size": "2.12 acres",
      "listed by": "Christopher Smith",
      "lister company": "Keller Williams Realty",
      "about": "Unbelievably unique property in the heart of Round Rock. This lot is build ready with desirable lot sizes in an extremely quiet neighborhood. See attached plans. The lot is fully cleared, well maintained, and has abundant mature live oak, red oak, and pecan trees. Utilities are available at the road and Spectrum has agreed to add their services at no cost during development. Conveniently located just off of I35 & 45 behind the Dell facility and the new Switch Data Center that is under development. Fantastic location for Dell and Switch employees for rentals or purchase. Less than a mile to HEB, Target, Sam's Club, Home Depot, Lowe's, restaurants, retail, and downtown Round Rock. * Subject to final approval; In final plat approval with the city of Round Rock *",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/92/bigphoto/849/3785849_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/849/genMid.3785849_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/849/genMid.3785849_4_0.jpg",
      "price1": "$165,000",
      "price 2": "Est. $1,153/mo",
      "address 1": "607 S Lake Creek Dr",
      "address2": "Round Rock, TX 78681",
      "size": "0.33 acres",
      "listed by": "Michael Loredo",
      "lister company": "Loredo Realty",
      "about": "Survey available. Appraisal available. No HOA. Backs up to creek",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/92/bigphoto/588/5549588_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/588/genMid.5549588_3_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/92/mbpaddedwide/588/genMid.5549588_10_0.jpg",
      "price1": "$575,000",
      "price 2": "Est. $4,022/mo",
      "address 1": "580 Whisper Ln,",
      "address2": "Leander, TX 78640",
      "size": "3.55 acres",
      "listed by": "Diana Caro",
      "lister company": "Keller Williams Realty-RR WC",
      "about": "Great building site! Secluded lot with many large trees. This acreage is on a private gated road located in Leander city limits, feeding into renowned Leander ISD. Truly a SPECIAL piece of land with a tremendous trees, and enough space to add other structures if needed. This lot is a setback from the road to keep life quiet, secluded, and private. A PERFECT spot in a secure, gated community to build your \"forever home. \" Also unique is the LOCATION. This is located with quick and easy access to Georgetown, Liberty Hill, I-35, Ronald Reagan (Parmer Lane), and 183. Here's your chance to be that home back in the woods where you're not seen from the road and enjoy the BEST that the Texas Hill Country has to offer.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/513/1683513_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/513/genMid.1683513_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/513/genMid.1683513_4_0.jpg",
      "price1": "$50,000",
      "price 2": "Est. $478/mo",
      "address 1": "2710 Irwin Dr",
      "address2": "San Antonio, TX 78221",
      "size": "8,407 sq ft",
      "listed by": "Naomi Morrow",
      "lister company": "RE/MAX Realty Advantage",
      "about": "Clear Lot on .193 Acres off of Rigsby Ave. Perfect for Building your forever home. Near Milam Wesley Tealer Park, And the best Taqueria's!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/144/1683144_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/144/genMid.1683144_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/144/genMid.1683144_7_0.jpg",
      "price1": "$149,990",
      "price 2": "Est. $1,243/mo",
      "address 1": "7707 Windmill Hill St",
      "address2": "San Antonio, TX 78229",
      "size": "8,276 sq ft",
      "listed by": "Alejandro Espada",
      "lister company": "Vortex Realty",
      "about": "Very nice lot in a pristine location, gated community, last lot in this subdivision",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/798/genMid.1682798_1_0.jpg",
      "image2": "",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/798/genMid.1682798_3_0.jpg",
      "price1": "$80,000",
      "price 2": "Est. $694/mo",
      "address 1": "719 Pecan Valley Dr,",
      "address2": "San Antonio, TX 78220",
      "size": "0.44 acres",
      "listed by": "Paul Mitchell",
      "lister company": "Heritage Group, REALTORS",
      "about": "Great area for new builds. Owners are also selling (711 Pecan Valley) lot and commercial building on the corner (703 Pecan Valley).",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/391/1682391_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/391/genMid.1682391_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/391/genMid.1682391_6_0.jpg",
      "price1": "$70,500",
      "price 2": "Est. $509/mo",
      "address 1": "120 Pendleton Ave",
      "address2": "San Antonio, TX 78203",
      "size": "6,272 sq ft",
      "listed by": "Susan Bowman",
      "lister company": "Nix Realty Company",
      "about": "This residential vacant property, contains 2 lots, Lot 284 & Lot 285, with mature trees in the back, is the perfect spot for building your dream home or investment property. Located south of downtown with easy access to I35 and I10, the Collins Garden neighborhood is undergoing a major revitalization. Homes throughout this area are being upgraded and remolded. Minutes from Blue Star Art Complex, Downtown, fine dining and more.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/325/1682325_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/325/genMid.1682325_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/325/genMid.1682325_5_0.jpg",
      "price1": "$167,500",
      "price 2": "Est. $1,320/mo",
      "address 1": "23023 Linwood Rdg",
      "address2": "San Antonio, TX 78255",
      "size": "0.68 acres",
      "listed by": "Juan Medina",
      "lister company": "AE Realty",
      "about": "Build your Dream Home here!!!! Huge Corner Lot with Stunning Hill Country Views!! Site is nicely treed with a spectacular site for your dream home with World Class Views! Gated Community that was the site of the 2017 Parade of Homes- close to La Cantera, The Rim and fine dining and shopping. No city taxes and underground utilities in a neighborhood with exquisite homes! 15 minutes from Downtown San Antonio!!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/311/1682311_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/311/genMid.1682311_2_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/311/genMid.1682311_7_0.jpg",
      "price1": "$147,821",
      "price 2": "Est. $1,100/mo",
      "address 1": "8470 Sierra Hermosa",
      "address2": "San Antonio, TX 78255-3374",
      "size": "8,145 sq ft",
      "listed by": "Florence Holder",
      "lister company": "Keller Williams Heritage",
      "about": "Build your dream home on this great lot backing up to easement for additional privacy. The Vistas of Sonoma is a gated community with scenic canyon and beautiful city views. It offers easy access to shopping, hospitals and education centers. In close proximity to The Palmer Course and Cedar Creek Golf Course for the golf enthusiasts. Located in the Northside ISD and No city taxes! BUYERS, BUILDERS AND INVESTORS welcome! Lot Dimensions approx. Listing information is deemed reliable but is not guaranteed and must be independently verified through personal inspection and/or with the appropriate sources and professionals by buyer, or buyer's agents and representatives.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/657/1653657_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/657/genMid.1653657_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/657/genMid.1653657_3_0.jpg",
      "price1": "$125,000",
      "price 2": "Est. $924/mo",
      "address 1": "2109 Virginia Blvd",
      "address2": "San Antonio, TX 78202",
      "size": "0.26 acres",
      "listed by": "Lance Duran",
      "lister company": "Real",
      "about": "Rare, vacant, cleared lot in Denver Heights. Zoned RM-4 for a builder interested in developing urban townhomes.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/856/1656856_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/856/genMid.1656856_4_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/856/genMid.1656856_12_1.jpg",
      "price1": "$179,000",
      "price 2": "Est. $1,271/mo",
      "address 1": "24590 Open Range Rd,",
      "address2": "San Antonio, TX 78264-4513",
      "size": "2.50 acres",
      "listed by": "Rodolfo Martinez",
      "lister company": "Premier Realty Group",
      "about": "ATTENTION EVERYONE*** LOOKING FOR SPACE AND SECLUSION!! LOOK NO FURTHER!!! 2.5 acre lot Cleared and Level!!!No Restrictions!! PERFECT FOR WORK VEHICLES, SEMI'S OR 18 WHEELERS!! It is fully fenced and utilities are in place. Water, Electric and Septic already available!!! Water meter located at front left of property. Septic is in place!!! Looking to put a mobile home or manufactured housing? This is for you!! Land has a pad ready for mobile home!!! Please come see it today!!!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/604/1654604_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/604/genMid.1654604_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/604/genMid.1654604_6_0.jpg",
      "price1": "$25,000",
      "price 2": "Est. $192/mo",
      "address 1": "243 Coyol St",
      "address2": "San Antonio, TX 78201",
      "size": "5,662 sq ft",
      "listed by": "Juan Sanchez",
      "lister company": "Redbird Realty LLC",
      "about": "Great lot near Our Lady of The Lake University. Lot is flat and gated.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/166/1654166_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/166/genMid.1654166_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/166/genMid.1654166_4_0.jpg",
      "price1": "$68,000",
      "price 2": "Est. $581/mo",
      "address 1": "5853 Midcrown Dr",
      "address2": "San Antonio, TX 78217",
      "size": "0.49 acres",
      "listed by": "Yvonne Hernandez",
      "lister company": "eXp Realty",
      "about": "Spacious corner lot ready for you to build on!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/234/1682234_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/234/genMid.1682234_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/234/genMid.1682234_3_0.jpg",
      "price1": "$200,000",
      "price 2": "Est. $1,352/mo",
      "address 1": "705 Marshall St",
      "address2": "San Antonio, TX 78212-4941",
      "size": "6,534 sq ft",
      "listed by": "Anthony Melos",
      "lister company": "Texas USA Realty",
      "about": "Location 6,550 Square Feet Empty Lot in Five Points. Builders / Investors Dream !!! Fantastic views of downtown San Antonio. Short distance to many venues, shopping, and entertainment centers. Easy access to major freeways. New construction surrounding the area. Buy now and Build later. The opportunities are endless !!!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/550/1680550_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/550/genMid.1680550_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/550/genMid.1680550_3_0.jpg",
      "price1": "$25,000",
      "price 2": "Est. $181/mo",
      "address 1": "1134 Elvira St",
      "address2": "San Antonio, TX 78207-5350",
      "size": "2178 sq ft",
      "listed by": "Ramondre Posten",
      "lister company": "DFW Elite Living",
      "about": "great lot to build your first home on or for investment go and show today",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/029/1679029_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/029/genMid.1679029_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/029/genMid.1679029_4_0.jpg",
      "price1": "$70,000",
      "price 2": "Est. $450/mo",
      "address 1": "5030 Driskill St",
      "address2": "San Antonio, TX 78227",
      "size": "7501 sq ft",
      "listed by": "Cynthia Luna",
      "lister company": "eXp Realty",
      "about": "Ready to build your dream home on this nice lot in a quiet neighborhood close to Ingram Mall, and Bandera Road. This lot has been cleared, its fenced in with a gate. There is a utility pole located on the property ready for you to start building. Plat Diagram and Soil Sample has been done on the property. 16X20 Storage shed (built by the owner) is on the property and will convey. It sits on Cinder blocks, not affixed to the land. Owner will NOT consider seller financing.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/987/1677987_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/987/genMid.1677987_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/987/genMid.1677987_3_0.jpg",
      "price1": "$160,000",
      "price 2": "Est. $1,187/mo",
      "address 1": "10006 Ivory Cyn",
      "address2": "San Antonio, TX 78255",
      "size": "0.59 acres",
      "listed by": "John Alaniva",
      "lister company": "Kimberly Howell Properties",
      "about": "VIEW LOT WITH PRIVACY ON TWO SIDES OF FUTURE HOME AND VIEWS! SECURITY GATES / BEAUTIFUL COMMUNITY IN VERY SOUGHT AFTER COMMUNITY AND AREA IN SAN ANTONIO!",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/744/1677744_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/744/genMid.1677744_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/744/genMid.1677744_3_0.jpg",
      "price1": "$260,000",
      "price 2": "Est. $1,964/mo",
      "address 1": "0 Trumbo Rd",
      "address2": "San Antonio, TX 78263",
      "size": "5.00 acres",
      "listed by": "Arianne Villanueva",
      "lister company": "Teifke Real Estate",
      "about": "Located in the Southside of town!! Beautiful, cleared 5 acres divided into 2 (2.5 acre each) parcels (one behind the other). Entirely fenced and located less than half a mile from W Loop 1604 and only 5 minutes from U.S. HWY 281 South. Cleared and ready for you to built on! Truck drivers, conveniently park your 18 wheelers on this lot, IH 35 South located only 13 miles straight using 1604. No HOA, utilities available at the street.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/395/1676395_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/395/genMid.1676395_3_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/395/genMid.1676395_9_1.jpg",
      "price1": "$145,000",
      "price 2": "Est. $1,074/mo",
      "address 1": "11010 Nina Rdg",
      "address2": "San Antonio, TX 78254",
      "size": "0.55 acres",
      "listed by": "Sasha Jam",
      "lister company": "JPAR San Antonio",
      "about": "Hill Country lot located in an exclusive gated community of Canyons at Scenic Loop. Fairly level lot, making it an ideal location to build your dream home. Situated in a great location with easy access to popular destinations such as La Cantera, the Medical Center, and The Rim. With its convenient location and beautiful surroundings, this Hill Country Lot is an excellent opportunity to create your perfect home in a highly desirable area.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/824/1605824_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/824/genMid.1605824_1_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/824/genMid.1605824_5_1.jpg",
      "price1": "$100,000",
      "price 2": "Est. $642/mo",
      "address 1": "11402 Sea Gull Ct,",
      "address2": "San Antonio, TX 78245",
      "size": "6,098 sq ft",
      "listed by": "Juan Sanchez",
      "lister company": "Keller Williams City-View",
      "about": "This Residential Lot does not include the House next door at 11406 Sea Gull Ct. *Same Owner different property *The appraisal district has some of the lot information incorrect, please confirm information and lot measurements with Bexar County Tax Office and their Correction Affidavit *Lot Size is .14 and there is a concrete slab established on this Lot *Great Opportunity to build a starter or investment home *Close to Sea World, 1604, shopping, grocery stores and Lackland AFB.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/098/1676098_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/098/genMid.1676098_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/098/genMid.1676098_3_0.jpg",
      "price1": "$125,000",
      "price 2": "Est. $824/mo",
      "address 1": "13315 Rockhampton St",
      "address2": "San Antonio, TX 78232",
      "size": "6141 sq ft",
      "listed by": "Pliny Mier",
      "lister company": "Kuper Sotheby's Int'l Realty",
      "about": "Great location and a wonderful opportunity to build a garden home in a good neighborhood close to Harry Wurzbach, 281, 35 and McCallister Park.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/538/1675538_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/538/genMid.1675538_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/538/genMid.1675538_3_0.jpg",
      "price1": "$75,000",
      "price 2": "Est. $607/mo",
      "address 1": "302 Belmont",
      "address2": "San Antonio, TX 78202",
      "size": "8450 sq ft",
      "listed by": "Rosie Hardway Thomas",
      "lister company": "Premier Realty Group",
      "about": "Vacant Lot nice size great area Historical",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/359/1675359_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/359/genMid.1675359_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/359/genMid.1675359_3_0.jpg",
      "price1": "$149,000",
      "price 2": "Est. $974/mo",
      "address 1": "606 Booker Aly",
      "address2": "San Antonio, TX 78202",
      "size": "3397 sq ft",
      "listed by": "Shannan Albrecht",
      "lister company": "Keller Williams Heritage",
      "about": "Here's your opportunity to be downtown, a short distance from the world famous Riverwalk, historic Hays Street Bridge, Alamodome, Tower of the Americas and so much more. Now's the time to build your dream home or investment property. Walking distance to Dignowity Park and convenient to some of the city's best dining and entertainment. Come see all that this vibrant community has to offer.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/429/1674429_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/429/genMid.1674429_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/429/genMid.1674429_3_0.jpg",
      "price1": "$45,000",
      "price 2": "Est. $417/mo",
      "address 1": "945 Rockwell Blvd",
      "address2": "San Antonio, TX 78224",
      "size": "0.44 acres",
      "listed by": "Genevieve Cavazos",
      "lister company": "JPAR San Antonio",
      "about": "Location right off 410 and minutes from the Toyota plant, Palo Alto college, and A&M University.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/988/1673988_1.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/988/genMid.1673988_1_1.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/988/genMid.1673988_3_1.jpg",
      "price1": "$85,000",
      "price 2": "Est. $650/mo",
      "address 1": "107 Cash Aly",
      "address2": "San Antonio, TX 78210",
      "size": "3393 sq ft",
      "listed by": "Megan Perez",
      "lister company": "Kuper Sotheby's Int'l Realty",
      "about": "Hidden lot on a hidden street. Steps away from the Alamodome and easy access to Downtown/Southtown/I-37. Build your home/workshop/garage/urban barndominium at this convenient near eastside location. Utilities available at the street.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/875/1673875_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/875/genMid.1673875_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/875/genMid.1673875_3_0.jpg",
      "price1": "$70,000",
      "price 2": "Est. $494/mo",
      "address 1": "1422 Sherman",
      "address2": "San Antonio, TX 78202-1556",
      "size": "4878 sq ft",
      "listed by": "Ramon Gonzalez",
      "lister company": "Vortex Realty",
      "about": "lot located within minutes from downtown and walking distance to park with access to playground and small soccer field.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/351/1672351_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/351/genMid.1672351_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/351/genMid.1672351_8_0.jpg",
      "price1": "$98,000",
      "price 2": "Est. $703/mo",
      "address 1": "3703 Colter Rd",
      "address2": "San Antonio, TX 78247",
      "size": "5314 sq ft",
      "listed by": "Janie Quesada",
      "lister company": "Riverside Realty, LLC",
      "about": "Limited opportunity, this is a rare find to build in a well-established neighborhood. The only lot available in Green Spring Valley has hit the market!!! This is a beautiful lot on the top of a hill sits on 0.122 of land and is ready for your dream home or investment property. Gorgeous views, location is prime! 2nd floor views of downtown. Lot is prepped with excavation, ready for you to build your dream home! Perfectly located near Loop 1604 and Highway 281, offering easy access to great shopping, dining, and entertainment",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/010/1672010_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/010/genMid.1672010_2_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/010/genMid.1672010_6_0.jpg",
      "price1": "$200,000",
      "price 2": "Est. $1,716/mo",
      "address 1": "23015 Linwood Rdg",
      "address2": "San Antonio, TX 78255",
      "size": "0.53 acres",
      "listed by": "Adam Rivera",
      "lister company": "Kuper Sotheby's Int'l Realty",
      "about": "Exceptional long-range views on this premier homesite in The Canyons at Scenic Loop. Ideally located 15 minutes from The Rim and La Cantera. No city taxes! Building plans are in the process of being completed and a Designer is available to modify the plans to suit your needs if desired.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/546/1670546_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/546/genMid.1670546_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/546/genMid.1670546_3_0.jpg",
      "price1": "$39,000",
      "price 2": "Est. $282/mo",
      "address 1": "318 N San Felipe Ave",
      "address2": "San Antonio, TX 78237",
      "size": "5749 sq ft",
      "listed by": "Jenny Monarez",
      "lister company": "Link'd Realty",
      "about": "Great opportunity to built your dream home in a great location that backs up to the green fields of Garcia Middle School! Just minutes from Saint Mary's University, Williams Elementary, Garcia Middle School and downtown San Antonio. Easy access to Highway 90 and Loop 410.",
      "state": "texas"
    },
    {
      "image1": "https://ssl.cdn-redfin.com/photo/108/bigphoto/512/1670512_0.jpg",
      "image2": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/512/genMid.1670512_1_0.jpg",
      "image3": "https://ssl.cdn-redfin.com/photo/108/mbpaddedwide/512/genMid.1670512_3_0.jpg",
      "price1": "$350,000",
      "price 2": "Est. $2,145/mo",
      "address 1": "306 W Ansley St",
      "address2": "San Antonio, TX 78220",
      "size": "0.29 acre",
      "listed by": "Othon Ruiz",
      "lister company": "Davalos & Associates",
      "about": "The property is .29 acres and will be sold with 4.76 acre lots MLS #1670508. These makes a great opportunity for a builder",
      "state": "texas"
    }
  ]