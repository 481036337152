import React, { useState } from 'react'
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu"
import { Box, Typography } from "@mui/material";
import logo from "../redcoral.png"



function Navbar2() {

  const [Show, setShow] = useState(false)
  const [show, setshow] = useState(false)
  const [show2, setshow2] = useState(false)
  const [show3, setshow3] = useState(false)
  const [show4, setshow4] = useState(false)
  const [show5, setshow5] = useState(false)
  return (
    <div   style={{position:"fixed", top:"0px", right:"0px", zIndex:"600", width:"100%" }}>
              <div id="header" data-rf-test-name="header" className>
                <div id="header-content">
                  <style
                    type="text/css"
                    dangerouslySetInnerHTML={{
                      __html:
                        "\n  #header header.LargeHeader { display: none; }\n  @media (min-width: 600px) {\n    #header header.LargeHeader { display: flex; }\n    #header header.SmallHeader { display: none; }\n  }",
                    }}
                  />

                 <div   >
                 <header
                    data-rf-test-id="small_header_content"
                    className="SmallHeader"
                    id="small_header_content"
                  
                  >
                    <div className="smallHeaderContent mobileFiltersHeader appNavigation">
                      <Link className="LogoR" to="/">
                       <img src={logo} style={{width:"10rem", height:"3rem"}} />
                      </Link>
                      <div
                        id="headerUnifiedSearch"
                        className="headerUnifiedSearch no-print"
                      >
                        <div
                          data-rf-test-name="search-box"
                          className="SearchBox"
                          tabIndex={-1}
                        >
                      
                          <noscript />
                        </div>
                      </div>
                      <div
                        data-rf-test-name="MobileNavMenuButton"
                        role="button"
                        tabIndex={0}
                        className="NavMenu logo-link clickable fill-link-black"
                        title="redcoral Navigation Menu"
                      >
                        <span className="mobileNavHamMenuButton hamburger" onClick={()=>{setShow(!Show)}}  >
                          <span className="Svg MenuNotification hamNavIcon">
                            <svg width={24} height={24} viewBox="0 0 24 24">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M3.25 13h17.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H3.25a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25zm0 5h17.5a.25.25 0 00.25-.25v-1.5a.25.25 0 00-.25-.25H3.25a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25z"
                              />
                              <circle cx={21} cy={6} r={3} fill="#C82021" />
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 6H3.25a.25.25 0 00-.25.25v1.5c0 .138.112.25.25.25h13.166A4.983 4.983 0 0116 6z"
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                    </div>



                { Show && <NavMenu />} 
                    <div className="mobileNavOverlay appNavigation" />
                  </header>
                  </div> 
                








                  <header
                    data-rf-test-name="large_header_content"
                    className="LargeHeader"
                  >
                    <div className="header-left can-shrink">
                      <div className="logo-div menuItemAlign verticallyCenterFlexContent">
                        <meta content="redcoral" />
                  
                        <div
                          className="logo-link clickable inheritColor fill-Red Coral Real Estates-red"
                          data-rf-test-name="LogoLink"
                          title="redcoral"
                          role="button"
                          aria-label="redcoral Homepage Link"
                          tabIndex={0}
                        >
                          <Link to="/">
                          <img src={logo} style={{width:"80%", height:"100%"}} />
                          </Link>
                       
                          <span className="Svg redcoralLogo">
                         
                          </span>
                        </div>
                      </div>
                      <div
                        id="headerUnifiedSearch"
                        className="headerUnifiedSearch"
                      >
                        <div
                          data-rf-test-name="search-box"
                          className="SearchBox"
                          tabIndex={-1}
                        >
              
                          <noscript />
                        </div>
                      </div>
                    </div>
                    <div className="header-right">
                      <div className="item Phone menuItemClickable menuItemAlign verticallyCenterFlexContent shouldHide">
                        <div className="contents hoverWrapper">
                          <div className="text menuItemClickable">
                            <Link
                              data-rf-test-name="Phone"
                              to="#"
                              title="Call redcoral Customer Service"
                            >
                              <span> </span>
                              <span className="phoneNumberDigits">
                                +1802-379-9585
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="menuItemClickable verticallyCenterFlexContent menuItemAlign clickable withHoverShim withHoverBoxShadow"
                        tabIndex={0}
                        role="button"
                        data-rf-test-name="BuyMenu"
                        style={{position:"relative"}}
                        onMouseEnter={()=>{setshow(!show)}}
                        onMouseLeave={()=>{setshow(!show)}}
                      >
                        <Link
                          className="inheritColor dropdownWidget"
                          to="#"
                          aria-expanded="false"
                          title="Buy Menu"
                          role="button"
                        >
                          Buy ▾
                        </Link>

                        { show && <Box sx={{position:"absolute", padding:"1.5rem 1rem", backgroundColor:"#fff", borderRadius:"3px", width:"38vw",zIndex:"100", top:"45px" }}>
                 
                 <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", }}>
                 <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                  <Link to="/buy">   <Typography sx={{color:"#333333", fontWeight:"500", fontSize:"18px"}}>New york Searches</Typography></Link>
                  <Link to="/buy"><Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Home for sale</Typography></Link>
                 
                  <Link to="/land"> <Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Land for sale</Typography></Link> 
                 
                  <Link to="/condos"> <Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Condos for sale</Typography></Link>
                    
                   
                   
                    
                   
                </Box>

                <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                  <Link to="/invest"><Typography sx={{color:"#333333",  fontWeight:"500", fontSize:"18px"}}>Buying Options</Typography></Link>
                  <Link to="/redcoral"> <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Buy with redcoral</Typography></Link>
                 
                     
                    
                    
                </Box>

                <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
              
               
                <Link to="/classes-and-event"><Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Free home buying classes</Typography></Link>
                <Link to="/us-housing-market"> <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Us housing market</Typography></Link>
                <Link to="/real-estate-agent"><Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Finding an agent</Typography></Link>
                    
                   
                    
                   
                    
                  
                </Box>

                 </Box>
              </Box>}
                      </div>
                      <div
                        className="menuItemClickable verticallyCenterFlexContent menuItemAlign clickable withHoverShim withHoverBoxShadow"
                        tabIndex={0}
                        role="button"
                        data-rf-test-name="RentMenu"
                        style={{position:"relative"}}
                        onMouseEnter={()=>{
                          setshow(false)
                          setshow3(false)
                          setshow2(!show2)}}
                        onMouseLeave={()=>{setshow2(!show2)}}
                      >
                        <Link
                          className="inheritColor dropdownWidget"
                          to="#"
                          aria-expanded="false"
                          title="Rent Menu"
                          role="button"
                        >
                          Rent{/* */} ▾
                        </Link>
                        { show2 && <Box sx={{position:"absolute", padding:"1.5rem 1rem", backgroundColor:"#fff", borderRadius:"3px", width:"15vw",zIndex:"100", top:"45px" }}>
                 
                 <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", }}>
                 <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>

                 <Link to="/rent">                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Apartment for rent</Typography>
</Link>
                 <Link to="/rent"> <Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>House for rent</Typography></Link>
                 <Link to="/us-housing-market"><Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Housing market</Typography></Link>

                 <Link to="/real-estate-agent"><Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Finding an agent</Typography></Link>
                 
             
                  
                   
                    
                   
                 
                </Box>

            

                {/* <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>
                     <Typography sx={{color:"#333333",  fontWeight:"500", fontSize:"18px"}}>Rental Resources</Typography>
                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>List my home for rent</Typography>
                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Rental market trends</Typography>
                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>How much rent can i afford</Typography>
                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Should i rent or buy</Typography>
                    <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Renter guide</Typography>
                  
                </Box> */}

                 </Box>
              </Box>}
                      </div>
                      <div
                        className="menuItemClickable verticallyCenterFlexContent menuItemAlign clickable withHoverShim withHoverBoxShadow"
                        tabIndex={0}
                        role="button"
                        data-rf-test-name="SellMenu"
                        style={{position:"relative"}}
                        onMouseEnter={()=>{
                          setshow2(false)
                          setshow4(false)
                          setshow3(!show3)}}
                        onMouseLeave={()=>{setshow3(!show3)}}
                      >
                        <Link
                          className="inheritColor dropdownWidget"
                          to="/#"
                          aria-expanded="false"
                          title="Sell Menu"
                          role="button"
                        >
                          Sell ▾
                        </Link>
                        
                      { show3 && <Box sx={{position:"absolute", padding:"1.5rem 1rem", backgroundColor:"#fff", borderRadius:"3px", width:"28vw",zIndex:"100", top:"45px" }}>
                 
                 <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", }}>
                 <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start", }}>

                 <Link to="/selling-options">   <Typography sx={{color:"#333333", fontWeight:"500", fontSize:"18px"}}>My Home</Typography></Link>
                 <Link to="/what-is-my-home-worth">  <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>What's my home worth</Typography></Link>
                 <Link to=""></Link>

                  
                  
                    
                   
                 
                </Box>

            

                <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>

                <Link to="/selling-options"> <Typography sx={{color:"#333333",  fontWeight:"500", fontSize:"18px"}}>Redcoral Selling Options</Typography></Link>
                <Link to="/compare-rates"><Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Compare my options</Typography></Link>
                <Link to="/partner"> <Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>redcoral full services</Typography></Link>
                <Link to="/real-estate-agent"><Typography sx={{color:"#333333", pt:"1rem",fontSize:"14px" }}>Find an agent </Typography></Link>
                
                    


                    
                   
                  
                </Box>

                 </Box>
              </Box>}
                      </div>
                      <a
                        className="topMenuPremierLink inline-block menuItemClickable verticallyCenterFlexContent menuItemAlign clickable"
                        href='https://projects.redcoralhomes.com/current/how-it-works/index.html'
                        title="redcoral Premier"
                        target='_blank'
                        role="button"
                        data-rf-test-name="TopPremierLink"
                      >
                        REITs Investment
                      </a>
                      <div
                        className="menuItemClickable verticallyCenterFlexContent menuItemAlign clickable withHoverShim withHoverBoxShadow"
                        tabIndex={0}
                        role="button"
                        data-rf-test-name="MortgageMenu"
                        onMouseEnter={()=>{
                          setshow3(false)
                          setshow5(false)
                          setshow4(!show4)}}
                        onMouseLeave={()=>{setshow4(!show4)}}

                      >
                        <Link
                          className="inheritColor dropdownWidget"
                          to="#"
                          aria-expanded="false"
                          title="Mortgage Menu"
                          role="button"
                        >
                          Mortgage ▾
                        </Link>
                        { show4 && <Box sx={{position:"absolute", padding:"1.5rem 1rem", backgroundColor:"#fff", borderRadius:"3px",zIndex:"100", top:"45px", width:"12vw" }}>
                 
                 <Box sx={{display:"flex", flexDirection:"row", width:"100%", justifyContent:"space-between", }}>
                 <Box sx={{display:"flex", flexDirection:"column", alignItems:"flex-start"}}>

                 <Link to="#">  <Typography sx={{color:"#333333", fontWeight:"500", fontSize:"18px"}}>Mortage</Typography></Link>
                 <Link to="/compare-rates"> <Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Total mortages rates</Typography></Link>
                 <Link to="/compare-rates">  <Typography sx={{color:"#333333", pt:"1rem", fontSize:"14px"}}>Payment Calculator</Typography></Link>

                   
                   
                  
                  
                 
                </Box>

            

            
                 </Box>
              </Box>}
                      </div>
                      <div
                        tabIndex={0}
                        role="button"
                        data-rf-test-name="RealEstateAgentsLink"
                        className="RealEstateAgentsLink inline-block menuItemClickable verticallyCenterFlexContent menuItemAlign clickable withHoverShim withHoverBoxShadow"
                        id="topMenuRealEstateAgentsLink"
                        aria-expanded="false"
                      >
                        <Link
                          className="menuItemClickable dropdownWidget"
                          to="/real-estate-agent"
                        >
                          Real Estate Agents 
                        </Link>
                      </div>
                
                      <div className="margin-horiz-medium">
                        <a
                          type="button"
                          className="button Button tertiary-alt compact headerMenuButton"
                          tabIndex={0}
                          role="button"
                       href='https://accounts.redcoralhomes.com/login.php'
                        >
                          <span>Log In</span>
                        </a>
                      </div>
                      <div className="joinLinkWrapper">
                        <a
                          type="button"
                          className="button Button tertiary compact headerMenuButton"
                          tabIndex={0}
                          role="button"
                          href='https://accounts.redcoralhomes.com/register.php'
                        >
                          <span>Sign Up</span>
                        </a>
                      </div>
                    </div>
                  </header>
                </div>
              </div>
    </div>
  )
}

export default Navbar2