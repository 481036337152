import React from "react";
import { Box, Grid } from "@mui/material";
import Map from "./Map";
import Navbar2 from "./Navbar2";
import { Link } from "react-router-dom";
import Details1 from "./Details1";

function Buy() {
    return (
      <Box>
        <Navbar2 />
  
        <Grid container  >
          <Grid item xs={12} sm={6} sx={{  }}>
            <Map />
          </Grid>
          <Grid item xs={12} sm={6} sx={{my:"4rem",  }}>
      
            <Details1 />
          </Grid>
        </Grid>
      </Box>
    );
  }
  
  export default Buy; 