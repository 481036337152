import React from "react";
import { Link } from "react-router-dom";
import Footer from "./Footer"
import Navbar2 from "./Navbar2"
import fullservice1 from "./Images/fullservice1.jpg"

function Selling2() {
  return (
    <div>
      <div>
      
      
        <div id="content" data-react-server-content>
          <div
            data-react-server-root-id={0}
            data-react-server-timing-offset={68}
          />
          <div
            data-react-server-root-id={1}
            data-react-server-timing-offset={81}
          >
         <Navbar2 />
          </div>
          <div
            data-react-server-root-id={2}
            data-react-server-timing-offset={84}
          />
          <div
            data-react-server-root-id={3}
            data-react-server-timing-offset={84}
          />
          <div
            data-react-server-root-id={4}
            data-react-server-timing-offset={84}
          />
          <div
            data-react-server-root-id={5}
            data-react-server-timing-offset={85}
          />
          <div
            data-react-server-root-id={6}
            data-react-server-timing-offset={87}
          >
     
          </div>
          <div
            data-react-server-container={7}
            className="SellerConsultationAddressPage"
          >
            <div
              data-react-server-root-id={8}
              data-react-server-timing-offset={92}
            >
           
            </div>
            <div
              data-react-server-root-id={9}
              data-react-server-timing-offset={98}
            >
              <div className="SellerConsultationSalePriceCalculatorSection gray-background">
                <div className="section-header">
                  <h1>See how much you could save with Red Coral Real Estates</h1>
                  <h2>
                    <span>
                      Sell with a Red Coral Real Estates Agent and pay a 1% listing fee when you
                      buy + sell. You'll get second-to-none service and save
                      thousands.
                    </span>
                    <Link
                      className="DisclaimerFlyoutAsterisk"
                      to="/disclaimer"
                      aria-label="disclaimer"
                    >
                      <svg className="SvgIcon asterisk size-tiny">
                        <svg viewBox="0 0 14 14">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M14 7A7 7 0 110 7a7 7 0 0114 0zM6.996 8.207c.038 0 .064.012.076.038l1.368 2.26a.187.187 0 00.171.096.509.509 0 00.247-.095l.798-.551c.127-.076.19-.159.19-.247 0-.038-.025-.095-.076-.171L8.174 7.599c-.038-.038-.057-.076-.057-.114a.122.122 0 01.095-.095l2.28-.684c.114-.026.171-.095.171-.21a.264.264 0 00-.018-.113l-.304-.95c-.026-.127-.09-.19-.19-.19a.292.292 0 00-.114.038l-2.28.988c-.039.012-.07.012-.096 0-.025-.013-.038-.038-.038-.076v-.038l.19-2.546a.182.182 0 00-.057-.133c-.025-.051-.076-.076-.152-.076H6.388a.205.205 0 00-.152.057c-.038.038-.057.076-.057.114v.038l.19 2.527c0 .088-.038.133-.114.133l-.076-.02-2.204-.93c-.126-.064-.221-.026-.285.114l-.36.969c-.064.19-.026.304.113.342l2.337.646c.076.012.114.05.114.114l-.038.076-1.672 2.052c-.05.05-.076.101-.076.152 0 .05.038.107.114.17l.912.647c.076.038.14.057.19.057.09 0 .152-.026.19-.076L6.92 8.245c.026-.026.051-.038.076-.038z"
                          />
                        </svg>
                      </svg>
                    </Link>
                  </h2>
                </div>
                <div className="SellerConsultationSalePriceCalculator">
                  <div className="title">Seller Savings</div>
                  <div className="redfinSavings">$7,500</div>
                  <div className="homePrice">
                    <span className="text">Home sale price</span>
                    <span className="value"> $750,000</span>
                  </div>
                  <div className="field TextAndSlider">
                    <span
                      data-rf-test-name="Text"
                      className="field text Text label-none optional value"
                    >
                      <span className="input">
                        <div role="presentation" className="value text">
                          <input
                            type="text"
                            name="value"
                            defaultValue={1225000}
                            placeholder=" "
                            inputMode
                            className="text"
                            data-rf-input-event-type="onInput"
                            data-rf-test-name="input"
                            tabIndex={0}
                            autoComplete="off"
                          />
                        </div>
                      </span>
                    </span>
                    <div className="NativeSlider two-tone-track webkit sublabel-none">
                      <div className="slider">
                        <div className="rendered">
                          <div className="left" style={{ width: "56.9444%" }} />
                          <div
                            className="right"
                            style={{ width: "43.0556%" }}
                          />
                          <div className="thumbWrap">
                            <div
                              className="thumb"
                              style={{ left: "56.9444%" }}
                            />
                          </div>
                        </div>
                        <input
                          type="range"
                          defaultValue={1225000}
                          max={2000000}
                          min={200000}
                          step={1000}
                        />
                      </div>
                    </div>
                    <input type="hidden" />
                  </div>
                  <div className="range">
                    <span className="value">$200,000</span>
                    <span className="value">$2,000,000</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-react-server-root-id={10}
              data-react-server-timing-offset={100}
            >
              <div style={{display:"flex", flexDirection:"row",justifyContent:"space-between", alignItems:"center"}}>
                <img
                  className="Red Coral Real Estates-agents"
                  src={fullservice1}
                  style={{width:"100%", height:"100%"}}
                />
                <div className="SellerConsultationComparisonTable">
                  <div className="comparisonSection">
                    <div className="comparisonTable">
                      <div className="tableHeader">
                        <h1>Compare Traditional vs. Red Coral Real Estates Agents</h1>
                      </div>
                      <div className="Comparison header">
                        <span className="label">
                          <div className="DefinitionFlyout definition-flyout-container react inline-block">
                            <span
                              className="DefinitionFlyoutLink inline-block clickable"
                              tabIndex={0}
                            >
                              <span className="definitionLabelText" />
                            </span>
                          </div>
                        </span>
                        <span className="value">Red Coral Real Estates</span>
                        <span className="value">Traditional</span>
                      </div>
                      <div className="Comparison ">
                        <span className="label">
                          <span>
                            <span>Listing fee</span>
                            <span className="middle-aligned">
                              <Link
                                className="DisclaimerFlyoutAsterisk"
                                to="/disclaimer"
                                aria-label="disclaimer"
                              >
                                <svg className="SvgIcon asterisk size-tiny">
                                  <svg viewBox="0 0 14 14">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M14 7A7 7 0 110 7a7 7 0 0114 0zM6.996 8.207c.038 0 .064.012.076.038l1.368 2.26a.187.187 0 00.171.096.509.509 0 00.247-.095l.798-.551c.127-.076.19-.159.19-.247 0-.038-.025-.095-.076-.171L8.174 7.599c-.038-.038-.057-.076-.057-.114a.122.122 0 01.095-.095l2.28-.684c.114-.026.171-.095.171-.21a.264.264 0 00-.018-.113l-.304-.95c-.026-.127-.09-.19-.19-.19a.292.292 0 00-.114.038l-2.28.988c-.039.012-.07.012-.096 0-.025-.013-.038-.038-.038-.076v-.038l.19-2.546a.182.182 0 00-.057-.133c-.025-.051-.076-.076-.152-.076H6.388a.205.205 0 00-.152.057c-.038.038-.057.076-.057.114v.038l.19 2.527c0 .088-.038.133-.114.133l-.076-.02-2.204-.93c-.126-.064-.221-.026-.285.114l-.36.969c-.064.19-.026.304.113.342l2.337.646c.076.012.114.05.114.114l-.038.076-1.672 2.052c-.05.05-.076.101-.076.152 0 .05.038.107.114.17l.912.647c.076.038.14.057.19.057.09 0 .152-.026.19-.076L6.92 8.245c.026-.026.051-.038.076-.038z"
                                    />
                                  </svg>
                                </svg>
                              </Link>
                            </span>
                          </span>
                        </span>
                        <span className="value">
                          1{/* */}%
                          <Link
                            className="DisclaimerFlyoutAsterisk"
                            to="/disclaimer"
                            aria-label="disclaimer"
                          >
                            <svg className="SvgIcon asterisk size-tiny">
                              <svg viewBox="0 0 14 14">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M14 7A7 7 0 110 7a7 7 0 0114 0zM6.996 8.207c.038 0 .064.012.076.038l1.368 2.26a.187.187 0 00.171.096.509.509 0 00.247-.095l.798-.551c.127-.076.19-.159.19-.247 0-.038-.025-.095-.076-.171L8.174 7.599c-.038-.038-.057-.076-.057-.114a.122.122 0 01.095-.095l2.28-.684c.114-.026.171-.095.171-.21a.264.264 0 00-.018-.113l-.304-.95c-.026-.127-.09-.19-.19-.19a.292.292 0 00-.114.038l-2.28.988c-.039.012-.07.012-.096 0-.025-.013-.038-.038-.038-.076v-.038l.19-2.546a.182.182 0 00-.057-.133c-.025-.051-.076-.076-.152-.076H6.388a.205.205 0 00-.152.057c-.038.038-.057.076-.057.114v.038l.19 2.527c0 .088-.038.133-.114.133l-.076-.02-2.204-.93c-.126-.064-.221-.026-.285.114l-.36.969c-.064.19-.026.304.113.342l2.337.646c.076.012.114.05.114.114l-.038.076-1.672 2.052c-.05.05-.076.101-.076.152 0 .05.038.107.114.17l.912.647c.076.038.14.057.19.057.09 0 .152-.026.19-.076L6.92 8.245c.026-.026.051-.038.076-.038z"
                                />
                              </svg>
                            </svg>
                          </Link>
                        </span>
                        <span className="value">3%</span>
                      </div>
                      <div className="Comparison ">
                        <span className="label">
                          <span>Full Service Agent</span>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon checkmark yes  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path d="M5.719 21.615L.2 14.992a.25.25 0 01.032-.352l1.536-1.28a.25.25 0 01.352.032l3.992 4.79L20.963 4.259a.25.25 0 01.353.011l1.368 1.46a.25.25 0 01-.011.353L6.082 21.637a.25.25 0 01-.363-.022" />
                            </svg>
                          </svg>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon checkmark yes  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path d="M5.719 21.615L.2 14.992a.25.25 0 01.032-.352l1.536-1.28a.25.25 0 01.352.032l3.992 4.79L20.963 4.259a.25.25 0 01.353.011l1.368 1.46a.25.25 0 01-.011.353L6.082 21.637a.25.25 0 01-.363-.022" />
                            </svg>
                          </svg>
                        </span>
                      </div>
                      <div className="Comparison ">
                        <span className="label">
                          <span>Premium Placement on Red Coral Real Estates</span>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon checkmark yes  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path d="M5.719 21.615L.2 14.992a.25.25 0 01.032-.352l1.536-1.28a.25.25 0 01.352.032l3.992 4.79L20.963 4.259a.25.25 0 01.353.011l1.368 1.46a.25.25 0 01-.011.353L6.082 21.637a.25.25 0 01-.363-.022" />
                            </svg>
                          </svg>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon close no no-warning-color  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <g fillRule="evenodd">
                                <path d="M21.105 4.134l-1.061-1.061a.252.252 0 00-.354 0l-7.601 7.602-7.602-7.602a.25.25 0 00-.353 0l-1.061 1.06a.252.252 0 000 .355l7.602 7.6-7.602 7.603a.25.25 0 000 .353l1.06 1.06a.25.25 0 00.354 0l7.602-7.601 7.6 7.602a.252.252 0 00.355 0l1.06-1.061a.25.25 0 000-.353l-7.601-7.602 7.602-7.601a.252.252 0 000-.354" />
                              </g>
                            </svg>
                          </svg>
                        </span>
                      </div>
                      <div className="Comparison ">
                        <span className="label">
                          <span>Free Professional Photos</span>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon checkmark yes  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path d="M5.719 21.615L.2 14.992a.25.25 0 01.032-.352l1.536-1.28a.25.25 0 01.352.032l3.992 4.79L20.963 4.259a.25.25 0 01.353.011l1.368 1.46a.25.25 0 01-.011.353L6.082 21.637a.25.25 0 01-.363-.022" />
                            </svg>
                          </svg>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon minus maybe  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path
                                d="M23.75 13H.25a.25.25 0 01-.25-.25v-1.5A.25.25 0 01.25 11h23.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </span>
                      </div>
                      <div className="Comparison ">
                        <span className="label">
                          <span>Free 3D Walkthrough</span>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon checkmark yes  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path d="M5.719 21.615L.2 14.992a.25.25 0 01.032-.352l1.536-1.28a.25.25 0 01.352.032l3.992 4.79L20.963 4.259a.25.25 0 01.353.011l1.368 1.46a.25.25 0 01-.011.353L6.082 21.637a.25.25 0 01-.363-.022" />
                            </svg>
                          </svg>
                        </span>
                        <span className="value">
                          <svg className="SvgIcon minus maybe  size-medium lighter-valid-color">
                            <svg viewBox="0 0 24 24">
                              <path
                                d="M23.75 13H.25a.25.25 0 01-.25-.25v-1.5A.25.25 0 01.25 11h23.5a.25.25 0 01.25.25v1.5a.25.25 0 01-.25.25"
                                fillRule="evenodd"
                              />
                            </svg>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              data-react-server-root-id={11}
              data-react-server-timing-offset={101}
            >
              <p className="sellForMoreDisclaimer">
                "Sell for more" based on a third-party analysis of Red Coral Real Estates data
                comparing price per square foot the sale and list price of
                Red Coral Real Estates listings to those of other brokerages between May 2020 to
                April 2021. Red Coral Real Estates Agents sell faster per a 2021 study.
              </p>
            </div>
          </div>
          <div
            data-react-server-root-id={13}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={14}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={15}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={16}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={17}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={18}
            data-react-server-timing-offset={101}
          />
          <div
            data-react-server-root-id={19}
            data-react-server-timing-offset={101}
          />
          <div data-react-server-container={20}>
            <div
              data-react-server-root-id={21}
              data-react-server-timing-offset={101}
            />
            <div
              data-react-server-root-id={22}
              data-react-server-timing-offset={101}
            />
            <div
              data-react-server-root-id={23}
              data-react-server-timing-offset={101}
            >
              <div className="GoogleOneTapSignOnContainer original">
                <div className="oneTapContainerWrapper">
                  <div id="GoogleOneTapParentDiv" />
                </div>
              </div>
            </div>
          </div>
          <div
            data-react-server-root-id={25}
            data-react-server-timing-offset={101}
          >
         <Footer />
          </div>
          <div
            data-react-server-root-id={26}
            data-react-server-timing-offset={101}
          >
            <noscript />
          </div>
        </div>
        <div id="onetrust-consent-sdk">
          <div className="onetrust-pc-dark-filter ot-hide ot-fade-in" />
          <div
            id="onetrust-pc-sdk"
            className="otPcCenter ot-hide ot-fade-in otRelFont"
            lang="en"
            aria-label="Preference center"
            role="region"
          >
            <div
              role="alertdialog"
              aria-modal="true"
              aria-describedby="ot-pc-desc"
              style={{ height: "100%" }}
              aria-label="Privacy Preference Center"
            >
              {/* Close Button */}
              <div className="ot-pc-header">
                {/* Logo Tag */}
                <div className="ot-pc-logo" role="img" aria-label="Red Coral Real Estates-logo">
                  <img
                    alt="Red Coral Real Estates-logo"
                    src="https://cdn.cookielaw.org/logos/86222029-6fe7-430a-9571-b81fe6ac20d1/7e5bc3d6-ef20-4760-aa0d-c8df4649fae2/5a4cac3e-8eff-4874-a883-f5d261a1994d/Red Coral Real Estates-inline-16px@2x.png"
                  />
                </div>
                <button
                  id="close-pc-btn-handler"
                  className="ot-close-icon"
                  aria-label="Close"
                  style={{
                    backgroundImage:
                      'url("https://cdn.cookielaw.org/logos/static/ot_close.svg")',
                  }}
                />
              </div>
              {/* Close Button */}
              <div id="ot-pc-content" className="ot-pc-scrollbar">
                <div className="ot-optout-signal ot-hide">
                  <div className="ot-optout-icon">
                    <svg xmlns="http://www.w3.org/2000/svg">
                      <path
                        className="ot-floating-button__svg-fill"
                        d="M14.588 0l.445.328c1.807 1.303 3.961 2.533 6.461 3.688 2.015.93 4.576 1.746 7.682 2.446 0 14.178-4.73 24.133-14.19 29.864l-.398.236C4.863 30.87 0 20.837 0 6.462c3.107-.7 5.668-1.516 7.682-2.446 2.709-1.251 5.01-2.59 6.906-4.016zm5.87 13.88a.75.75 0 00-.974.159l-5.475 6.625-3.005-2.997-.077-.067a.75.75 0 00-.983 1.13l4.172 4.16 6.525-7.895.06-.083a.75.75 0 00-.16-.973z"
                        fill="#FFF"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                  <span />
                </div>
                <h2 id="ot-pc-title">Privacy Preference Center</h2>
                <div id="ot-pc-desc">
                  When you visit any website, it may store or retrieve
                  information on your browser, mostly in the form of cookies.
                  This information might be about you, your preferences or your
                  device and is mostly used to make the site work as you expect
                  it to. The information does not usually directly identify you,
                  but it can give you a more personalized web experience.
                  Because we respect your right to privacy, you can choose not
                  to allow some types of cookies. Click on the different
                  category headings to find out more and change our default
                  settings. However, blocking some types of cookies may impact
                  your experience of the site and the services we are able to
                  offer.
                </div>
                <button id="accept-recommended-btn-handler">Allow All</button>
                <section className="ot-sdk-row ot-cat-grp">
                  <h3 id="ot-category-title"> Manage Consent Preferences</h3>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0002"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0002"
                      aria-labelledby="ot-header-id-C0002"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0002">
                        Performance Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0002"
                      >
                        These cookies allow us to count visits and traffic
                        sources so we can measure and improve the performance of
                        our site. They help us to know which pages are the most
                        and least popular and see how visitors move around the
                        site. All information these cookies collect is
                        aggregated and therefore anonymous. If you do not allow
                        these cookies we will not know when you have visited our
                        site, and will not be able to monitor its performance.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0003"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0003"
                      aria-labelledby="ot-header-id-C0003"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0003">
                        Functional Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0003"
                      >
                        These cookies enable the website to provide enhanced
                        functionality and personalisation. They may be set by us
                        or by third party providers whose services we have added
                        to our pages. If you do not allow these cookies then
                        some or all of these services may not function properly.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0001"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0001"
                      aria-labelledby="ot-header-id-C0001"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0001">
                        Strictly Necessary Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0001"
                      >
                        These cookies are necessary for the website to function
                        and cannot be switched off in our systems. They are
                        usually only set in response to actions made by you
                        which amount to a request for services, such as setting
                        your privacy preferences, logging in or filling in
                        forms. You can set your browser to block or alert you
                        about these cookies, but some parts of the site may not
                        work.
                      </p>
                    </div>
                  </div>
                  <div
                    className="ot-accordion-layout ot-cat-item ot-vs-config"
                    data-optanongroupid="C0004"
                  >
                    <button
                      aria-expanded="false"
                      ot-accordion="true"
                      aria-controls="ot-desc-id-C0004"
                      aria-labelledby="ot-header-id-C0004"
                    />
                    {/* Accordion header */}
                    <div className="ot-acc-hdr ot-always-active-group">
                      <div className="ot-plus-minus">
                        <span />
                        <span />
                      </div>
                      <h4 className="ot-cat-header" id="ot-header-id-C0004">
                        Advertising/Targeting Cookies
                      </h4>
                      <div className="ot-always-active">Always Active</div>
                    </div>
                    {/* accordion detail */}
                    <div className="ot-acc-grpcntr ot-acc-txt">
                      <p
                        className="ot-acc-grpdesc ot-category-desc"
                        id="ot-desc-id-C0004"
                      >
                        These cookies may be set through our site by our
                        advertising partners. They may be used by those
                        companies to build a profile of your interests and show
                        you relevant adverts on other sites. They do not store
                        directly personal information, but are based on uniquely
                        identifying your browser and internet device. If you do
                        not allow these cookies, you will experience less
                        targeted advertising.
                      </p>
                    </div>
                  </div>
                  {/* Groups sections starts */}
                  {/* Group section ends */}
                  {/* Accordion Group section starts */}
                  {/* Accordion Group section ends */}
                </section>
              </div>
              <section
                id="ot-pc-lst"
                className="ot-hide ot-hosts-ui ot-pc-scrollbar"
              >
                <div id="ot-pc-hdr">
                  <div id="ot-lst-title">
                    <button
                      className="ot-link-btn back-btn-handler"
                      aria-label="Back"
                    >
                      <svg
                        id="ot-back-arw"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 444.531 444.531"
                        xmlSpace="preserve"
                      >
                        <title>Back Button</title>
                        <g>
                          <path
                            fill="#656565"
                            d="M213.13,222.409L351.88,83.653c7.05-7.043,10.567-15.657,10.567-25.841c0-10.183-3.518-18.793-10.567-25.835
                    l-21.409-21.416C323.432,3.521,314.817,0,304.637,0s-18.791,3.521-25.841,10.561L92.649,196.425
                    c-7.044,7.043-10.566,15.656-10.566,25.841s3.521,18.791,10.566,25.837l186.146,185.864c7.05,7.043,15.66,10.564,25.841,10.564
                    s18.795-3.521,25.834-10.564l21.409-21.412c7.05-7.039,10.567-15.604,10.567-25.697c0-10.085-3.518-18.746-10.567-25.978
                    L213.13,222.409z"
                          />
                        </g>
                      </svg>
                    </button>
                    <h3>Performance Cookies</h3>
                  </div>
                  <div className="ot-lst-subhdr">
                    <div className="ot-search-cntr">
                      <p role="status" className="ot-scrn-rdr" />
                      <label
                        htmlFor="vendor-search-handler"
                        className="ot-scrn-rdr"
                      />{" "}
                      <input
                        id="vendor-search-handler"
                        type="text"
                        name="vendor-search-handler"
                      />{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 -30 110 110"
                        aria-hidden="true"
                      >
                        <title>Search Icon</title>
                        <path
                          fill="#2e3644"
                          d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23
            s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92
            c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17
            s-17-7.626-17-17S14.61,6,23.984,6z"
                        />
                      </svg>
                    </div>
                    <div className="ot-fltr-cntr">
                      <button
                        id="filter-btn-handler"
                        aria-label="Filter"
                        aria-haspopup="true"
                      >
                        <svg
                          role="presentation"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          x="0px"
                          y="0px"
                          viewBox="0 0 402.577 402.577"
                          xmlSpace="preserve"
                        >
                          <title>Filter Icon</title>
                          <g>
                            <path
                              fill="#fff"
                              d="M400.858,11.427c-3.241-7.421-8.85-11.132-16.854-11.136H18.564c-7.993,0-13.61,3.715-16.846,11.136
      c-3.234,7.801-1.903,14.467,3.999,19.985l140.757,140.753v138.755c0,4.955,1.809,9.232,5.424,12.854l73.085,73.083
      c3.429,3.614,7.71,5.428,12.851,5.428c2.282,0,4.66-0.479,7.135-1.43c7.426-3.238,11.14-8.851,11.14-16.845V172.166L396.861,31.413
      C402.765,25.895,404.093,19.231,400.858,11.427z"
                            />
                          </g>
                        </svg>
                      </button>
                    </div>
                    <div id="ot-anchor" />
                    <section id="ot-fltr-modal">
                      <div id="ot-fltr-cnt">
                        <button id="clear-filters-handler">Clear</button>
                        <div className="ot-fltr-scrlcnt ot-pc-scrollbar">
                          <div className="ot-fltr-opts">
                            <div className="ot-fltr-opt">
                              <div className="ot-chkbox">
                                <input
                                  id="chkbox-id"
                                  type="checkbox"
                                  aria-checked="false"
                                  className="category-filter-handler"
                                />{" "}
                                <label htmlFor="chkbox-id">
                                  <span className="ot-label-txt">
                                    checkbox label
                                  </span>
                                </label>{" "}
                                <span className="ot-label-status">label</span>
                              </div>
                            </div>
                          </div>
                          <div className="ot-fltr-btns">
                            <button id="filter-apply-handler">Apply</button>{" "}
                            <button id="filter-cancel-handler">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  id="ot-lst-cnt"
                  className="ot-host-cnt ot-pc-scrollbar"
                >
                  <div id="ot-sel-blk">
                    <div className="ot-sel-all">
                      <div className="ot-sel-all-hdr">
                        <span className="ot-consent-hdr">Consent</span>{" "}
                        <span className="ot-li-hdr">Leg.Interest</span>
                      </div>
                      <div className="ot-sel-all-chkbox">
                        <div className="ot-chkbox" id="ot-selall-hostcntr">
                          <input
                            id="select-all-hosts-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-hosts-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-vencntr">
                          <input
                            id="select-all-vendor-groups-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-groups-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                        <div className="ot-chkbox" id="ot-selall-licntr">
                          <input
                            id="select-all-vendor-leg-handler"
                            type="checkbox"
                            aria-checked="false"
                          />{" "}
                          <label htmlFor="select-all-vendor-leg-handler">
                            <span className="ot-label-txt">checkbox label</span>
                          </label>{" "}
                          <span className="ot-label-status">label</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="ot-sdk-row">
                    <div className="ot-sdk-column" />
                  </div>
                </section>
              </section>
          
              <span
                className="ot-scrn-rdr"
                aria-atomic="true"
                aria-live="polite"
              />
              {/* Vendor Service container and item template */}
            </div>
         
          </div>
        </div>
 
        <img
          className="ywa-10000"
          src="https://sp.analytics.yahoo.com/sp.pl?a=10000&d=Sun%2C%2012%20Mar%202023%2011%3A13%3A16%20GMT&n=-1&b=Sell%20My%20Home%20%7C%20Redfin&.yp=10184908&f=https%3A%2F%2Fwww.redfin.com%2Fsell-a-home%2Faddress%3FinquirySource%3D484&e=https%3A%2F%2Fwww.redfin.com%2Fselling-options&enc=UTF-8&us_privacy=1---&yv=1.13.0&tagmgr=gtm"
          alt="dot image pixel"
          style={{ display: "none" }}
        />
 
        <div id="fb-root" className=" fb_reset">
          <div
            style={{
              position: "absolute",
              top: "-10000px",
              width: "0px",
              height: "0px",
            }}
          >
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Selling2;
